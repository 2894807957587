import {
  useBioFromSlugQuery,
  useBioV2FromSlugUserSummaryQuery,
} from "shared/dist/__generated__/components";

import { Maybe } from "shared/dist/util";
import React from "react";
import { match } from "ts-pattern";
import { useParams } from "react-router-dom";

type Slug = string;
export const BioFromSlugContext = React.createContext<
  [Slug, ReturnType<typeof useBioFromSlugQuery>]
>(["", {} as any]);

/**
 * @deprecated. use `useTypedParams` instead
 */
export function useSlug() {
  return React.useContext(BioFromSlugContext)[0];
}

/**
 *
 * @deprecated use the BioV2 queries and caching instead
 */
export function useBioFromSlug() {
  return React.useContext(BioFromSlugContext)[1];
}

/**
 * @deprecated use BioV2 functions and caching instead
 */
export function useBioAndSlug(): [
  string | null | undefined,
  ReturnType<typeof useBioFromSlugQuery>,
] {
  let { slug } = useParams();
  // console.log("🚀 ~ file: bio-context.tsx:28 ~ slug:", slug);
  const result = useBioFromSlugQuery({
    skip: !slug,
    variables: { slug: slug! },
    fetchPolicy: "cache-first",
  });
  return [slug, result];
}

/**
 * @deprecated use BioV2 functions and caching instead
 */
export function useBioAndSlugFromContext() {
  return React.useContext(BioFromSlugContext);
}

export function useBioV2ScreenName(slug: Maybe<string>) {
  const { data, loading } = useBioV2FromSlugUserSummaryQuery({
    skip: !slug,
    variables: { slug: slug! },
    fetchPolicy: "cache-first",
  });
  const screenName = match(data?.l_bio_v2)
    .with({ __typename: "L_Bio_V2_Success" }, (d) => ({ screen_name: d.user?.screen_name }))
    .otherwise(() => undefined);
  return { loading, data: screenName };
}
