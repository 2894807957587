import { H2, H3 } from "shared-web-react/dist/widgets/text";
import { useAuthIsLoading, useMyId } from "shared/dist/auth-data";

import { Link } from "react-router-dom";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../util/routes";
import logoNeonWhite from "../../assets/neon-logos/candid-neon-white.png";

export function LandingCtaButton({
  variant,
  className,
}: {
  variant?: "accent" | "neutral";
  className?: string;
}): React.JSX.Element {
  return (
    <Link
      to={allRoutes.login.buildPath({})}
      className={`lowercase btn !rounded-full btn-${variant ?? "primary"} ${className ?? ""}`}
    >
      join the beta
    </Link>
  );
}

export function LandingLoginButton(): React.JSX.Element {
  const id = useMyId();
  const loading = useAuthIsLoading();
  return (
    <>
      <div className="absolute flex items-center justify-between flex-row top-0 left-0 right-0 p-4 space-x-2">
        <div className="flex-1 ">
          <img src={logoNeonWhite} className="w-64" />
        </div>
        {/* {loggedIn ? (
	  <button onClick={logout} className="btn btn-ghost capitalize">
		Logout
	  </button>
	) : ( */}
        <div className="flex-1">
          {loading ? (
            <div className="btn btn-accent capitalize">
              <div className={"opacity-0"}>Logout</div>
              <div className={`absolute mx-auto`}>
                <Spinner />
              </div>
            </div>
          ) : (
            <div className="space-x-2 text-right lg:space-x-4 text-sm lg:text-base">
              {id && (
                <Link
                  className="text-candid-lavender-200 capitalize"
                  to={allRoutes.me.buildPath({})}
                >
                  My Page
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export const LandingWidget = ({
  topText,
  text,
  cta,
  btn,
}: {
  topText: string;
  text: string;
  cta: string;
  btn: React.JSX.Element;
}) => {
  return (
    <div className="z-50 flex-col text-left md:py-24 md:max-w-[30vw] sm:pt-45 pt-40 max-w-[80vw]">
      <p className="mb-0 text-sm uppercase  font-semibold text-[color-mix(in_oklab,oklch(var(--a)),black_7%)]">
        {topText}
      </p>
      <h2 className="mb-0 text-3xl sm:text-4xl uppercase tracking-wide text-primary-content font-bold">
        {cta}
      </h2>
      <p className="mb-4 text-lg text-primary-content leading-[1.25]">{text}</p>
      {btn}
    </div>
  );
};

export const BuildWidget = ({
  text,
  cta,
  btn,
}: {
  text: string;
  cta: string;
  btn: React.JSX.Element;
}) => {
  return (
    <div className="hero-content text-center">
      <div className="lg:max-w-[40vw] md:min-w-[40%] text-left max-w-[80vw] md:py-24 py-10">
        <H3 className="mb-1 uppercase tracking-wide text-secondary font-bold">{cta}</H3>
        <p className="mb-1 py-2 text-lg font-semibold">{text}</p>
        {btn}
      </div>
    </div>
  );
};

export function SingleFeatureWidget({
  imgSrc,
  children,
  right,
}: React.PropsWithChildren<{ imgSrc: string; right: boolean }>): React.JSX.Element {
  return (
    <div
      className={`w-full flex items-center flex-col ${
        right ? "lg:flex-row" : "lg:flex-row-reverse"
      } p-4 `}
    >
      <div
        className={`${
          right ? "lg:m-6 m-3 pr-4 lg:pr-20" : "lg:m-6 m-3 pl-4 lg:pl-20"
        } md:px-[3vw] sm:px-[10vw] flex-1 space-y-2 text-primary-content ${
          right ? "md:justify-start" : "md:justify-end"
        }`}
      >
        {children}
      </div>
      <div
        className="aspect-video my-24 bg-center bg-no-repeat bg-cover drop-shadow-2xl mt-4 md:mt-0"
        style={{
          backgroundImage: `url(${imgSrc})`,
          height: "812px",
          width: "50%",
          minWidth: "300px",
          backgroundSize: "contain",
        }}
      ></div>
    </div>
  );
}

export const StandoutCardWidget = ({
  imageSrc,
  title,
  text,
}: {
  imageSrc: string;
  title: string;
  text: string;
}) => {
  return (
    <div className="flex-shrink-0 w-full md:w-1/5 px-4 mb-8 md:mb-0 min-w-[250px]">
      <div className="bg-primary-content rounded-xl p-5 shadow-md transform transition-transform duration-300 hover:rotate-2">
        <img
          src={imageSrc}
          alt={title}
          className="mx-auto mb-2 w-3/5 min-w-[100%] h-auto rounded-lg"
        />
        <h3 className="text-lg font-semibold text-black mb-0">{title}</h3>
        <p className="text-black">{text}</p>
      </div>
    </div>
  );
};
