import { BasicLocations, LocationPicker, useSaveLocation } from "../../widgets/bio/location-picker";

import { L_Geo_Point_Type } from "shared/dist/__generated__/components";
import { Loc } from "shared/dist/util/loc";
import { ObScreen } from "./widgets";
import React from "react";
import clsx from "clsx";
import { useMustBeLoggedIn } from "../../util/routes";

export function ObLocation({
  // onNext, // not needed here
  setDisableButton,
}: {
  onNext: () => void;
  setDisableButton?: (b: boolean) => void;
}): React.JSX.Element {
  const saveLocation = useSaveLocation({ source: L_Geo_Point_Type.Manual, suppressToast: true });
  React.useEffect(() => {
    setDisableButton && setDisableButton(true);
    setTimeout(() => setDisableButton && setDisableButton(false), 1500);
  }, []);
  const [customLocation, setCustomLocation] = React.useState<Loc | null>(null);
  console.log("🚀 - file: location.tsx:43 - customLocation:", customLocation);

  const [locationChoice, setLocationChoice] = React.useState<
    keyof typeof BasicLocations | null | "custom"
  >("la");
  // const [locationLoading, setLocationLoading] = React.useState(false);
  // const anyLoading = locationLoading;
  // const isDev = useIsDev();
  useMustBeLoggedIn();

  React.useEffect(() => {
    if (!locationChoice) return;
    const locToSave = locationChoice === "custom" ? customLocation : BasicLocations[locationChoice];
    if (!locToSave) return;
    setDisableButton?.(true);
    saveLocation(locToSave).then(() => setDisableButton?.(false));
  }, [customLocation, locationChoice, saveLocation]);

  return (
    <ObScreen title="What's your location?">
      <div className="flex flex-col h-full w-full pb-4 items-stretch">
        {(Object.keys(BasicLocations) as Array<keyof typeof BasicLocations>).map((key) => (
          <div key={key} className="form-control">
            <label className="label cursor-pointer flex items-center justify-start space-x-2">
              <input
                type="radio"
                name="location"
                className="radio radio-primary"
                onChange={() => {
                  setLocationChoice(key);
                }}
                checked={locationChoice === key}
              />
              <span
                className={clsx(
                  "label-text",
                  locationChoice === key && "font-semibold text-primary"
                )}
              >
                {BasicLocations[key as keyof typeof BasicLocations].label}
              </span>
            </label>
          </div>
        ))}
        <div className="divider my-0" />
        <div className="form-control">
          <label
            className="label cursor-pointer flex items-center justify-start space-x-2"
            onClick={() => setLocationChoice("custom")}
          >
            <input
              type="radio"
              name="location"
              className="radio radio-primary"
              onChange={() => setLocationChoice("custom")}
              checked={locationChoice === "custom"}
            />
            <span
              className={clsx(
                "label-text",
                locationChoice === "custom" && "font-semibold text-primary"
              )}
            >
              Other
            </span>
          </label>
        </div>

        {locationChoice === "custom" && (
          <div className={clsx("form-control flex-1")}>
            <label className="label">
              <span className="label-text">Custom Location</span>
            </label>
            <LocationPicker
              onSelect={setCustomLocation}
              hideValueOnDisabled
              hideIcon
              // onLoadingChange={setLocationLoading}
            />
          </div>
        )}

        <div className="flex-1 basis-full"></div>
      </div>
    </ObScreen>
  );
}
