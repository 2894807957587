import { FacebookPixelComponent, GoogleComponent } from "./widgets/tracking-pixels";
import { useEnv, useIsDev } from "shared/dist/util/env";

import React from "react";
import { SmsAuthForm } from "shared-web-react/dist/sms-login-screen";
import { allRoutes } from "./util/routes";
import login0 from "./assets/login-0.jpg";
import login1 from "./assets/login-1.jpg";
import login2 from "./assets/login-2.jpg";
import login3 from "./assets/login-3.jpg";
import mixpanel from "mixpanel-browser";

export function UserSmsAuth(): React.JSX.Element {
  React.useEffect(() => {
    try {
      mixpanel.track("login_page_landing");
    } catch (e) {
      console.error("MP Error: ", e);
    }
  }, []);
  const isDev = useIsDev();
  const env = useEnv("REACT_APP_ENV");
  console.log("🚀 ~ file: sms-auth-web.tsx:21 ~ UserSmsAuth ~ env:", env);
  const loginImg = React.useMemo(() => {
    const arr = [login0, login1, login2, login3];
    return arr[Math.round(Math.random() * (arr.length - 1))];
  }, []);
  return (
    <>
      <div className="flex min-h-full w-screen h-screen">
        <SmsAuthForm
          allowDevLoginEnv={isDev ? env : undefined}
          redirectToOnLogin={allRoutes.HOME.buildPath({})}
        />
        <div className="relative bg-red-400 hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={loginImg}
            alt="stock photo of people together"
          />
        </div>
        <FacebookPixelComponent />
        <GoogleComponent />
      </div>
    </>
  );
}
