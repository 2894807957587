import { P, match } from "ts-pattern";
import { classNames, sortBy } from "shared/dist/util";

import { Container } from "../../../widgets/container";
import { DateTime } from "luxon";
import { NewsFeedItem } from "../news-feed/items";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../../util/routes";
import clsx from "clsx";
import { useBioV2FromSlugFeed403Query } from "shared/dist/__generated__/components";
import { useMyId } from "shared/dist/auth-data";
import { useTypedParams } from "react-router-typesafe-routes/dom";

function NothingToSeeHere(): React.JSX.Element {
  return (
    <div className="text-base-300 flex-col-center-center">
      <div className="p-4">Nothing to see here</div>
    </div>
  );
}
export function UserFeed(): React.JSX.Element {
  // const isProd = useIsProd();
  const my_id = useMyId();

  const slug = useTypedParams(allRoutes.PROFILE).slug!;
  const { loading, data, fetchMore, previousData } = useBioV2FromSlugFeed403Query({
    variables: { slug },
  });
  const bottomRef = React.createRef<HTMLDivElement>(); // TODO why does this work but not with useRef which is recommended
  const [isAtBottom, setIsAtBottom] = React.useState(false);
  React.useEffect(() => {
    if (!bottomRef.current) return;
    const observer = new IntersectionObserver(([entry]) => {
      setIsAtBottom(entry.isIntersecting);
    });
    observer.observe(bottomRef.current);
    return () => observer.disconnect();
  }, [bottomRef, setIsAtBottom]);
  React.useEffect(() => {
    if (!isAtBottom || loading) return;
    const nextPage = match(data?.l_bio_v2)
      .with(
        { feed: { __typename: "L_News_Feed_Response_Success" } },
        (data) => data.feed.next_page_cursor
      )
      .otherwise(() => null);
    if (nextPage) fetchMore({ variables: { slug, cursor: nextPage } });
  }, [isAtBottom, loading, fetchMore, data]);

  const newsFeedResult = data?.l_bio_v2;
  const sorted = React.useMemo(
    () =>
      match(newsFeedResult)
        .with({ feed: { __typename: "L_News_Feed_Response_Success" } }, (data) =>
          sortBy(
            data.feed.items.filter((item) => item.__typename !== "L_News_Feed_Item_Public_Event") ??
              [],
            (item) => DateTime.fromISO(item.created_at).toMillis()
          ).reverse()
        )
        .otherwise(() => []),
    [newsFeedResult]
  );
  return match(newsFeedResult)
    .with(
      { __typename: "L_Bio_Access_Denied" },
      { __typename: "L_Bio_Error" },
      { __typename: P.nullish },
      P.nullish,
      { feed: P.nullish },
      { feed: { __typename: "L_News_Feed_Bad_Cursor" } },
      { feed: { __typename: "L_News_Feed_Empty" } },
      { feed: { __typename: "L_Simple_Response_Error" } },
      () => <NothingToSeeHere />
    )
    .with({ feed: { __typename: "L_News_Feed_Response_Success" } }, (data) => {
      if (loading && !sorted?.length) return <SpinnerCentered />;
      return (
        <Container
          size="xs"
          className={classNames(
            "h-full flex flex-col items-center pb-16",
            // "lg:pt-2 max-lg:pt-32 ",
            "text-candid-purple-700 justify-start space-y-8 text-center"
          )}
        >
          <div className="w-full max-w-full space-y-6 pb-16 ">
            {sorted.map((item, idx) => (
              <NewsFeedItem key={idx} item={item} />
            ))}
            {loading && <SpinnerCentered />}
            <div className={clsx("h-1 mx-auto col-span-3 w-1 ")} ref={bottomRef}></div>
          </div>
        </Container>
      );
    })
    .exhaustive();
}
