import {
  LazyImage,
  LazyImageProps,
  useLoadImageWithCache,
} from "shared-web-react/dist/widgets/lazy-image";
import { Size, useMkImgSrcSet, useMkImgUrl } from "../util/routes";

import React from "react";
import clsx from "clsx";

export type LazyMediaItemProps = Omit<LazyImageProps, "src"> & {
  mediaUploadId: string;
  size?: Size;
};

export function useLoadMediaItemIntoCache() {
  const mkUrl = useMkImgUrl();
  const loadImage = useLoadImageWithCache();
  return React.useCallback(
    async (mediaUploadId: string, size?: Size) => {
      const url = mkUrl(mediaUploadId, size);
      return await loadImage(url);
    },
    [loadImage, mkUrl]
  );
}

export function LazyMediaItem({
  mediaUploadId,
  size,
  thumbHash,
  alt,
  className,
  imgRef,
  ...props
}: LazyMediaItemProps): React.JSX.Element {
  const url = useMkImgUrl()(mediaUploadId, size);
  return (
    <LazyImage
      src={url}
      // cacheKey={mediaUploadId + "_" + size}
      {...props}
      thumbHash={thumbHash}
      alt={alt}
      className={clsx("LazyMediaItem", className)}
      imgRef={imgRef}
    />
  );
}
