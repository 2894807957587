import {
  Feature_Flags_Enum,
  useMyRelationshipsShortV410Query,
} from "shared/dist/__generated__/components";
import { ProfileList, ProfileListItem, ProfileListProps } from "../../../widgets/profile-list";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { classNames, filterNulls } from "shared/dist/util";

import { AddThreadButton } from "../../../widgets/add-thread-button";
import { SearchBar } from "../../../widgets/search-bar";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { ZeroScreen } from "../../../widgets/zero-screens";
import { allRoutes } from "../../../util/routes";
import { useMyId } from "shared/dist/auth-data";
import { useMyInfo } from "../../bio/bio-settings";

export function DiscoveryConnections(): React.JSX.Element {
  const my_id = useMyId();
  const { slug: mySlug } = useMyInfo();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { data, loading, refetch } = useMyRelationshipsShortV410Query({
    skip: !my_id,
    variables: { type: "like", my_id: my_id! },
  });

  const connections: Array<ProfileListItem> = useMemo(
    () =>
      filterNulls(
        data?.relationships_union?.map((e) =>
          e.snd_user_summary?.slug && e.snd_user_summary?.screen_name
            ? {
                slug: e.snd_user_summary.slug,
                screenName: e.snd_user_summary.screen_name,
              }
            : null
        )
      ),
    [data]
  );

  const filteredItems = useMemo(() => {
    if (searchTerm === "") {
      return connections;
    } else {
      return connections.filter((item) =>
        item.screenName.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
    }
  }, [connections, searchTerm]);

  const mkRoute: ProfileListProps["mkRoute"] = React.useCallback(
    ({ slug, ...other }: ProfileListItem) =>
      slug
        ? {
            to: allRoutes.PROFILE.buildPath({ slug }),
          }
        : null,
    []
  );

  const mkActions: ProfileListProps["mkActions"] = React.useCallback(
    ({ slug }: ProfileListItem) => [
      slug
        ? {
            component: (
              <AddThreadButton slug={slug}>
                <div className="btn btn-sm btn-outline">Message</div>
              </AddThreadButton>
            ),
          }
        : null,
    ],
    []
  );

  if (loading) {
    return <SpinnerCentered />;
  }

  if (filteredItems.length === 0 && !searchTerm) {
    return (
      <div className="flex flex-col h-full w-full overflow-hidden">
        {/*  temporary removal pending avatar loading fix
        <div className="flex my-2 px-2">
          <SearchBar
            onSearch={(term: string) => setSearchTerm(term)}
            placeholder="Search connections..."
            value={searchTerm}
          />
        </div> */}
        <ZeroScreen screenType="discovery-connections" />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "flex justify-start items-stretch flex-col join join-vertical gap-4 h-full relative"
      )}
    >
      {/* temporary removal pending avatar loading fix
       <div className="flex my-2 px-2">
        <SearchBar
          onSearch={(term: string) => setSearchTerm(term)}
          placeholder="Search connections..."
          value={searchTerm}
        />
      </div> */}
      <div className={classNames("p-4 pb-16 max-h-full overflow-y-auto")}>
        {filteredItems.length === 0 ? (
          <div>No results found</div>
        ) : (
          <ProfileList
            profiles={filteredItems}
            mkActions={mkActions}
            mkRoute={mkRoute}
            columnCountOverride={1}
          />
        )}
      </div>
    </div>
  );
}
