import {
  Event_Summary_412Fragment,
  useMyPastEvents412Query,
  useMyUpcomingEvents412Query,
  usePublicUpcomingEventList451Query,
} from "shared/dist/__generated__/components";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Maybe, classNames } from "shared/dist/util";
import React, { useEffect } from "react";
import { UserPageTab, UserPageTabLabel } from "../../../widgets/tabs";
import {
  faCalendarCheck,
  faCalendarClock,
  faCalendarUsers,
} from "@fortawesome/pro-regular-svg-icons";

import { Container } from "../../../widgets/container";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShowIndicator } from "shared/dist/indicators-context";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { VerificationBox } from "../../../widgets/verification-box";
import { allRoutes } from "../../../util/routes";
import clsx from "clsx";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { useIndicators } from "../../../widgets/indicators";
import { useMkUrl } from "shared/dist/util/env";
import { useSecureStorage } from "../../../widgets/use-secure-storage";
import { useSetPageTitle } from "shared-web-react/dist/widgets/nav-stack-view";

export function EventsHome(): React.JSX.Element {
  useSetPageTitle("Events");
  return (
    <div className={classNames("h-full flex flex-col justify-stretch items-stretch bg-base-200")}>
      <div className="m-5 mb-2">
        <VerificationBox
          heading="✨ Exclusive Events Await! ✨"
          text="Verification takes just a moment and unlocks all the fantastic features and events on Candid. Tap here to verify your identity now!"
        />
      </div>
      <div className={classNames("flex-0 fit-content pt-2 relative z-20-main-ui-overlay")}>
        <div className="tabs-xs md:tabs-sm lg:tabs-md tabs-bordered tabs flex w-full my-2 flex-nowrap justify-center ">
          <UserPageTab hideIndicator route={allRoutes.EVENTS.LIST.buildPath({})}>
            <ShowIndicator
              prefix={allRoutes.EVENTS.LIST.buildPath({})}
              indicatorItemClassName="left-11"
            >
              <FontAwesomeIcon icon={faCalendarUsers} />
              <UserPageTabLabel>Public</UserPageTabLabel>
            </ShowIndicator>
          </UserPageTab>
          <UserPageTab route={allRoutes.EVENTS.LIST.MY_UPCOMING.buildPath({})}>
            <FontAwesomeIcon icon={faCalendarCheck} />
            <UserPageTabLabel>Upcoming</UserPageTabLabel>
          </UserPageTab>
          <UserPageTab route={allRoutes.EVENTS.LIST.MY_PAST.buildPath({})}>
            <FontAwesomeIcon icon={faCalendarClock} />
            <UserPageTabLabel>Past</UserPageTabLabel>
          </UserPageTab>
        </div>
      </div>
      <div className={classNames("flex-1  max-h-full overflow-auto")}>
        <Outlet />
      </div>
    </div>
  );
}
function eventFragmentToItem(
  fragment: Event_Summary_412Fragment
): Omit<EventListItemProps, "initialLastCheckedEvents"> {
  return {
    name: fragment.name,
    start: DateTime.fromISO(fragment.start),
    location: fragment.location_description,
    slug: fragment.url_slug,
    imageMediaUploadId: fragment.media_upload_id,
  };
}

export function EventsPublic(): React.JSX.Element {
  const { data, loading } = usePublicUpcomingEventList451Query({ variables: { offset: 0 } });
  const { getSecureValue, setSecureValue } = useSecureStorage("events");
  const [initialLastChecked, setInitialLastChecked] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { clearEventsIndicator } = useIndicators();

  useEffect(() => {
    const fetchInitialDate = async () => {
      const value = await getSecureValue();
      setInitialLastChecked(value || DateTime.now().minus({ days: 7 }).toISO());
      setIsLoading(false);
    };

    fetchInitialDate();
  }, [getSecureValue]);

  useEffect(() => {
    if (!isLoading && !loading) {
      const newLastChecked = DateTime.now().toISO();
      setSecureValue(newLastChecked!);
      clearEventsIndicator();
    }
  }, [isLoading, loading, setSecureValue, clearEventsIndicator]);

  const items: Array<EventListItemProps> = React.useMemo(
    () =>
      data?.event_public_summaries?.map((e) => ({
        connectionsAttending: e.connections_attending ?? 0,
        created_at: e.created_at ?? undefined,
        imageMediaUploadId: e.media_upload_id,
        location: e.location_description,
        name: e.name!,
        slug: e.url_slug!,
        start: DateTime.fromISO(e.start!),
        initialLastCheckedEvents: initialLastChecked,
      })) ?? [],
    [data, initialLastChecked]
  );

  if (loading || isLoading) return <SpinnerCentered />;

  return <EventList items={items} />;
}

export function EventsMyPast(): React.JSX.Element {
  const { data, loading } = useMyPastEvents412Query({});
  const { lastChecked: events_last_checked } = useSecureStorage("events");
  const initialLastCheckedEvents = React.useRef<string>(
    events_last_checked || DateTime.now().minus({ days: 7 }).toISO()
  );

  const items: Array<EventListItemProps> = React.useMemo(
    () =>
      data?.events?.map((event) => ({
        ...eventFragmentToItem(event),
        initialLastCheckedEvents: initialLastCheckedEvents.current,
      })) ?? [],
    [data, initialLastCheckedEvents]
  );
  return <div>{loading ? <SpinnerCentered /> : <EventList items={items} />}</div>;
}

export function EventsMyUpcoming(): React.JSX.Element {
  const { data, loading } = useMyUpcomingEvents412Query({});
  const { getSecureValue, setSecureValue } = useSecureStorage("events");
  const [initialLastChecked, setInitialLastChecked] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchInitialDate = async () => {
      const value = await getSecureValue();
      setInitialLastChecked(value || DateTime.now().minus({ days: 7 }).toISO());
      setIsLoading(false);
    };

    fetchInitialDate();
  }, [getSecureValue]);

  useEffect(() => {
    if (!isLoading) {
      setSecureValue(DateTime.now().toISO()!);
    }
  }, [isLoading, setSecureValue]);

  const items: Array<EventListItemProps> = React.useMemo(
    () =>
      data?.events?.map((event) => ({
        ...eventFragmentToItem(event),
        initialLastCheckedEvents: initialLastChecked,
      })) ?? [],
    [data, initialLastChecked]
  );

  if (loading || isLoading) return <SpinnerCentered />;

  return <EventList items={items} />;
}
type EventListProps = {
  items: Array<EventListItemProps>;
  fakeData?: boolean;
};
export function EventList({ items, fakeData }: EventListProps): React.JSX.Element {
  return (
    <Container size="xxs" className={clsx("EventList px-2 pb-4")}>
      <ul className={clsx("space-y-2")}>
        {fakeData &&
          [...Array(5)].map((_, idx) => (
            <li key={idx}>
              <EventListItem
                connectionsAttending={1}
                dummyImage
                slug={`event_${idx}`}
                name={`Test Event ${idx}`}
                start={DateTime.fromISO("2021-10-10")}
                location={`city number ${idx}`}
                initialLastCheckedEvents={DateTime.now().minus({ days: 7 }).toISO()}
                created_at={DateTime.now().toISO()!}
              />
            </li>
          ))}
        {!fakeData &&
          items.map((item) => (
            <li key={item.slug}>
              <EventListItem {...item} />
            </li>
          ))}
      </ul>
    </Container>
  );
}

type EventListItemProps = {
  connectionsAttending?: number;
  dummyImage?: boolean;
  imageMediaUploadId?: Maybe<string>;
  location?: Maybe<string>;
  name: string;
  slug: string;
  start: DateTime;
  created_at?: string;
  initialLastCheckedEvents: string | null;
};

export function EventListItem({
  connectionsAttending,
  dummyImage,
  imageMediaUploadId,
  location,
  name,
  slug,
  start,
  created_at,
  initialLastCheckedEvents,
}: EventListItemProps): React.JSX.Element {
  const path = allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({ slug });
  const mkUrl = useMkUrl();
  const isNewEvent = React.useMemo(() => {
    if (created_at && initialLastCheckedEvents) {
      const createdAtDate = DateTime.fromISO(created_at);
      const lastCheckedDate = DateTime.fromISO(initialLastCheckedEvents);

      if (createdAtDate.isValid && lastCheckedDate.isValid) {
        return createdAtDate > lastCheckedDate;
      }
    }
    return false;
  }, [created_at, initialLastCheckedEvents]);

  const eventImgUrl = dummyImage
    ? "https://picsum.photos/1024/512"
    : mkUrl(path) + (imageMediaUploadId ? `?mid=${imageMediaUploadId}` : "");

  return (
    <>
      {isNewEvent && (
        <div className={clsx("flex flex-row justify-stretch items-center")}>
          <div className={clsx("flex-1 h-[1px] w-full border border-candid-lavender-200")} />
          <div className="flex-0 text-xs mx-2">
            <FontAwesomeIcon icon={faSparkles} className="mx-1 text-candid-red-300" />
            New Event
          </div>
          <div className={clsx("flex-1 h-[1px] w-full border border-candid-lavender-200")} />
        </div>
      )}
      <Link
        to={allRoutes.EVENT_FROM_SLUG.buildPath({ slug })}
        className={clsx("flex flex-row justify-stretch items-center gap-2 py-1")}
      >
        <div
          className={clsx(
            "card w-96 bg-base-100 shadow-sm shadow-black",
            "w-full aspect-square bg-no-repeat bg-cover bg-center overflow-hidden"
          )}
          style={{ backgroundImage: `url(${eventImgUrl})` }}
        >
          <div
            className={clsx(
              "card w-96 bg-base-100 shadow-sm shadow-black",
              "w-full aspect-square bg-no-repeat bg-cover bg-center overflow-hidden"
            )}
            style={{ backgroundImage: `url(${eventImgUrl})` }}
          >
            <div
              className={clsx(
                "flex flex-col items-start justify-center",
                "w-full bg-gradient-to-b from-transparent via-60%  to-80% to-[rgba(0,0,0,0.8)]",
                "absolute top-[66%] bottom-0 left-0 right-0 py-2 px-4 ",
                "text-primary-content"
              )}
            >
              <h2 className="drop-shadow card-title">{name}</h2>
              <p className={clsx("drop-shadow text-sm")}>
                {" "}
                {start.toLocaleString(DateTime.DATE_FULL)}
              </p>
              {location && <p className={clsx("drop-shadow text-sm")}>{location}</p>}
              {!!connectionsAttending && (
                <p className={clsx("drop-shadow text-sm")}>
                  {connectionsAttending} {connectionsAttending === 1 ? "person" : "people"} you know
                  attending
                </p>
              )}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
