import { BioImage, ProfilePageEditFunctions } from "../../widgets/bio/bio-types";
import {
  GqlOps,
  useArchiveMediaMutation,
  useMarkMediaNsfwMutation,
  useMoveImageDownMutation,
  useMoveImageUpMutation,
  useSetUserProfileImage421Mutation,
  useSwapUploadSequenceNumbersMutation,
  useTogglePinedMediaMutation,
  useUpdateBioMutation,
  useUpdateMediaUploadMutation,
  useUpdateScreenNameMutation,
  useUserProfileImageHashDataQuery,
} from "shared/dist/__generated__/components";

import { DateTime } from "luxon";
import { useApolloClient } from "shared/dist/apollo";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useMyInfo } from "../bio/bio-settings";

// Originally this was used to allow separate refetching but that
// is somewhat deprecated as we just use the BioFromSlug handler
// for MY page and others.  keep the functionality though in case
// we want to optimize ti later

export function useProfilePageEditFunctions(
  id: string | null,
  editable: boolean,
  refetchAll?: () => Promise<any> | null
): null | ProfilePageEditFunctions {
  try {
    return useProfilePageEditFunctionsInner(id, editable, refetchAll);
  } catch (error) {
    console.error(
      "🚀 ~ file: edit-functions.ts ~ line 71 ~ useProfilePageEditFunctions ~ error",
      error
    );
    return null;
  }
}

function useProfilePageEditFunctionsInner(
  id: string | null,
  editable: boolean,
  refetchAll?: () => Promise<any> | null
): null | ProfilePageEditFunctions {
  const apolloClient = useApolloClient();
  const [updateMedia] = useUpdateMediaUploadMutation();
  const [moveImageUp] = useMoveImageUpMutation();
  const [moveImageDown] = useMoveImageDownMutation();
  const [updateScreenName] = useUpdateScreenNameMutation();
  const [updateBio] = useUpdateBioMutation();
  const [archiveMedia] = useArchiveMediaMutation();
  const [setUserProfileImage] = useSetUserProfileImage421Mutation();
  const [swapUploadSequenceNumbers] = useSwapUploadSequenceNumbersMutation();
  const [markMediaNSFW] = useMarkMediaNsfwMutation();
  const [togglePinedMedia] = useTogglePinedMediaMutation();
  const { slug } = useMyInfo();
  const { data } = useUserProfileImageHashDataQuery({
    variables: { slug: slug! },
    skip: !slug,
    fetchPolicy: "cache-first",
  });
  const profile_image_id = data?.user_summaries?.[0]?.profile_image_id;
  const confirm = useConfirm();

  if (!id || !editable || !slug) return null;

  return {
    refetchBio: async () => refetchAll?.(),
    refetchImages: async () => refetchAll?.(),
    screenName: async (screen_name: string) => {
      await updateScreenName({ variables: { id, screen_name } });
    },
    bioAboutText: async (bio: string) => {
      await updateBio({
        variables: { id, bio },
        refetchQueries: [GqlOps.Query.BioV2FromSlugBasics],
      });
    },
    archiveImage: async (id) => {
      const handleArchive = async () => {
        await archiveMedia({
          variables: { id, archived_at: DateTime.now().toISO() },
          refetchQueries: [
            GqlOps.Query.BioV2FromSlugMedia451,
            GqlOps.Query.UserThumbnails,
            GqlOps.Query.OnboardingImages412,
            GqlOps.Query.ThreadMessageMedia340,
            GqlOps.Query.UserMedia,
            GqlOps.Query.UserProfileImageHashData,
          ],
        });
        refetchAll?.();
        return;
      };
      if (id === profile_image_id) {
        confirm({
          title: "Delete Profile Image",
          content:
            "This action will remove your current profile image, leaving it empty. Are you sure you want to proceed? You can set a new profile image at any time.",
          okButtonContent: "Delete Image",
          onOk() {
            handleArchive();
            setUserProfileImage({
              variables: { profile_media_id: null },
              refetchQueries: [
                GqlOps.Query.BioV2FromSlugBasics,
                GqlOps.Query.BioV2FromSlugMedia451,
                GqlOps.Query.UserThumbnails,
                GqlOps.Query.ThreadMessageMedia340,
                GqlOps.Query.UserMedia,
                GqlOps.Query.UserProfileImageHashData,
              ],
            });
          },
        });
        return;
      }
      handleArchive();
    },
    setImgCaption: async (id, val) => {
      await updateMedia({
        variables: { id, update: { caption: val } },
        refetchQueries: [
          GqlOps.Query.BioV2FromSlugMedia451,
          GqlOps.Query.UserThumbnails,
          GqlOps.Query.ThreadMessageMedia,
          GqlOps.Query.UserMedia,
          GqlOps.Query.OnboardingImages412,
        ],
      });
      refetchAll?.();
    },
    setImgPrivate: async (id: string, val: boolean) => {
      await updateMedia({
        variables: { id, update: { is_private: val } },
        refetchQueries: [
          GqlOps.Query.BioV2FromSlugMedia451,
          GqlOps.Query.UserThumbnails,
          GqlOps.Query.ThreadMessageMedia,
          GqlOps.Query.UserMedia,
        ],
      });
      refetchAll?.();
    },
    setImgNsfw: async (id: string, is_nsfw: boolean) => {
      await markMediaNSFW({
        variables: { id, is_nsfw },
        refetchQueries: [
          GqlOps.Query.BioV2FromSlugMedia451,
          GqlOps.Query.UserThumbnails,
          GqlOps.Query.ThreadMessageMedia,
          GqlOps.Query.UserMedia,
        ],
      });
      refetchAll?.();
    },
    setProfileImage: async (id: string) => {
      await setUserProfileImage({
        variables: { profile_media_id: id },
        refetchQueries: [GqlOps.Query.BioV2FromSlugBasics, GqlOps.Query.UserProfileImageHashData],
      });
      refetchAll?.();
    },
    moveImg: async ({ id: img_id, seq_no }, new_seq_no) => {
      if (!img_id) {
        console.warn("🚀 ~ file: me.tsx:51 ~ moveImg: ~ img_id is null", seq_no);
        return;
      }
      if (seq_no === null || seq_no === undefined) {
        console.warn("🚀 ~ file: me.tsx:51 ~ moveImg: ~ seq_no is null", seq_no);
        return;
      }
      console.warn("🚀 ~ file: me.tsx:58 ~ moveImg: ~ seq_no is null", {
        id,
        seq_no,
        new_seq_no,
      });
      const cache = apolloClient.cache;
      if (new_seq_no > seq_no) {
        const result = await moveImageUp({
          variables: {
            owner_id: id,
            img_id,
            current_seq_no: seq_no,
            new_seq_no,
          },
        });
        result?.data?.new_seq_nos?.returning?.map(({ id, seq_no }) =>
          cache.modify({
            id: cache.identify({
              __typename: "L_Image_With_Caption",
              id: id,
            }),
            fields: { seq_no: () => seq_no },
          })
        );
      } else {
        const result = await moveImageDown({
          variables: {
            owner_id: id,
            img_id,
            current_seq_no: seq_no,
            new_seq_no,
          },
        });
        result?.data?.new_seq_nos?.returning?.map(({ id, seq_no }) =>
          cache.modify({
            id: cache.identify({
              __typename: "L_Image_With_Caption",
              id: id,
            }),
            fields: { seq_no: () => seq_no },
          })
        );
      }
      cache.modify({
        id: cache.identify({
          __typename: "L_Image_With_Caption",
          id: img_id,
        }),
        fields: { seq_no: () => new_seq_no },
      });
    },
    swapImgSeqNos: async (
      { id: fst_id, seq_no: fst_seq_no }: BioImage,
      sndImg: undefined | BioImage
    ) => {
      if (!sndImg) {
        return;
      }
      const { id: snd_id, seq_no: snd_seq_no } = sndImg;
      if (
        !fst_id ||
        !snd_id ||
        !(typeof snd_seq_no === "number") ||
        !(typeof fst_seq_no === "number")
      ) {
        const vars = { fst_id, snd_id, fst_seq_no, snd_seq_no };
        return;
      }
      const variables = { fst_id, snd_id, fst_seq_no, snd_seq_no };
      await swapUploadSequenceNumbers({ variables });
      const cache = apolloClient.cache;
      const fstCacheImage = cache.identify({
        __typename: "L_Image_With_Caption",
        id: fst_id,
      });
      const sndCacheImage = cache.identify({
        __typename: "L_Image_With_Caption",
        id: snd_id,
      });
      cache.modify({
        id: fstCacheImage,
        fields: {
          seq_no: () => snd_seq_no,
        },
      });
      cache.modify({
        id: sndCacheImage,
        fields: {
          seq_no: () => fst_seq_no,
        },
      });
    },
    togglePinnedImage: async (id: string, date: string | null) => {
      await togglePinedMedia({
        variables: { id, pinned_at: date },
        refetchQueries: [
          GqlOps.Query.BioV2FromSlugMedia451,
          GqlOps.Query.UserThumbnails,
          GqlOps.Query.ThreadMessageMedia,
          GqlOps.Query.UserMedia,
        ],
      });
      refetchAll?.();
    },
    // moveImageUp: async (seqNo: number, id: string) => {

    // },
    // moveImageDown: async (seqNo: number, id: string) => {},
  };
}
