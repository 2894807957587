import {
  GqlOps,
  useAddReactionMutation,
  useDeletemoveReactionMutation,
  useMyReactionsQuery,
  useReactionsQuery,
  useReactionsWithProfileQuery,
} from "shared/dist/__generated__/components";
import { classNames, filterNulls, pairsToArrayRecord } from "shared/dist/util";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { faSmilePlus } from "@fortawesome/pro-regular-svg-icons";
import { useMyId } from "shared/dist/auth-data";

type ReactionRecord = { reaction: string; count: number; includes_mine: boolean };
type ReactionsContext = {
  track: (objectId: string) => void;
  untrack: (objectId: string) => void;
  reactions: Record<string, Array<ReactionRecord>>;
};
const ReactionsContext = React.createContext<ReactionsContext>({
  track: () => {},
  untrack: () => {},
  reactions: {},
});

type Reaction = {
  __typename?: "reactions" | undefined;
  id: string;
  object_id: string;
  reaction: string;
  owner_summary?:
    | {
        __typename?: "user_summaries" | undefined;
        slug?: string | null | undefined;
      }
    | null
    | undefined;
};

type AllReactions = {
  [key: string]: { [slug: string]: Reaction };
};

export const useReaction = (objectId: string) => {
  const { track, untrack, reactions } = React.useContext(ReactionsContext);
  React.useEffect(() => {
    track(objectId);
    return () => untrack(objectId);
  }, []);
  const [addReaction] = useAddReactionMutation();
  const [deleteReaction] = useDeletemoveReactionMutation();
  const myReactions = React.useMemo(
    () =>
      new Set(reactions[objectId]?.filter((r) => r.includes_mine)?.map((r) => r.reaction) ?? []),
    [reactions]
  );
  const toggleReaction = React.useCallback(
    async (emoji: string) => {
      if (myReactions.has(emoji)) {
        console.log("🚀 ~ file: reactions.tsx:48 ~ DELETING emoji:", emoji);
        await deleteReaction({
          variables: { object_id: objectId, reaction: emoji },
          refetchQueries: [GqlOps.Query.Reactions, GqlOps.Query.MyReactions],
        });
      } else {
        await addReaction({
          refetchQueries: [GqlOps.Query.Reactions, GqlOps.Query.MyReactions],
          variables: { object_id: objectId, reaction: emoji },
        });
      }
    },
    [addReaction, deleteReaction, myReactions, objectId]
  );
  return { reactions: reactions[objectId], toggleReaction, myReactions };
  // return reactions[objectId]
};

export const ReactionsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const myId = useMyId();
  const [trackedObjectIds, setTrackedObjectIds] = React.useState<Record<string, number>>({});
  const reactionsQuery = useReactionsQuery({
    variables: { object_ids: Object.keys(trackedObjectIds) },
  });
  const trackObjectId = (objectId: string) =>
    setTrackedObjectIds((tmp) => ({
      ...tmp,
      [objectId]: 1 + (tmp[objectId] ?? 0),
    }));
  const untrackObjectId = (objectId: string) =>
    setTrackedObjectIds((tmp) => {
      const newCount = (tmp[objectId] ?? 0) - 1;
      if (newCount <= 0) {
        const { [objectId]: _, ...rest } = tmp;
        return rest;
      }
      return {
        ...tmp,
        [objectId]: newCount,
      };
    });

  const myReactionsQuery = useMyReactionsQuery({
    skip: !myId,
    variables: { object_ids: Object.keys(trackedObjectIds) },
  });

  const reactions = React.useMemo(() => {
    try {
      const myReactions = pairsToArrayRecord(
        myReactionsQuery.data?.reactions?.map((r) => [r.object_id, r.reaction]) ?? []
      );
      return pairsToArrayRecord(
        filterNulls(
          reactionsQuery.data?.reaction_counts?.map((r) =>
            r.object_id && r.reaction
              ? [
                  r.object_id,
                  {
                    reaction: r.reaction,
                    count: r.count,
                    includes_mine: !!myReactions[r.object_id]?.includes?.(r.reaction),
                  },
                ]
              : null
          ) ?? []
        )
      );
    } catch (e) {
      console.error("🚀 ~ file: reactions.tsx ERROR :94 ~ reactions ~ e", e);
      return {};
    }
  }, [reactionsQuery.data, myReactionsQuery.data]);
  // console.log(
  //   "🚀 ~ file: reactions.tsx:94 ~ reactions ~ reactions:",
  //   reactions,
  //   trackedObjectIds,
  //   reactionsQuery.data?.reaction_counts,
  //   myReactionsQuery?.data?.reactions
  // );
  return (
    <ReactionsContext.Provider
      value={{
        track: trackObjectId,
        untrack: untrackObjectId,
        reactions,
      }}
    >
      {children}
    </ReactionsContext.Provider>
  );
};

export function ReactionPills({ objectId }: { objectId: string }): React.JSX.Element {
  const reaction = useReaction(objectId);
  if (!reaction.reactions?.length) {
    return <></>;
  }
  return (
    <div className="flex flex-row gap-1">
      {reaction.reactions?.map((r) => (
        <span
          key={r.reaction}
          onClick={(e) => {
            reaction.toggleReaction(r.reaction);
            e.stopPropagation();
          }}
          style={{ borderWidth: "1px" }}
          className={clsx(
            "ReactionsPills text-xs px-1 cursor-pointer py-[0.1rem] rounded-md",
            r.includes_mine ? "bg-slate-600 text-slate-100" : "bg-slate-200 border-slate-600"
          )}
        >
          {r.reaction}
          {"\u00A0"}
          {r.count}
        </span>
      ))}
    </div>
  );
}

export function AddReaction({
  reverse,
  objectId,
  buttonClassName,
}: {
  reverse?: boolean;
  objectId: string;
  buttonClassName?: string;
}): React.JSX.Element {
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const hidePicker = React.useCallback(() => setShowEmojiPicker(false), [setShowEmojiPicker]);
  const myId = useMyId();
  const reaction = useReaction(objectId);
  const { myReactions } = reaction;
  const [buttonCoordinates, setButtonCoordinates] = React.useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    window.addEventListener("scroll", hidePicker, true);
    // window.addEventListener("click", hidePicker, true);
    return () => {
      window.removeEventListener("scroll", hidePicker, true);
      // window.removeEventListener("click", hidePicker, true);
    };
  }, [hidePicker]);

  const select = (emoji: string) => {
    setShowEmojiPicker(false);
    reaction.toggleReaction(emoji);
  };
  const emojiString =
    "\u2764\u{1f44d}\u{1f525}\u{1f44e}\u{1f64f}\u{1f602}\u{1f62e}\u{1f92f}\u{1f622}";

  const emoji = Array.from(emojiString);
  if (!myId) {
    return <></>;
  }
  return (
    <span className="inline-block relative">
      {showEmojiPicker &&
        createPortal(
          <div
            className="fixed top-0 right-0 left-0 bottom-0"
            onClick={(e) => {
              e.stopPropagation();
              setShowEmojiPicker(false);
            }}
          >
            <div
              className={classNames(
                "EmojiPickerPopup absolute flex items-center justify-start left-0 right-0",
                reverse ? "flex-row-reverse" : "flex-row"
              )}
              style={{
                top: `${buttonCoordinates.y - 48}px`,
                zIndex: 1000,
              }}
            >
              <div
                className="grow-0 shrink"
                style={{
                  flexBasis: reverse
                    ? `calc(100vw - ${buttonCoordinates.x + buttonCoordinates.width}px)`
                    : buttonCoordinates.x + "px",
                }}
              />
              <div
                className={classNames(
                  "flex flex-0 flex-row items-center justify-start bg-opacity-80 px-2 rounded-full bg-secondary-content"
                  // reverse && "right-0"
                )}
                // style={{
                //   ...(reverse
                //     ? { right: `calc(100vw - ${buttonCoordinates.x}px)` }
                //     : { left: `${buttonCoordinates.x}px` }),
                // }}
              >
                {emoji.map((selectedEmoji) => (
                  <button
                    className={classNames(
                      "text-xl lg:text-2xl flex-0 btn-ghost rounded-full h-[2rem] w-[2rem] lg:h-[3rem] lg:w-[3rem] hover:bg-primary ",
                      myReactions.has(selectedEmoji) && "bg-primary"
                    )}
                    key={selectedEmoji}
                    onClick={(evt) => {
                      select(selectedEmoji);
                      evt.stopPropagation();
                    }}
                  >
                    {selectedEmoji}
                  </button>
                ))}
              </div>
            </div>
          </div>,
          document.body
        )}
      <button
        className={classNames("text-primary", buttonClassName)}
        ref={buttonRef}
        onClick={(e) => {
          const rect = buttonRef?.current?.getBoundingClientRect();
          console.log("🚀 ~ file: reactions.tsx:275 ~ rect:", rect);
          setButtonCoordinates({
            x: rect?.left ?? 0,
            y: rect?.top ?? 0,
            width: rect?.width ?? 0,
            height: rect?.height ?? 0,
          });
          setShowEmojiPicker(!showEmojiPicker);
          e.stopPropagation();
        }}
      >
        <FontAwesomeIcon icon={faSmilePlus} fixedWidth />
      </button>
    </span>
  );
}

export const useAllReactions = (object_id: string) => {
  const { track, untrack, reactions } = React.useContext(ReactionsContext);

  const { data, refetch } = useReactionsWithProfileQuery({
    variables: { object_id },
  });

  React.useEffect(() => {
    track(object_id);
    refetch();
    return () => untrack(object_id);
  }, [reactions]);

  const reactionArray = data?.reactions;

  const queryData: AllReactions =
    reactionArray?.reduce((acc, curr) => {
      const slug = curr.owner_summary?.slug || "FOO";

      if (!acc[curr.object_id]) {
        acc[curr.object_id] = {};
      }

      if (!acc[curr.object_id][slug]) {
        acc[curr.object_id][slug] = curr;
      } else {
        acc[curr.object_id][slug] = {
          ...acc[curr.object_id][slug],
          reaction: acc[curr.object_id][slug].reaction + curr.reaction,
        };
      }

      return acc;
    }, {} as AllReactions) || {};

  return { reactions: queryData[object_id] };
};

export function AddReactionBlack({
  reverse,
  objectId,
  buttonClassName,
}: {
  reverse?: boolean;
  objectId: string;
  buttonClassName?: string;
}): React.JSX.Element {
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const hidePicker = React.useCallback(() => setShowEmojiPicker(false), [setShowEmojiPicker]);
  const myId = useMyId();
  const reaction = useReaction(objectId);
  const { myReactions } = reaction;
  const [buttonCoordinates, setButtonCoordinates] = React.useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    window.addEventListener("scroll", hidePicker, true);
    // window.addEventListener("click", hidePicker, true);
    return () => {
      window.removeEventListener("scroll", hidePicker, true);
      // window.removeEventListener("click", hidePicker, true);
    };
  }, [hidePicker]);

  const select = (emoji: string) => {
    setShowEmojiPicker(false);
    reaction.toggleReaction(emoji);
  };
  const emojiString =
    "\u2764\u{1f44d}\u{1f525}\u{1f44e}\u{1f64f}\u{1f602}\u{1f62e}\u{1f92f}\u{1f622}";

  const emoji = Array.from(emojiString);
  if (!myId) {
    return <></>;
  }
  return (
    <span className="inline-block relative">
      {showEmojiPicker &&
        createPortal(
          <div
            className="fixed lg:left-[60vw] md:top-[-1vw] top-0 right-0 left-0 bottom-0 z-50  touch-none"
            onClick={(e) => {
              e.stopPropagation();
              setShowEmojiPicker(false);
            }}
          >
            <div
              className={classNames(
                "EmojiPickerPopup absolute flex items-center justify-center top-1/2 left-1/2 transform -translate-x-1/2 ",
                reverse ? "flex-row-reverse" : "flex-row"
              )}
              style={{
                top: `${buttonCoordinates.y - 40}px`,
                zIndex: 1000,
              }}
            >
              <div
                className="grow-0 shrink"
                style={{
                  flexBasis: reverse
                    ? `calc(100vw - ${buttonCoordinates.x + buttonCoordinates.width}px)`
                    : buttonCoordinates.x + "px",
                }}
              />
              <div
                className={classNames(
                  "flex flex-0 flex-row items-center justify-start px-2 rounded-full bg-primary"
                  // reverse && "right-0"
                )}
                // style={{
                //   ...(reverse
                //     ? { right: `calc(100vw - ${buttonCoordinates.x}px)` }
                //     : { left: `${buttonCoordinates.x}px` }),
                // }}
              >
                {emoji.map((selectedEmoji) => (
                  <button
                    className={classNames(
                      "animate-wiggle text-xl lg:text-2xl bg-opacity-80 flex-0 btn-ghost rounded-full h-[2rem] w-[2rem] lg:h-[3rem] lg:w-[3rem] hover:bg-primary",
                      myReactions.has(selectedEmoji) && "bg-content"
                    )}
                    key={selectedEmoji}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      select(selectedEmoji);
                    }}
                    style={{
                      animationDelay: `${Math.random() * 0.3}s`, // This will add a random delay up to 2 seconds
                    }}
                  >
                    {selectedEmoji}
                  </button>
                ))}
              </div>
            </div>
          </div>,
          document.body
        )}
      <button
        className={classNames("text-secondary-content", buttonClassName)}
        ref={buttonRef}
        onClick={(e) => {
          e.stopPropagation();
          const rect = buttonRef?.current?.getBoundingClientRect();
          console.log("🚀 ~ file: reactions.tsx:275 ~ rect:", rect);
          setButtonCoordinates({
            x: rect?.left ?? 0,
            y: rect?.top ?? 0,
            width: rect?.width ?? 0,
            height: rect?.height ?? 0,
          });
          setShowEmojiPicker(!showEmojiPicker);
        }}
      >
        <FontAwesomeIcon icon={faSmilePlus} fixedWidth color={"black"} />
      </button>
    </span>
  );
}
