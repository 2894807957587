import { Controller, useForm } from "react-hook-form";
import {
  Feature_Flags_Enum,
  useCreateNewEvent551Mutation,
  useEventDetails440Query,
  useUpdateEvent440Mutation,
} from "shared/dist/__generated__/components";
import { H2, H3 } from "shared-web-react/dist/widgets/text";
import { Link, useNavigate, useParams } from "react-router-dom";
import { P, match } from "ts-pattern";
import { allRoutes, useMkImgUrl } from "../../../util/routes";

import { Container } from "../../../widgets/container";
import { ContentBox } from "../../../widgets/content-box";
import { DateTime } from "luxon";
// import { DevTool } from "@hookform/devtools";
import { EventMediaUploader } from "./event-media-uploader";
import { EventTagsForSelectPills } from "./common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ForceAddAttendee } from "./force-add-attendee";
import { Input } from "../../../widgets/input";
import { PersonaButton } from "../../user-page/settings/identity/persona";
import React from "react";
import { SelectPills } from "../../../widgets/inputs/select-pills";
import { SpinnerButton } from "../../../widgets/spinner-button";
import { UserPageContainer } from "../../user-page/common";
import { createPortal } from "react-dom";
import { faFaceParty } from "@fortawesome/pro-duotone-svg-icons";
import { navHeaderState } from "shared-web-react/dist/widgets/nav-stack-view";
import { useIdentityVerifiedStatus } from "../../user-page/settings/identity/common";
import { useIsDev } from "shared/dist/util/env";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useMyId } from "shared/dist/auth-data";
import { z } from "zod";

type CreateEventInputs = {
  name: string;
  start_time: string;
  start_day: string;
  duration_minutes: number;
  is_public: boolean;
  timezone: string;
  location_description?: null | string;
  address?: null | string;
  description?: null | string;
  display_dark_mode: boolean;
  url_slug: string;
  tags: Array<string>;
  invitation_code: string;
  media_upload_id: string;
};

const mkId = () => `${Math.floor(Math.random() * 100000000)}`;
export function EditEvent(): React.JSX.Element {
  const existingEventId = useParams<{ event_id: string }>().event_id;
  const [mediaUploadId, setMediaUploadId] = React.useState<undefined | string>();
  const [apiError, setApiError] = React.useState<string | null>(null);
  const my_id = useMyId();
  const { verified } = useIdentityVerifiedStatus();
  const queryExistingEventResults = useEventDetails440Query({
    skip: !existingEventId,
    variables: { event_id: existingEventId! },
  });

  const isEnabled = useIsFeatureEnabled(Feature_Flags_Enum.EventsCreateV00);
  const [endDtPreview, setEndDtPreview] = React.useState("");
  const [updateEventMutation, updateEventResults] = useUpdateEvent440Mutation();
  const [saveEventMutation, saveEventResults] = useCreateNewEvent551Mutation();
  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { isValid, errors, ...formState },
  } = useForm<CreateEventInputs>({
    mode: "all",
    defaultValues: {
      start_day: DateTime.local().plus({ days: 7 }).toISODate() ?? undefined,
      duration_minutes: 120,
      display_dark_mode: false,
      is_public: true,
      start_time: "20:00",
      timezone: "America/Los_Angeles",
    },
  });

  const existingEvent = queryExistingEventResults?.data?.events_by_pk;
  React.useEffect(() => {
    if (!existingEvent) {
      return;
    }
    const start_dt = existingEvent?.start ? DateTime.fromISO(existingEvent.start) : null;
    const start_day = start_dt?.toISODate();
    if (!start_day) {
      console.error("edit-event.tsx:85 ~ React.useEffect ~ start_dt invalid", start_dt);
      return;
    }
    if (!start_dt) {
      return;
    }
    const tags = match(z.array(z.string()).safeParse(existingEvent.tags))
      .with({ data: P.select() }, (p) => p)
      .otherwise(() => []);
    setValue("name", existingEvent.name);
    setValue("is_public", existingEvent.is_public);
    setValue("start_day", start_day);
    setValue("start_time", start_dt.toFormat("T"));
    setValue("duration_minutes", existingEvent.duration);
    setValue("media_upload_id", existingEvent.media_upload_id || mediaUploadId || "");
    setValue("location_description", existingEvent.location_description);
    setValue("url_slug", existingEvent.url_slug);
    setValue("address", existingEvent.address);
    setValue("tags", tags ?? []);
    setValue("description", existingEvent.description);
    setValue("display_dark_mode", existingEvent.display_dark_mode);
    setValue("invitation_code", existingEvent.invitation_code || "");
  }, [existingEvent, mediaUploadId]);

  const isDev = useIsDev();
  const navigate = useNavigate();
  const onSubmit = React.useCallback(
    async ({
      duration_minutes,
      start_day,
      name,
      is_public,
      invitation_code,
      description,
      display_dark_mode,
      location_description,
      address,
      url_slug,
      start_time,
      timezone,
      tags,
    }: CreateEventInputs) => {
      const startDt = DateTime.fromISO(`${start_day}T${start_time}`, {
        zone: timezone,
      }).toUTC();
      const start = startDt.toISO();
      if (!start) {
        console.error("edit-event:131, startDt invalid");
        return;
      }
      const variables = {
        duration: duration_minutes,
        start,
        location_description,
        address,
        description,
        display_dark_mode,
        is_public,
        url_slug,
        tags,
        name,
        invitation_code: invitation_code?.length ? invitation_code : mkId(),
      };

      if (existingEventId) {
        const _ = await updateEventMutation({
          variables: {
            event_id: existingEventId,
            changes: variables,
          },
        });
        navigate(
          allRoutes.EVENTS.DETAILS.buildPath({
            event_id: existingEventId,
          })
        );
      } else {
        try {
          const res = await saveEventMutation({
            variables: {
              ...variables,
              media_upload_id: mediaUploadId!,
              user_id: my_id!,
              invitation_code:
                variables.is_public || !variables.invitation_code.length
                  ? mkId()
                  : variables.invitation_code,
            },
          });
          if (!res.data) {
            alert("Could not save event. Check that URL is unique.");
          }
          if (res.data?.insert_events_one?.id) {
            navigate(
              allRoutes.EVENTS.DETAILS.buildPath({
                event_id: res.data?.insert_events_one?.id,
              }),
              {
                state: navHeaderState("Make more edits"),
              }
            );
          }
        } catch (err) {
          alert("Error saving event");
        }
      }
    },
    [mediaUploadId]
  );
  const mkImgUrl = useMkImgUrl();

  const eventImgUrl = mediaUploadId
    ? mkImgUrl(mediaUploadId, "1280")
    : existingEvent?.event_profile_image_with_assets?.full_1280_presigned_url?.presigned_url;

  React.useEffect(() => {
    const sub = watch((values) => {
      const startDt = DateTime.fromISO(`${values.start_day}T${values.start_time}`, {
        zone: values.timezone,
      });
      setEndDtPreview(
        startDt
          .plus({ minutes: values.duration_minutes ?? 0 })
          .toLocaleString(DateTime.DATETIME_SHORT)
      );
    });
    return () => sub.unsubscribe();
  }, [watch, setEndDtPreview]);

  if (!isEnabled) return <AskForAccess />;

  const isPublic = true; // watch("is_public");

  return (
    <UserPageContainer>
      <Container size="xs" className="flex flex-col h-full gap-2 pb-4 items-stretch">
        {verified ? (
          <>
            <ContentBox className="px-3 pb-1">
              <H2 className=" my-2 self-start lg:self-center">
                {existingEvent ? "Edit" : "New"} Event:
              </H2>
              {existingEventId && (
                <div>
                  <Link
                    className="link"
                    to={allRoutes.EVENTS.DETAILS.buildPath({
                      event_id: existingEventId,
                    })}
                  >
                    Preview Event
                  </Link>
                </div>
              )}
              {eventImgUrl && (
                <div
                  className="w-1/2 max-w-[256px] aspect-video bg-no-repeat bg-cover bg-center"
                  style={{ backgroundImage: `url(${eventImgUrl})` }}
                />
              )}
              <div>
                <label className="label">
                  <span className="label-text">
                    Event Cover Photo <span className="text-error italic opacity-70">*</span>
                  </span>
                </label>
                <EventMediaUploader
                  event_id={existingEventId}
                  mediaUploadId={mediaUploadId}
                  setMediaUploadId={(id) => setMediaUploadId(id)}
                />
              </div>
              {existingEventId ? (
                <>
                  <div>
                    <ForceAddAttendee event_id={existingEventId} />
                  </div>
                </>
              ) : (
                <p className="italic">
                  after an event is created you can come back here to add attendees
                </p>
              )}
            </ContentBox>
            <ContentBox className="px-3 pb-1">
              <form onSubmit={handleSubmit(onSubmit)} className="w-full gap-2 pb-16">
                {/* {isDev && createPortal(<DevTool control={control} />, document.body)} */}
                <div className="form-control w-full name-field">
                  <label className="label">
                    <span className="label-text">
                      Event Name <span className="text-error italic opacity-70">*</span>
                    </span>
                  </label>
                  <Input
                    type="text"
                    placeholder="Vandersexxx"
                    {...register("name", {
                      maxLength: {
                        value: 255,
                        message: "Please enter something under 255 characters",
                      },
                      pattern: {
                        value: /^[a-z0-9][-a-z0-9_ ]*[a-z0-9]$/i,
                        message: "Only letters, numbers, spaces, and dashes are allowed",
                      },
                      required: { value: true, message: "We need a name.." },
                      minLength: {
                        value: 6,
                        message: "Plesae write a little more... (at least 6 letters)",
                      },
                    })}
                  />
                  <label className="label">
                    <span className="label-text-alt text-error">
                      {errors.name?.message ?? "\u00A0"}
                    </span>
                  </label>
                </div>
                <div className="form-control w-full name-field">
                  <label className="label">
                    <span className="label-text">
                      Event URL <span className="text-error italic opacity-70">*</span>
                    </span>
                  </label>
                  <Input
                    type="text"
                    placeholder="edc-2023"
                    {...register("url_slug", {
                      maxLength: {
                        value: 255,
                        message: "Please enter something under 255 characters",
                      },
                      minLength: {
                        value: 6,
                        message: "Please enter at least 6 characters",
                      },
                      pattern: {
                        value: /^[a-z0-9][-a-z0-9]*[a-z0-9]$/i,
                        message: "Only letters, numbers, and dashes are allowed",
                      },
                      required: { value: true, message: "We need a URL.." },
                    })}
                  />
                  <label className="label">
                    <span className="label-text-alt text-error">
                      {errors.url_slug?.message ?? "\u00A0"}
                    </span>
                  </label>
                </div>
                <div className="form-control w-full name-field">
                  <label className="label">
                    <span className="label-text">Address (visible to attendees):</span>
                  </label>
                  <Input
                    type="text"
                    placeholder="A WEHO Alley or something"
                    {...register("address", {
                      maxLength: {
                        value: 255,
                        message: "Please enter something under 255 characters",
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9\s,.'-]{3,}$/,
                        message: "Only letters, numbers, spaces, and dashes are allowed",
                      },
                    })}
                  />
                  <label className="label">
                    <span className="label-text-alt text-error">
                      {errors.address?.message ?? "\u00A0"}
                    </span>
                  </label>
                </div>
                <div className="form-control w-full name-field">
                  <label className="label">
                    <span className="label-text">General Location (publicly visible):</span>
                  </label>
                  <Input
                    type="text"
                    placeholder="A WEHO Alley or something"
                    {...register("location_description", {
                      maxLength: {
                        value: 255,
                        message: "Please enter something under 255 characters",
                      },
                      pattern: {
                        value: /^[a-z0-9][-a-z0-9_ ]*[a-z0-9]$/i,
                        message: "Only letters, numbers, spaces, and dashes are allowed",
                      },
                    })}
                  />
                  <label className="label">
                    <span className="label-text-alt text-error">
                      {errors.location_description?.message ?? "\u00A0"}
                    </span>
                  </label>
                </div>
                <div className="form-control w-full name-field">
                  <label className="label">
                    <span className="label-text">Details:</span>
                  </label>
                  <textarea
                    className="textarea textarea-bordered w-full "
                    placeholder="Some useful details about the event"
                    {...register("description", {
                      maxLength: {
                        value: 19999,
                        message: "Please enter something under 20000 characters",
                      },
                    })}
                  />
                  <label className="label">
                    <span className="label-text-alt text-error">
                      {errors.description?.message ?? "\u00A0"}
                    </span>
                  </label>
                </div>

                {/* <div className="form-control w-full name-field">
              <label className="label cursor-pointer">
                <span className="label-text">Event is Private:</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-lg checkbox-primary"
                  {...register("is_public", {
                    onChange: (e) => {
                      setValue("is_public", !e.target.checked, { shouldValidate: true });
                      setValue("invitation_code", "", { shouldValidate: true });
                    },
                  })}
                  checked={!isPublic}
                />
              </label>
            </div> */}

                <div className="form-control w-full name-field">
                  <label className="label cursor-pointer">
                    <span className="label-text">Display Splash page in Dark Mode?:</span>
                    <input
                      type="checkbox"
                      className="checkbox checkbox-lg checkbox-primary"
                      {...register("display_dark_mode", {
                        onChange: (e) => {
                          const checked = e.target.checked;
                          setValue("display_dark_mode", checked, {
                            shouldValidate: true,
                          });
                        },
                      })}
                    />
                  </label>
                </div>

                {!isPublic && (
                  <div className="form-control w-full name-field">
                    <label className="label">
                      <span className="label-text">
                        Invitation Code <span className="text-error italic opacity-70">*</span>
                      </span>
                    </label>
                    <Input
                      type="text"
                      placeholder="Fluggaenk...."
                      {...register("invitation_code", {
                        maxLength: {
                          value: 32,
                          message: "Please enter something under 32 characters",
                        },
                        pattern: {
                          value: /^[a-z0-9][-a-z0-9_]*[a-z0-9]$/i,
                          message: "Only letters, numbers, and dashes are allowed",
                        },
                        minLength: { value: 3, message: "Minimum 3 characters" },
                        required: {
                          value: !isPublic,
                          message: "Invitation Code is required for private events",
                        },
                      })}
                    />
                    <label className="label">
                      <span className="label-text-alt text-error">
                        {errors.invitation_code?.message ?? "\u00A0"}
                      </span>
                    </label>
                  </div>
                )}
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      Start Day <span className="text-error italic opacity-70">*</span>
                    </span>
                  </label>
                  <Input
                    type="date"
                    {...register("start_day", {
                      required: { value: true, message: "We need a day.." },
                    })}
                  />
                  <label className="label">
                    <span className="label-text-alt text-error">
                      {errors.start_day?.message ?? "\u00A0"}
                    </span>
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      Start Time <span className="text-error italic opacity-70">*</span>
                    </span>
                  </label>
                  <Input
                    type="time"
                    {...register("start_time", {
                      required: { value: true, message: "We need a day.." },
                    })}
                  />
                  <label className="label">
                    <span className="label-text-alt text-error">
                      {errors.start_time?.message ?? "\u00A0"}
                    </span>
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      Event Duration (in minutes){" "}
                      <span className="text-error italic opacity-70">*</span>
                    </span>
                  </label>
                  <Input
                    type="number"
                    inputMode="numeric"
                    {...register("duration_minutes", {
                      min: { value: 1, message: "Must be more than 0" },
                      required: { value: true, message: "We need a day.." },
                    })}
                  />
                  <label className="label">
                    <span className="label-text-alt text-error">
                      {errors.duration_minutes?.message ?? "\u00A0"}
                    </span>
                    <span className="label-text-alt">Ends at {endDtPreview}</span>
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Time Zone:</span>
                  </label>
                  <select
                    className="select w-full select-bordered mb-3 "
                    {...register("timezone")}
                    onChange={async (e) =>
                      setValue("timezone", e.target.value, { shouldValidate: true })
                    }
                  >
                    <option value="America/New_York">America/New_York</option>
                    <option value="America/Los_Angeles">America/Los_Angeles</option>
                  </select>
                </div>
                <div className="form-control w-full name-field">
                  <Controller
                    name="tags"
                    control={control}
                    render={({ field }) => (
                      <SelectPills
                        choices={EventTagsForSelectPills}
                        defaultValue={field.value}
                        onChange={async (choices) =>
                          field.onChange(choices.map(({ value }) => value))
                        }
                      />
                    )}
                  />
                </div>
                <div className="form-control w-full mt-4">
                  <SpinnerButton
                    onClick={handleSubmit(onSubmit)}
                    loading={saveEventResults.loading}
                    disabled={saveEventResults.loading || !isValid || !eventImgUrl}
                    className="btn btn-primary"
                    type="submit"
                  >
                    {existingEventId ? "Update Event" : "Create Event"}
                  </SpinnerButton>
                </div>
              </form>
            </ContentBox>{" "}
          </>
        ) : (
          <div className="flex flex-col justify-center items-center p-2">
            <p className="font-bold p-2">
              You must be verified in order to create an event. Please verify your identity.
            </p>
            {apiError && (
              <>
                <div className="divider my-0" />
                <p className="text-error">{apiError}</p>
              </>
            )}
            <PersonaButton
              onError={() => {
                setApiError("An error occurred while processing your verification.");
              }}
            />
          </div>
        )}
      </Container>
    </UserPageContainer>
  );
}

function AskForAccess(): React.JSX.Element {
  return (
    <Container
      size="xs"
      className="h-full p-4 flex flex-col items-center text-candid-purple-700 justify-center space-y-8 text-center"
    >
      <FontAwesomeIcon icon={faFaceParty} fixedWidth size="5x" />
      <H3 className="font-semibold ">Creating events is in limited preview</H3>
      <div className="rounded-md bg-candid-purple-700 text-white text-left space-y-3 p-4 font-medium text-lg">
        <p>We're just testing this for now and plan to release it soon.</p>
        <p>
          If you'd like early access, please send an email to{" "}
          <a className="link" href="mailto:events-beta@candid.bio">
            events-beta@candid.bio
          </a>
          .
        </p>
      </div>
    </Container>
  );
}
