import {
  Feature_Flags_Enum,
  L_Vouch_Level,
  useUpdateInAppNotificationReadStatus510Mutation,
} from "shared/dist/__generated__/components";
import { faPartyBack, faPartyHorn, faStars, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PortalDialog } from "shared-web-react/dist/widgets/confirm-provider";
import React from "react";
import { VouchControl } from "./vouch-controls";
import { allRoutes } from "../../../util/routes";
import { classNames } from "shared/dist/util";
import highlyRecommendedBadge from "../../../assets/status-highly-recommended.png";
import { match } from "ts-pattern";
import recommendedBadge from "../../../assets/status-recommended.png";
import topUserBadge from "../../../assets/status-top-user.png";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import vouchCTABackground from "../../../assets/vouch_cta_background.png";
import mixpanel from "mixpanel-browser";

export function NewVouchLevelModal({
  first_vouch,
  vouchLevel,
  newLevelAchieved,
  notificationId,
  fromSlug,
  fromScreenName,
  mySlug,
  refetchVouchNotifications,
}: {
  first_vouch: boolean;
  vouchLevel: L_Vouch_Level | undefined;
  newLevelAchieved: boolean;
  notificationId: string;
  fromSlug: string;
  mySlug: string | undefined | null;
  fromScreenName: string;
  refetchVouchNotifications: () => void;
}): React.JSX.Element {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = React.useState(false);
  const dialogRef = React.useRef<HTMLDialogElement>(null);
  const requesterHasVouchEnabled = useIsFeatureEnabled(Feature_Flags_Enum.VouchingV00);

  if (!requesterHasVouchEnabled) return <></>;

  const [updateNotificationStatus] = useUpdateInAppNotificationReadStatus510Mutation();

  React.useEffect(() => {
    const shouldShowDialog = first_vouch || newLevelAchieved;
    setShowDialog(shouldShowDialog);

    if (shouldShowDialog) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }

    return () => dialogRef.current?.close();
  }, [first_vouch, newLevelAchieved]);

  const handleCloseAndNavigate = async () => {
    dialogRef.current?.close();
    setShowDialog(false);

    await updateNotificationStatus({ variables: { id: notificationId } });
    refetchVouchNotifications();

    if (!newLevelAchieved) {
      navigate(allRoutes.PROFILE.buildPath({ slug: vouchLevel && mySlug ? mySlug : fromSlug }), {
        state: {
          showVouchPopup: vouchLevel ? false : true,
          fromSlug,
          fromScreenName,
          notificationId,
        },
      });
    }
  };

  const vouchModalContent = match<[boolean, boolean, L_Vouch_Level | undefined], React.JSX.Element>(
    [newLevelAchieved, first_vouch, vouchLevel]
  )
    .with([true, false, L_Vouch_Level.TopUser], () => (
      <TopUserVouch handleClose={handleCloseAndNavigate} />
    ))
    .with([true, false, L_Vouch_Level.HighlyRecommended], () => (
      <HighlyRecommendedVouch handleClose={handleCloseAndNavigate} />
    ))
    .with([true, false, L_Vouch_Level.Recommended], () => (
      <RecommendedVouch handleClose={handleCloseAndNavigate} />
    ))
    .otherwise(() => <DefaultVouch handleClose={handleCloseAndNavigate} />);

  return (
    <PortalDialog
      ref={dialogRef}
      preventDismiss={true}
      dialogId="new-vouch-popup"
      hideBackdrop={true}
      className={classNames(
        showDialog ? "" : "hidden",
        "px-2 fixed inset-x-0 bottom-0 mb-4 flex min-w-full justify-center items-center !w-full"
      )}
      modalBoxClassName="relative bg-gradient-to-b from-[#B7BEFF] to-white rounded-3xl rounded-tl-none p-6 max-w-sm w-full shadow-lg"
    >
      {vouchModalContent}
    </PortalDialog>
  );
}

interface NewVouchReceivedPopupProps {
  fromSlug: string;
  fromScreenName: string;
  notificationId: string;
  onClose: () => void;
}

export function NewVouchReceivedPopup({
  fromSlug,
  fromScreenName,
  notificationId,
  onClose,
}: NewVouchReceivedPopupProps): React.JSX.Element | null {
  const requesterHasVouchEnabled = useIsFeatureEnabled(Feature_Flags_Enum.VouchingV00);
  const [updateNotificationStatus] = useUpdateInAppNotificationReadStatus510Mutation();
  const [showPopup, setShowPopup] = React.useState(true);

  if (!requesterHasVouchEnabled || !showPopup) return null;

  const handleClose = async () => {
    await updateNotificationStatus({
      variables: { id: notificationId },
    });
    setShowPopup(false);
    onClose();
    try {
      mixpanel.track("user_viewed_viewed_vouch_popup");
    } catch (e) {
      console.error("MP Error: ", e);
    }
  };

  return (
    <div className="my-4 mx-auto max-w-sm" onClick={handleClose}>
      <div className="relative bg-[#E6E9FF] rounded-3xl rounded-tl-none shadow-lg overflow-hidden">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${vouchCTABackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
          }}
        />
        <div className="relative z-10 p-6 flex flex-col items-center">
          <button
            onClick={handleClose}
            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          >
            <FontAwesomeIcon icon={faXmark} size="lg" color="black" />
          </button>
          <h2 className="text-lg font-semibold mb-2 mt-10 text-center">
            <FontAwesomeIcon icon={faStars} className="text-yellow-400 mr-2" />
            {fromScreenName} has vouched for you!
          </h2>
          <p className="text-sm mb-4 text-center">
            This means {fromScreenName} recommends you as a trusted member of the community. Vouches
            boost your credibility and your profile's visibility.
          </p>
          <span onClick={(e) => e.stopPropagation()}>
            <VouchControl
              slug={fromSlug}
              size="full"
              label={`Vouch for ${fromScreenName}?`}
              labelVouched={`Already vouched for ${fromScreenName}`}
              btnClassName="whitespace-nowrap w-full btn !btn-primary btn-md flex-row-center-center gap-1 !normal-case"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

function VouchModalContent({
  backgroundImage,
  title,
  description,
  perks,
  buttonText,
  onClick,
  preBtnText,
}: {
  backgroundImage: string;
  title: React.ReactNode;
  description: string;
  perks: React.ReactNode[];
  buttonText: string;
  onClick: () => void;
  preBtnText?: React.JSX.Element;
}) {
  return (
    <>
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
      <div className="relative flex flex-col items-center text-center p-4 sm:p-6 md:p-8 lg:p-10">
        <h2 className="text-lg sm:text-xl md:text-2xl font-bold mx-2 mt-8 mb-2 md:my-8">{title}</h2>
        <p className="mb-4 text-sm sm:text-md md:text-base text-center">{description}</p>
        <ul className="text-sm sm:text-md md:text-base text-center mb-4 md:mb-6">
          {perks.map((perk, index) => (
            <li key={index}>{perk}</li>
          ))}
        </ul>
        <p className="text-sm sm:text-md md:text-base text-center"> {preBtnText}</p>
        <button
          className="btn btn-primary w-full sm:w-auto mt-4 focus:outline-none"
          onClick={async () => await onClick()}
        >
          {buttonText}
        </button>
      </div>
    </>
  );
}

function TopUserVouch({ handleClose }: { handleClose: () => void }) {
  return (
    <VouchModalContent
      backgroundImage={vouchCTABackground}
      title={
        <div className="flex items-center">
          <img src={topUserBadge} alt="Top User Badge" className="mr-2 h-6 w-6" />
          <span>You're a Top Member!</span>
        </div>
      }
      description={`Welcome to the top! As a Top Member, you've unlocked exclusive perks, including an elevated badge and advanced vouching filters. You're now among the most trusted members on Candid—explore your new features and keep leading the way!`}
      perks={[
        <>💎 Elevated badge on Profile, Discovery, & more</>,
        <>💥 Exclusive filters for Top Members</>,
        <>🧐 Increased visibility into who vouched for others</>,
      ]}
      buttonText="Explore My Perks"
      onClick={async () => await handleClose()}
    />
  );
}

function HighlyRecommendedVouch({ handleClose }: { handleClose: () => void }) {
  return (
    <VouchModalContent
      backgroundImage={vouchCTABackground}
      title={
        <div className="flex items-center">
          <img
            src={highlyRecommendedBadge}
            alt="Highly Recommended Badge"
            className="mr-2 h-6 w-6"
          />
          <span>You're Highly Recommended!</span>
        </div>
      }
      description={`You're now Highly Recommended! Your new badge on Discovery highlights your top-tier status, making it easier for others to find and connect with you. Here's what you've just unlocked:`}
      perks={[
        <>⭐️ An updated status on your profile</>,
        <>🔥 An icon that displays on the Discovery</>,
        <>❤️ Your likes will stand out from standard members</>,
      ]}
      buttonText="Check out my Badge"
      onClick={async () => await handleClose()}
    />
  );
}

function RecommendedVouch({ handleClose }: { handleClose: () => void }) {
  return (
    <VouchModalContent
      backgroundImage={vouchCTABackground}
      title={
        <div className="flex items-center">
          <img src={recommendedBadge} alt="Recommended Badge" className="mr-2 h-6 w-6" />
          <span>You're a Recommended Member!</span>
        </div>
      }
      description={`You've reached Recommended status! This means others recognize your value on Candid, making you more visible and trusted in our community. We've added a “Recommended” badge to your profile! Your status will:`}
      perks={[
        <>👀 Increase your visibility on the platform</>,
        <>✅ Show that you're trusted by your network</>,
      ]}
      buttonText="Explore My Profile"
      onClick={async () => await handleClose()}
    />
  );
}

function DefaultVouch({ handleClose }: { handleClose: () => void }) {
  return (
    <VouchModalContent
      backgroundImage={vouchCTABackground}
      title={<span>🎉 You Received a Vouch!</span>}
      description={`Congratulations on receiving your first vouch! This is the start of building your reputation on Candid. Recommended members stand out on Candid in a few ways:`}
      perks={[
        <>
          📣 Their profile cards <strong>stand out</strong> on Discovery
        </>,
        <>
          ❤️‍🔥 Their likes and messages are <strong>prioritized</strong>
        </>,
        <>
          💸 They receive <strong>premium perks</strong> for free
        </>,
      ]}
      preBtnText={
        <>
          The more vouches you earn, the more you'll stand out. So keep connecting and watch your
          influence grow!
        </>
      }
      buttonText="View My Vouch"
      onClick={async () => await handleClose()}
    />
  );
}
