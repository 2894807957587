import { AuthDataManager, AuthDataManagerProps, useAuthData } from "shared/dist/auth-data";
import {
  secureStorageClear,
  secureStorageGet,
  secureStorageSet,
} from "../capacitor-plugins/secure-storage";
import { useEnv, useIsDev, useOrigin } from "shared/dist/util/env";

import { Capacitor } from "@capacitor/core";
import { CapacitorCookies } from "@capacitor/core";
import Cookies from "js-cookie";
import React from "react";
import { WebAuthDataImplementation } from "shared-web-react/dist/util/web-auth";

const isNativePlatform = Capacitor.isNativePlatform();

export function AuthDataImplementation({
  children,
  keyOverride,
}: {
  children: AuthDataManagerProps["children"];
  keyOverride?: string;
}): React.JSX.Element {
  const isNativePlatform = Capacitor.isNativePlatform();
  return isNativePlatform ? (
    <NativeAuthDataImplementation children={children} keyOverride={keyOverride} />
  ) : (
    <WebAuthDataImplementation children={children} keyOverride={keyOverride} />
  );
}

function NativeAuthDataImplementation({
  children,
  keyOverride,
}: {
  children: AuthDataManagerProps["children"];
  keyOverride?: string;
}): React.JSX.Element {
  const isDev = useIsDev();
  const defaultKey = `${useEnv("REACT_APP_PRODUCT_NAME")}-${useEnv("REACT_APP_ENV")}-${
    keyOverride ?? "auth"
  }-v001`;
  if (!isNativePlatform) throw new Error("WebAuthDataManager not supported without native");

  return (
    <AuthDataManager
      setSecureValue={secureStorageSet}
      getSecureValue={secureStorageGet}
      removeSecureValue={secureStorageClear}
      secureStorageKey={defaultKey}
    >
      {children}
    </AuthDataManager>
  );
}

export function useNativeCookieSetter() {
  const key = `${useEnv("REACT_APP_PRODUCT_NAME")}-${useEnv("REACT_APP_ENV")}-auth-v001`;
  const url = useEnv("REACT_APP_BASE_URI");
  const auth = useAuthData();
  const isDev = useIsDev();
  React.useEffect(() => {
    console.log(
      "🚀 - file: native-auth-data-manager.tsx:73 - React.useEffect - isNativePlatform:",
      isNativePlatform,
      auth?.token?.slice(0, 15),
      key
    );
    if (!isNativePlatform) {
      if (isDev) {
        if (auth?.token) {
          console.log(
            "🚀 - file: native-auth-data-manager.tsx:73 - NOT native, setting token: ",
            auth?.token?.slice(0, 5)
          );
          Cookies.set(key, auth.token, {
            domain: "http://localhost:53001",
          });
        } else {
          console.log("🚀 - file: native-auth-data-manager.tsx:80 - native,clear token");
          Cookies.remove(key, { domain: "http://localhost:53001" });
        }
      }
    } else {
      if (auth?.token) {
        console.log("🚀 - file: native-auth-data-manager.tsx:85 - native, setting token: ", {
          tok: auth?.token?.slice(0, 5),
          url,
          key,
        });
        CapacitorCookies.setCookie({ url, key, value: auth.token });
      } else {
        console.log("🚀 - file: native-auth-data-manager.tsx:80 - native,clear token");
        CapacitorCookies.deleteCookie({ url, key });
      }
    }
  }, [key, auth?.token, url, Capacitor, isDev]);
}
