import { EmailEditor } from "../../widgets/pickers/email-editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ObScreen } from "./widgets";
import { ObScreenProps } from "../../widgets/bio/bio-types";
import React from "react";
import { classNames } from "shared/dist/util";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import mixpanel from "mixpanel-browser";
// import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useMyId } from "shared/dist/auth-data";
import { useSetEmailMutation } from "shared/dist/__generated__/components";

export function ObEmail({ setLoading, setDisableButton }: ObScreenProps): React.JSX.Element {
  const [email, setEmail] = React.useState<string | null>(null);
  React.useEffect(() => {
    try {
      mixpanel.track("bio_onboarding");
    } catch (e) {
      console.error("MP Error: ", e);
    }
  }, []);
  const [emailMutation, emailResults] = useSetEmailMutation();
  const [emailFormLoading, setEmailFormLoading] = React.useState(false);
  const my_id = useMyId()!;
  // const addToast = useAddToast();
  const anyLoading = emailResults.loading || emailFormLoading;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;

  React.useEffect(() => {
    setLoading && setLoading(anyLoading);
    const isEmailValid = emailPattern.test(email!);
    setDisableButton && setDisableButton(!isEmailValid);
  }, [anyLoading, email]);

  return (
    <ObScreen title="What's your email address?">
      <div
        className={classNames(
          "flex flex-col gap-0 max-h-full min-h-0 h-full w-full overflow-hidden items-stretch"
        )}
      >
        <EmailEditor
          saveComponentOverride={<FontAwesomeIcon icon={faCheck} fixedWidth />}
          defaultValueTreatAsValid
          hideSubmitInstructions
          hideReset
          suppressToast
          onLoading={(b) => setEmailFormLoading(b)}
          onValid={(e) => setEmail(e)}
          onChange={(e) => setEmail(e)}
          className="border-b pl-1 border-gray-400 rounded-none w-full"
        />
        <div className="flex-1" />
      </div>
    </ObScreen>
  );
}
