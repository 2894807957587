import { PersonaButton } from "../screens/user-page/settings/identity/persona";
import React from "react";
import { useIdentityVerifiedStatus } from "../screens/user-page/settings/identity/common";
import { useLeftMargin } from "../screens/user-page/main-nav";
import { useMyId } from "shared/dist/auth-data";

type VerificationProps = {
  heading: string;
  text: string;
};

export function VerificationBox({ heading, text }: VerificationProps): React.JSX.Element {
  const myId = useMyId();
  const { verified } = useIdentityVerifiedStatus();
  const [showButton, setShowButton] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const [apiError, setApiError] = React.useState<string | null>(null);
  const marginLeft = useLeftMargin();

  const handleScroll = React.useCallback(() => {
    const modal = ref.current;
    if (!modal) return;
    const rect = modal.getBoundingClientRect();
    setShowButton(rect.bottom <= 75);
  }, []);

  React.useEffect(() => {
    handleScroll(); // Initial check
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (verified || !myId) return <></>;

  return (
    <div>
      {apiError && (
        <>
          <div className="divider my-0" />
          <p className="text-error">{apiError}</p>
        </>
      )}
      <div
        id="verification-modal"
        ref={ref}
        className="mx-auto w-full sm:max-w-md bg-neutral p-6 rounded-3xl shadow-lg text-left rounded-tl-none"
      >
        <div className="px-2 w-full text-center">
          <h3 className="text-lg font-bold m-0">{heading}</h3>
          <p className="pb-4">{text}</p>
        </div>
        <div className="flex justify-center">
          <PersonaButton
            beginBtnContent="Verify my Identity"
            beginBtnClassName="btn btn-primary btn-large w-3/4 sm:w-1/2"
            onError={() => {
              setApiError("An error occurred while processing your verification.");
            }}
          />
        </div>
      </div>
      {showButton && (
        <div
          className="w-11/12 left-0 fixed bottom-20 z-10 flex justify-center"
          style={{ marginLeft }}
        >
          <PersonaButton
            beginBtnContent="Verify my Identity"
            beginBtnClassName="btn btn-primary btn-large w-3/4 sm:w-1/2"
            onError={() => {
              setApiError("An error occurred while processing your verification.");
            }}
          />
        </div>
      )}
    </div>
  );
}
