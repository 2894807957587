import {
  GqlOps,
  Onboarding_Statuses_Enum,
  useSubmitInviteCodeMutation,
  useUserObStatusQuery,
} from "shared/dist/__generated__/components";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalContainer } from "../../widgets/container";
import { OB_LAST_PAGE_IDX } from "./main";
import { ObScreen } from "./widgets";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import { allRoutes } from "../../util/routes";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useMyId } from "shared/dist/auth-data";

export function ObInvitationCodeEntry(): React.JSX.Element {
  const [otp, setOtpInner] = React.useState("");
  const setOtp = React.useCallback(
    (newOtp: string) => {
      console.log(
        "🚀 - file: invitation-code-entry.tsx:26 - ObInvitationCodeEntry - newOtp:",
        newOtp
      );
      setOtpInner(() =>
        match(newOtp)
          .when(
            (s) => s.match(/^\s*\w{4}-\w{0,4}\s*$/i),
            () => newOtp.trim()
          )
          .when(
            (s) => s.match(/^\s*\w{0,4}\s*$/i),
            () => newOtp.trim()
          )
          .when(
            (s) => s.match(/^\s*\w{5,8}\s*$/i),
            () => newOtp.trim().slice(0, 4) + "-" + newOtp.trim().slice(4, 8)
          )
          .otherwise(() => newOtp.trim())
      );
    },
    [setOtpInner]
  );
  const [apiError, setApiError] = React.useState("");
  const navigate = useNavigate();
  const myId = useMyId();
  const [mutate, { loading, data }] = useSubmitInviteCodeMutation();
  const statusCheck = useUserObStatusQuery({
    skip: !myId,
    variables: {
      id: myId!,
    },
  });

  React.useEffect(() => {
    if (!otp.match(/^\w{4}-\w{4}$/i)) {
      setApiError("");
      return;
    }
    // const code = otp.slice(0, 4) + "-" + otp.slice(4, 8);
    mutate({ variables: { code: otp }, refetchQueries: [GqlOps.Query.UserUrlSlug] });
  }, [otp, mutate]);

  React.useEffect(() => {
    const resultType = data?.lm_submit_invitation_code?.__typename;
    if (!resultType) {
      setApiError("");
      return;
    }
    if (resultType === "L_Simple_Response_Error") {
      setApiError(data?.lm_submit_invitation_code?.human_readable_error ?? "Unknown Error");
      return;
    }
    if (resultType === "L_Simple_Response_Success") {
      // addToast({ color: "success", content: "Success!" });
      navigate(allRoutes.HOME.buildPath({}));
    }
  }, [navigate, data]);

  const showNextStepDialog =
    statusCheck.data?.users_by_pk?.onboarding_status === Onboarding_Statuses_Enum.V2_20LiveUser;

  return (
    // <div className="h-screen bg-primary flex flex-col items-stretch">
    <div className="h-full w-screen bg-primary flex flex-col items-stretch p-0">
      {/* <TopNavWithSpacer hideActions> */}
      <ModalContainer
        size="lg"
        className={clsx(
          "relative flex-1 flex flex-col max-lg:h-full justify-stretch items-center",
          "bg-primary-content max-h-full overflow-hidden px-4 py-2"
        )}
      >
        <div
          className="text-3xl relative top-9 z-10 mt-5 mb-10 self-start"
          onClick={() =>
            navigate(allRoutes.ONBOARDING.buildPath({}), {
              state: { startingIdx: OB_LAST_PAGE_IDX },
            })
          }
        >
          <FontAwesomeIcon icon={faChevronLeft} style={{ cursor: "pointer" }} />
        </div>
        <ObScreen title="Whats your Invite Code?">
          <div className={classNames("flex flex-col h-full items-center justify-center")}>
            {statusCheck.loading ? (
              <SpinnerCentered />
            ) : (
              <div className="">
                {/* <div className="text-center"> */}
                <div className="relative text-center">
                  <input
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="12ab-cd34"
                    maxLength={9}
                    className={
                      "input input-bordered input-lg  max-w-[80vw] w-64 text-center px-2 text-4xl font-bold "
                    }
                  />
                </div>

                <div className="text-error italic">
                  {apiError}
                  {"\u00A0"}
                </div>
                {/* </div> */}
                <div className="h-9 w-full text-center">{loading && <Spinner size="2x" />}</div>

                <p className="text-lg md:text-xl text-left">
                  Enter an invite code to join Candid immediately.
                  <br />
                  <br />
                  If you don't have a code, no worries—our team will review your information and get
                  you started soon!
                </p>
                <div className="flex-0 my-5 w-full">
                  <SpinnerButton
                    onClick={() =>
                      navigate(allRoutes.ONBOARDING.buildPath({}), {
                        state: { startingIdx: OB_LAST_PAGE_IDX },
                      })
                    }
                    // loading={fakeLoading}
                    // disabled={fakeLoading}
                    className="text-candid-purple-000 w-full py-2 px-6 rounded-full border-2 border-candid-purple-000 shadow-md hover:bg-candid-purple-200 hover:border-candid-purple-000 transition duration-150 ease-in-out"
                  >
                    I don't have an Invite Code
                  </SpinnerButton>
                </div>
              </div>
            )}
          </div>
        </ObScreen>
        {showNextStepDialog &&
          createPortal(
            <div className="modal modal-open">
              <div className="modal-box">
                <h3 className="font-bold text-lg">Good News!</h3>
                <p className="py-4">Looks like you're in! Next step...</p>
                <div className="modal-action">
                  <Link to={allRoutes.me.buildPath({})} className="btn">
                    Next Step
                  </Link>
                </div>
              </div>
            </div>,
            document.body
          )}
      </ModalContainer>
      {/* </TopNavWithSpacer> */}
    </div>
    // </div>
  );
}
