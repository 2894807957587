import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Outlet, useNavigate } from "react-router-dom";
import { RefetchProp, UserPageContainer } from "../common";
import { UserPageTab, UserPageTabLabel } from "../../../widgets/tabs";
import {
  faC,
  faCircleEnvelope,
  faCircleInfo,
  faHeartCirclePlus,
  faLock,
  faPerson,
} from "@fortawesome/pro-solid-svg-icons";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { H4 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../../util/routes";
import { useMySlug } from "../../bio/bio-settings";
import { useNeedsEmailOrVerification } from "../../../widgets/pickers/email-editor";

export type UserSettingsProps = {} & RefetchProp;

// export function DetailsSettings(): React.JSX.Element {
//   return (
//     <div className="pb-[40vh] space-y-2">
//       <BirthdayPicker />
//       <div className="divider divider-vertical w-1/2" />
//       <GenderPicker />
//       <div className="divider divider-vertical w-1/2" />
//       <PronounsPicker />
//       <div className="divider divider-vertical w-1/2" />
//       <OrientationPicker />
//       <div className="divider divider-vertical w-1/2" />
//       <LocationSettings />
//       <div className="divider divider-vertical w-1/2" />
//     </div>
//   );
// }

// Profile Personal Dating Privacy
export function SettingsIndexPage(): React.JSX.Element {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate(allRoutes.SETTINGS.PROFILE.buildPath({}));
  }, [navigate]);
  return <SpinnerFullScreen />;
}

const SettingsTabs: Array<{
  route: string;
  icon: FontAwesomeIconProps["icon"];
  label: string;
}> = [
  {
    label: "Profile",
    route: allRoutes.SETTINGS.PROFILE.buildPath({}),
    icon: faCircleInfo,
  },
  {
    label: "Personal",
    route: allRoutes.SETTINGS.PERSONAL.buildPath({}),
    icon: faPerson,
  },
  {
    label: "Dating",
    route: allRoutes.SETTINGS.DATING.buildPath({}),
    icon: faHeartCirclePlus,
  },
  {
    label: "Privacy",
    route: allRoutes.SETTINGS.PRIVACY.buildPath({}),
    icon: faLock,
  },
];

export function UserSettings({ refetch }: UserSettingsProps): React.JSX.Element {
  const showEmailBadge = useNeedsEmailOrVerification();
  const useNewLayout = true;
  const { slug } = useMySlug() ?? {};
  return (
    <div className="min-h-full max-w-screen relative">
      <div className="bg-white w-full top-0 bottom-0 -z-20">
        <UserPageContainer className="space-y-3 flex items-center gap-2">
          {slug && <Avatar slug={slug} tailwindSize="14" />}
          <H4 className=" my-2 self-start lg:self-center">My Settings:</H4>
        </UserPageContainer>
        <div className="relative">
          <div className="tabs-xs md:tabs-sm lg:tabs-md tabs-bordered tabs flex w-full flex-nowrap my-2 pb-1 justify-center">
            {useNewLayout ? (
              SettingsTabs.map(({ route, icon, label }) => (
                <UserPageTab
                  style={{ maxWidth: `${100 / SettingsTabs.length}vw` }}
                  route={route}
                  key={label}
                >
                  <FontAwesomeIcon icon={icon} fixedWidth />
                  <UserPageTabLabel>{label}</UserPageTabLabel>
                </UserPageTab>
              ))
            ) : (
              <>
                <UserPageTab route={allRoutes.SETTINGS.buildPath({})}>
                  <FontAwesomeIcon icon={faC} fixedWidth />
                  {false && showEmailBadge ? (
                    <div className="indicator">
                      <span className="indicator-item badge rounded-full bg-red-500 badge-sm max-lg:badge-xs" />
                      <UserPageTabLabel>Basics</UserPageTabLabel>
                    </div>
                  ) : (
                    <UserPageTabLabel>Basics</UserPageTabLabel>
                  )}
                </UserPageTab>
                <UserPageTab route={allRoutes.SETTINGS.DETAILS.buildPath({})}>
                  <FontAwesomeIcon icon={faCircleInfo} fixedWidth />
                  <UserPageTabLabel>Details</UserPageTabLabel>
                </UserPageTab>
                <UserPageTab route={allRoutes.SETTINGS.NOTIFICATIONS.buildPath({})}>
                  <FontAwesomeIcon icon={faCircleEnvelope} fixedWidth />
                  <UserPageTabLabel>Notifications</UserPageTabLabel>
                </UserPageTab>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="fixed top-0 bottom-0 right-0 left-0 bg-base-200 -z-10" />
      <UserPageContainer className="max-lg:pb-20 overflow-visible">
        <Outlet />
      </UserPageContainer>
    </div>
  );
}
