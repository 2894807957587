import { Capacitor, registerPlugin } from "@capacitor/core";

export interface LoggingPlugin {
  log(options: { value: string }): Promise<{ value: string }>;
  debug(options: { value: string }): Promise<{ value: string }>;
  info(options: { value: string }): Promise<{ value: string }>;
  warning(options: { value: string }): Promise<{ value: string }>;
  error(options: { value: string }): Promise<{ value: string }>;
}

const Logging = registerPlugin<LoggingPlugin>("Logging");

const valuesToString = (values: any[]) => ({
  value: values.map((v) => (typeof v === "string" ? v : JSON.stringify(v))).join(" "),
});

export const logger = {
  log(...args: Array<any>) {
    Logging.log(valuesToString(args));
  },
  debug(...args: Array<any>) {
    Logging.debug(valuesToString(args));
  },
  info(...args: Array<any>) {
    Logging.info(valuesToString(args));
  },
  warning(...args: Array<any>) {
    Logging.warning(valuesToString(args));
  },
  error(...args: Array<any>) {
    Logging.error(valuesToString(args));
  },
};

let isPatched = false;
const nativeConsole = console;
if (!isPatched && Capacitor.isNativePlatform()) {
  isPatched = true;
  logger.warning("MONKEY PATCHING CONSOLE");
  console.warn("MONKEY PATCHING CONSOLE");
  window.console = {
    ...nativeConsole,
    log: (...args: Array<any>) => {
      logger.log(...args);
      nativeConsole.log(...args);
    },
    debug: (...args: Array<any>) => {
      logger.debug(...args);
      nativeConsole.debug(...args);
    },
    info: (...args: Array<any>) => {
      logger.info(...args);
      nativeConsole.info(...args);
    },
    warn: (...args: Array<any>) => {
      logger.warning(...args);
      nativeConsole.warn(...args);
    },
    error: (...args: Array<any>) => {
      logger.error(...args);
      nativeConsole.error(...args);
    },
  };
}
