import {
  L_Discovery_Item,
  L_Relationship_Type_Enum,
  Relationship_Types_Enum,
  useBioFromSlugLazyQuery,
  useMyRelationshipsShortV410Query,
} from "shared/dist/__generated__/components";
import { P, match } from "ts-pattern";
import {
  ProfileList,
  ProfileListItem,
  profileListItemFromSummary,
} from "../../../widgets/profile-list";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ZeroScreenActiveFriendsList,
  ZeroScreenMutualFriendsList,
} from "../../../widgets/zero-screens";
import { faCirclesOverlap, faHeart, faUserGroupSimple } from "@fortawesome/pro-light-svg-icons";

import { AddThreadButton } from "../../../widgets/add-thread-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultipleTypeRelationshipControlButton } from "../../../widgets/add-relationship-button";
import { SearchBar } from "../../../widgets/search-bar";
import { allRoutes } from "../../../util/routes";
import { filterNulls } from "shared/dist/util";
import { useDebounce } from "use-debounce";
import { useExtendedFriendList } from "../../../widgets/use-extended-friends-list";
import { useMyId } from "shared/dist/auth-data";
import { useMyInfo } from "../../bio/bio-settings";
import { useNavigate } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";

type FriendsListProps = {
  friendType: "active" | "mutual-friends";
};

export function FriendsList({ friendType }: FriendsListProps): React.JSX.Element {
  const my_id = useMyId();
  const { slug: mySlug } = useMyInfo();
  const [searchTerm, setSearchTerm] = useState("");
  const { slug } = useTypedParams(allRoutes.PROFILE.FRIENDS);
  const navigate = useNavigate();

  const [runBioQuery, { data }] = useBioFromSlugLazyQuery();
  const [debouncedFilter, { isPending }] = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (slug) runBioQuery({ variables: { slug } });
  }, [slug, runBioQuery]);

  const { data: extendedFriendListData, loading: extendedFriendListLoading } =
    useExtendedFriendList({
      slug: mySlug!,
      userId: my_id!,
      includeRelationships: false,
      filter: debouncedFilter,
    });

  const { data: mutualData } = useMyRelationshipsShortV410Query({
    variables: { my_id: my_id ?? "", type: "friend" },
  });

  const isMutual = useCallback(
    (slug: string | null | undefined) => {
      if (!slug) return false;
      const myFriendsSlugs =
        mutualData?.relationships_union.map((r) => r.snd_user_summary?.slug) ?? [];
      return myFriendsSlugs.includes(slug);
    },
    [mutualData]
  );

  const profilesFromBio = useMemo(
    () =>
      filterNulls(
        data?.l_bio_public?.relationships
          ?.filter((f) => f.type === L_Relationship_Type_Enum.Friend)
          ?.map(profileListItemFromSummary) ?? []
      ),
    [data]
  );

  const friends = useMemo(
    () =>
      friendType === "mutual-friends"
        ? profilesFromBio.filter((p) => isMutual(p.slug))
        : profilesFromBio,
    [friendType, profilesFromBio, isMutual]
  );
  const isMyPage = data?.l_bio_public?.editable ?? false;

  const [cachedProfiles, setCachedProfiles] = useState<ProfileListItem[]>([]);

  const filteredProfiles = useMemo(() => {
    if (!searchTerm) return friends;

    const allProfiles = [...friends, ...(isMyPage ? extendedFriendListData ?? [] : [])];
    const uniqueProfilesMap = new Map<string, ProfileListItem>();

    allProfiles.forEach((profile) => {
      match(profile)
        .with({ slug: P.string, screenName: P.string }, (p) => {
          if (p.screenName.toLowerCase().startsWith(searchTerm.toLowerCase())) {
            uniqueProfilesMap.set(p.slug, p);
          }
        })
        .with(
          { user: { user_slug: P.string, user_screen_name: P.string, relationship: P.string } },
          (p) => {
            if (p.user.user_screen_name.toLowerCase().startsWith(searchTerm.toLowerCase())) {
              uniqueProfilesMap.set(p.user.user_slug, {
                slug: p.user.user_slug,
                screenName: p.user.user_screen_name,
                caption: match(p.user.relationship)
                  .with("my_friend", () => (
                    <>
                      <FontAwesomeIcon
                        icon={faUserGroupSimple}
                        className="mx-1 text-candid-red-300"
                      />{" "}
                      Friend
                    </>
                  ))
                  .with("match", () => (
                    <>
                      <FontAwesomeIcon icon={faHeart} className="mx-1 text-candid-red-300" /> Match
                    </>
                  ))
                  .with("friends_friend", () => (
                    <>
                      <FontAwesomeIcon
                        icon={faCirclesOverlap}
                        className="mx-1 text-candid-red-300"
                      />{" "}
                      Mutual Friend
                    </>
                  ))
                  .otherwise(() => null),
                subCaption: null,
                image_id: null,
                avatarOverride: null,
              });
            }
          }
        )
        .otherwise(() => {});
    });

    return Array.from(uniqueProfilesMap.values());
  }, [friends, extendedFriendListData, searchTerm, isMyPage]);
  const mkActions = useCallback(
    ({ slug }: ProfileListItem) =>
      filterNulls([
        // only show message button for friends
        slug && isMyPage && friends.some((friend) => friend.slug === slug)
          ? {
              component: (
                <AddThreadButton slug={slug}>
                  <div className="btn btn-sm btn-outline">Message</div>
                </AddThreadButton>
              ),
            }
          : null,
        !isMyPage && slug
          ? {
              component: (
                <MultipleTypeRelationshipControlButton
                  slug={slug}
                  rel_types={[Relationship_Types_Enum.Like, Relationship_Types_Enum.Friend]}
                />
              ),
            }
          : null,
      ]),
    [isMyPage, friends]
  );

  if (!my_id) {
    slug && navigate(allRoutes.PROFILE.buildPath({ slug }));
    return <></>;
  }

  if (friends.length === 0 && !searchTerm) {
    return (
      <div className="flex flex-col h-full w-full overflow-hidden">
        <div className="flex mb-2 px-2">
          <SearchBar
            onSearch={(term: string) => setSearchTerm(term)}
            placeholder="Search connections..."
            value={searchTerm}
          />
        </div>
        {friendType === "active" ? (
          <ZeroScreenActiveFriendsList />
        ) : (
          <ZeroScreenMutualFriendsList slug={slug} />
        )}
      </div>
    );
  }

  const profilesToDisplay = isPending() ? cachedProfiles : filteredProfiles;

  if (searchTerm && profilesToDisplay.length === 0) {
    return (
      <div className="flex flex-col h-full w-full overflow-hidden px-2">
        <div className="flex mb-2">
          <SearchBar
            onSearch={(term: string) => setSearchTerm(term)}
            placeholder="Search connections..."
            value={searchTerm}
          />
        </div>
        <div>No users found</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col mb-2 px-2">
      <SearchBar
        onSearch={(term: string) => {
          setSearchTerm(term);
          setCachedProfiles(profilesToDisplay);
        }}
        placeholder="Search connections..."
        value={searchTerm}
        loading={isPending()}
      />
      {extendedFriendListLoading || (isPending() && profilesToDisplay.length === 0) ? (
        <div>Loading...</div>
      ) : (
        <>
          {searchTerm && profilesToDisplay.length === 0 ? (
            <div>No results found</div>
          ) : (
            <div className="mt-1">
              <ProfileList mkActions={mkActions} profiles={profilesToDisplay} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
