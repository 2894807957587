import { ObPageProps, ObScreen } from "./widgets";

import { HopeToFindPicker } from "../../widgets/pickers/hope-to-find-picker";
import React from "react";
import { classNames } from "shared/dist/util";

export function ObHopeToFind({ setDisableButton }: ObPageProps) {
  React.useEffect(() => setDisableButton(false), []);
  return (
    <ObScreen title="Tell us what your looking for">
      <div className={classNames("flex flex-col gap-3 max-h-full w-full h-80 items-stretch")}>
        <HopeToFindPicker size="full" className="bg-primary-content !px-0" isForBio={true} />
      </div>
    </ObScreen>
  );
}
