import QRCode from "react-qr-code";
// import React from "react";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../util/routes";
import { classNames } from "shared/dist/util";
import { colors } from "../../colors.json";
import { useEnv } from "shared/dist/util/env";
import { useMySlug } from "../../screens/bio/bio-settings";

export function QrCodeToShare(): React.JSX.Element {
  const { slug, loading } = useMySlug() ?? {};
  const baseUri = useEnv("REACT_APP_BASE_URI").replace(/\/$/, "");
  const url = baseUri + allRoutes.PROFILE.buildPath({ slug: slug ?? "" }) + "?source=qr";
  // const divRef = React.useRef<any>(null);
  return (
    <div
      className={classNames(
        "p-4 flex rounded-lg items-center justify-center bg-primary-content",
        "h-[256px] w-[256px]",
        "opacity-100 bg-opacity-100 z-55-ui-modal"
      )}
    >
      {loading ? (
        <Spinner />
      ) : !slug ? (
        <span>error</span>
      ) : (
        <QRCode value={url} size={256} bgColor="white" fgColor={colors["candid-purple"][700]} />
      )}
    </div>
  );
}
