import {
  L_Bio_Field,
  useUpdateScreenNameMutation,
  useUserSummaryLazyQuery,
} from "shared/dist/__generated__/components";
import { ValidatedInput, ValidatedInputProps } from "../inputs/validated-input";

// import { DevTool } from "@hookform/devtools";
import React from "react";
import mixpanel from "mixpanel-browser";
import { useMustBeLoggedIn } from "../../util/routes";
import { z } from "zod";

export type ScreenNameEditorProps = {
  dontLoadDbValue?: boolean;
  showBadgeOnEmpty?: boolean;
  className?: string;
  placeholder?: string;
  onSave?: (value: string) => Promise<unknown>;
} & Pick<
  ValidatedInputProps,
  | "defaultValueTreatAsValid"
  | "hideReset"
  | "hideSubmitInstructions"
  | "labelOverride"
  | "onChange"
  | "onLoading"
  | "onValid"
  | "saveComponentOverride"
  | "suppressToast"
  | "validationField"
>;

export function ScreenNameEditor({
  className,
  defaultValueTreatAsValid,
  dontLoadDbValue,
  onSave,
  placeholder,
  showBadgeOnEmpty,
  ...validatedInputProps
}: ScreenNameEditorProps): React.JSX.Element {
  const user_id = useMustBeLoggedIn();
  const [setScreenNameMutation] = useUpdateScreenNameMutation();
  const [loadUserData, { data, loading }] = useUserSummaryLazyQuery({});
  React.useEffect(() => {
    if (dontLoadDbValue || !user_id) return;
    loadUserData({ variables: { id: user_id ?? "" } });
  }, []);

  const submitHandler = React.useCallback(
    async (value: string) => {
      if (!user_id) return;

      const result = (
        await setScreenNameMutation({
          variables: {
            id: user_id,
            screen_name: value,
          },
        })
      )?.data?.update_users_by_pk?.screen_name;
      if (!result) return "Unable to save email";
      mixpanel.track("set_screen name");
      await onSave?.(value);
    },
    [setScreenNameMutation]
  );
  return (
    <ValidatedInput
      {...{
        inputOpts: {
          className: className && className,
        },
        autoSave: true,
        defaultValue: data?.user_summaries?.[0]?.screen_name,
        defaultValueLoading: loading,
        defaultValueTreatAsValid,
        submitHandler,
        validationField: L_Bio_Field.ScreenName,
        placeholder,
        validationMinLength: 3,
        fieldSchema: z
          .string()
          .min(3, { message: "Screen name must be at least 3 characters." })
          .max(32, { message: "Screen name must be less than 32 characters." })
          .regex(/^[a-z0-9][-A-Za-z0-9_ ]*[a-z0-9]$/i, {
            message: "Please use only letters, numbers, spaces, or dashes.",
          }),
        ...validatedInputProps,
      }}
    />
  );
}
