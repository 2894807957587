import {
  Relationship_Statuses_Enum,
  Relationship_Types_Enum,
} from "shared/dist/__generated__/components";

import { SpinnerButton } from "./spinner-button";
import { TestAddRelationshipButtonUi } from "./add-relationship-button";
import { classNames } from "shared/dist/util";
import { useIsDev } from "shared/dist/util/env";

function BasicsButton({ children }: React.PropsWithChildren<{}>) {
  return (
    <span
      className={classNames(
        "border p-1 border-candid-purple-700 text-2xl lg:text-4xl",
        "h-9 w-9 lg:h-14 lg:w-14 inline-flex justify-center items-center"
      )}
    >
      {children}
    </span>
  );
}

export function UiDev(): React.JSX.Element {
  const isDev = useIsDev();
  if (!isDev) {
    return <></>;
  }
  return (
    <div className="text-2xl space-y-4 tooltip p-4">
      {[Relationship_Statuses_Enum.Accepted, Relationship_Statuses_Enum.Requested, undefined].map(
        (status, idx) => (
          <div key={idx} className="space-x-4">
            <BasicsButton>
              <TestAddRelationshipButtonUi
                loading={true}
                status={status}
                type={Relationship_Types_Enum.Friend}
                i_initiated={false}
              />
            </BasicsButton>
            <BasicsButton>
              <TestAddRelationshipButtonUi
                loading={false}
                status={status}
                type={Relationship_Types_Enum.Friend}
                i_initiated={false}
              />
            </BasicsButton>
            <BasicsButton>
              <TestAddRelationshipButtonUi
                loading={false}
                disabled
                status={status}
                type={Relationship_Types_Enum.Friend}
                i_initiated={false}
              />
            </BasicsButton>{" "}
            {status}
          </div>
        )
      )}
    </div>
  );
}
