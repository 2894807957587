import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";

import { Container } from "../../widgets/container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { allRoutes } from "../../util/routes";
import logoPurple from "../../assets/neon-logos/candid-neon-purple.png";

export function LandingFooter(): React.JSX.Element {
  return (
    <div className="p-4 bg-candid-gray-500 flex justify-center w-full">
      <Container size="md">
        <div className="hero-content flex flex-col md:flex-row md:items-center items-start">
          <div className="md:flex-shrink-0 md:px-0 px-[5vw]">
            <div className="relative sm:max-w-[10vw] max-w-[20vw]">
              <img src={logoPurple} alt="Logo" />
            </div>
            <div className="flex-col md:flex-col flex">
              <div className="flex-0">
                <span className="text-secondary my-0">
                  © Principled Software Inc. DBA Candid 2023. All Rights Reserved
                </span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-3/5 md:mt-0 flex justify-between items-center md:px-0 px-[5vw]">
            <div className="flex w-[60vw] md:w-[100%] md:space-x-4 md:justify-around justify-between ">
              <a className="underline" href="mailto:info@trustcandid.com">
                Contact Us
              </a>
              <Link className="underline" to={allRoutes.terms.buildPath({})}>
                Terms
              </Link>
              <Link className="underline" to={allRoutes.privacy.buildPath({})}>
                Privacy
              </Link>
            </div>
            <div className="flex space-x-4 text-xl md:mt-0">
              <a href="https://www.instagram.com/trust_candid/">
                <FontAwesomeIcon className="text-candid-purple-500" icon={faInstagram} />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
