import { ImageUploader, ImageUploaderProps } from "../../widgets/images";
import { ObPageProps, ObScreen } from "./widgets";
import {
  OnboardingImages412Query,
  useOnboardingImages412Query,
} from "shared/dist/__generated__/components";
import React, { useEffect, useMemo, useState } from "react";
import { classNames, sortBy } from "shared/dist/util";
import { faPlus, faXmark } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UnLazyImage } from "@unlazy/react";
import clsx from "clsx";
import { useIsDev } from "shared/dist/util/env";
import { useMkImgUrl } from "../../util/routes";
import { useMyId } from "shared/dist/auth-data";
import { useOnScreenLogger } from "shared-web-react/dist/util/on-screen-logger";
import { useProfilePageEditFunctions } from "../user-page/edit-functions";

export function ObMedia({
  setDisableButton,
  isReviewScreen = false,
}: {
  setDisableButton: (b: boolean) => void;
  isReviewScreen?: boolean;
}): React.JSX.Element {
  const user_id = useMyId();
  const { log } = useOnScreenLogger();
  const { data, refetch } = useOnboardingImages412Query({
    variables: { user_id },
  });
  const isDev = useIsDev();
  React.useEffect(() => {
    const { media_uploads } = data ?? {};
    const minImages = isDev ? 1 : 3;
    if (isDev) log({ id: "obImages", content: "Allowing 1 image for dev" });
    setDisableButton(!media_uploads || media_uploads?.length < minImages);
  }, [data]);
  const my_id = useMyId();
  const sortedImages = React.useMemo(
    () => sortBy(data?.media_uploads ?? [], (e) => new Date(e.created_at).getTime()),
    [data?.media_uploads]
  );
  const editFunctions = useProfilePageEditFunctions(my_id, true, refetch);

  if (isReviewScreen)
    return (
      <div className={classNames("flex flex-col gap-3 items-stretch")}>
        <PendingApprovalImages
          images={sortedImages}
          deleteImage={editFunctions?.archiveImage ?? undefined}
          onComplete={() => {
            refetch();
          }}
          onError={() => {
            console.log("🚀 ~ file: thumbnails.tsx ~ line 101 ~ onError");
          }}
        />
      </div>
    );

  return (
    <ObScreen
      title={`Lastly, let's add your pictures`}
      subtitle="Start with at least three pictures. You can always add more later."
    >
      <div className={classNames("flex flex-col gap-3 h-full w-full items-stretch max-h-60")}>
        <ObImages
          images={sortedImages}
          deleteImage={editFunctions?.archiveImage ?? undefined}
          onComplete={() => {
            refetch();
          }}
          onError={() => {
            console.log("🚀 ~ file: thumbnails.tsx ~ line 101 ~ onError");
          }}
        />
      </div>
    </ObScreen>
  );
}

export function ObImages({
  images,
  deleteImage,
  ...imageUploaderProps
}: {
  deleteImage?: (s: string) => Promise<unknown>;
  images: OnboardingImages412Query["media_uploads"];
} & Pick<ImageUploaderProps, "onComplete" | "onStart" | "onError">): React.JSX.Element {
  const mkImgUrl = useMkImgUrl();
  const [nextImageIdx, setNextImageIdx] = React.useState(0);
  return (
    <>
      <div className="grid gap-4 grid-cols-3 grid-rows-2 lg:grid-cols-3 lg:grid-rows-2">
        {[...Array(Math.max(6, images.length))].map((_, idx) => (
          <div className="h-48" key={idx}>
            {images[idx]?.id ? (
              <div className="h-full relative items-center justify-center flex rounded-2xl border-4 p-2 border-candid-gray-500">
                <UnLazyImage
                  className="max-w-full max-h-full w-full h-full object-contain"
                  thumbhash={images[idx].metadata_public?.thumb_hash ?? undefined}
                  src={mkImgUrl(images[idx].id, "256")}
                />
                <div className="absolute -right-4 -top-4 z-30-main-ui-overlay-2">
                  <button
                    onClick={() => deleteImage?.(images[idx].id!)}
                    className="btn bg-gray-700 btn-sm btn-circle text-white border-white border-2"
                  >
                    <FontAwesomeIcon icon={faXmark} size="lg" />
                  </button>
                </div>
              </div>
            ) : (
              <div className="relative h-full items-center justify-center flex rounded-2xl bg-candid-gray-500">
                <div className="relative flex items-center justify-center">
                  {idx === images.length ? (
                    <ImageUploader
                      iconOverride={faPlus}
                      disallowMultiple
                      {...imageUploaderProps}
                      onStart={() => setNextImageIdx(idx + 1)}
                      btnClassName={classNames(
                        "btn-circle btn-primary text-3xl flex-1",
                        "border-none absolute flex justify-center items-center"
                      )}
                      btnContent={""}
                      // iconClassName={classNames("aspect-square")}
                    />
                  ) : (
                    <button
                      className={classNames(
                        "btn-circle btn-primary text-3xl flex-1",
                        "border-none absolute flex justify-center items-center bg-candid-gray-700"
                      )}
                      disabled
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export function PendingApprovalImages({
  images,
  deleteImage,
  ...imageUploaderProps
}: {
  deleteImage?: (s: string) => Promise<unknown>;
  images: OnboardingImages412Query["media_uploads"];
} & Pick<ImageUploaderProps, "onComplete" | "onStart" | "onError">): React.JSX.Element {
  const mkImgUrl = useMkImgUrl();
  const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);
  let imagesLength = images.length;
  let shouldDisplaySmall = screenHeight <= 667;
  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [images.length]);

  return (
    <div
      className={clsx(
        "grid gap-4 w-full h-full",
        shouldDisplaySmall
          ? `grid-cols-${Math.min(imagesLength + 1, 6)}`
          : "grid-cols-3 grid-rows-2"
      )}
    >
      {Array.from({
        length: imagesLength < 6 ? imagesLength + 1 : Math.max(6, imagesLength),
      }).map((_, idx) => (
        <div
          className={clsx(
            "relative flex items-center justify-center h-auto w-full",
            shouldDisplaySmall ? "h-48" : `h-auto`
          )}
          key={idx}
        >
          {images[idx]?.id ? (
            <div className="relative items-center justify-center flex h-full w-full">
              <UnLazyImage
                className="max-w-full max-h-32 w-full h-full object-cover border-4 border-candid-gray-500 rounded-2xl"
                thumbhash={images[idx].metadata_public?.thumb_hash ?? undefined}
                src={mkImgUrl(images[idx].id, "256")}
              />
              <div className="absolute -right-4 -top-4 z-30-main-ui-overlay-2">
                <button
                  onClick={() => {
                    deleteImage?.(images[idx].id!);
                  }}
                  className={`btn bg-gray-700 btn-${
                    shouldDisplaySmall ? "xs" : "sm"
                  } btn-circle text-white border-white border-21`}
                >
                  <FontAwesomeIcon icon={faXmark} size={shouldDisplaySmall ? "xs" : "lg"} />
                </button>
              </div>
            </div>
          ) : (
            <div className="relative h-full w-full items-center justify-center flex rounded-2xl">
              <div className="relative flex items-center justify-center w-full h-full">
                {idx === images.length ? (
                  <ImageUploader
                    iconOverride={faPlus}
                    disallowMultiple
                    btnClassName={clsx(
                      "btn-circle btn-primary",
                      shouldDisplaySmall ? "btn-sm text-lg" : "btn-lg text-3xl",
                      "flex-1 border-none absolute flex justify-center items-center"
                    )}
                    btnContent=""
                    {...imageUploaderProps}
                  />
                ) : (
                  <button
                    className={classNames(
                      "btn-circle btn-primary text-3xl flex-1",
                      "border-none absolute flex justify-center items-center bg-candid-gray-700"
                    )}
                    disabled
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
