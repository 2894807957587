import { Maybe, classNames, stringify } from "shared/dist/util";
import {
  Media_Item_With_Caption_451Fragment,
  useBioV2FromSlugMedia451Query,
} from "shared/dist/__generated__/components";
import { faFire, faThumbtack } from "@fortawesome/pro-solid-svg-icons";

import { Container } from "../../widgets/container";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorSpinnerTimeout } from "shared-web-react/dist/widgets/errors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyMediaItem } from "../../widgets/lazy-media-item";
import { MediaControls } from "../../widgets/bio/media-controls";
import { ProfilePageEditFunctions } from "../../widgets/bio/bio-types";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../util/routes";
import clsx from "clsx";
import { match } from "ts-pattern";
import { useMyId } from "shared/dist/auth-data";
import { useNavigate } from "react-router-dom";
import { useProfilePageEditFunctions } from "./edit-functions";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { userPageContainerWidth } from "./common";

export type UserGalleryProps = {};

type ZoomedImageState = null | [string, string | null | undefined];
type GalleryImageData = Media_Item_With_Caption_451Fragment & { pinned_at?: Maybe<string> };

function GalleryImage({
  controlShowing,
  setControlShowing,
  editFunctions,
  setLoading,
  img,
  nextImg,
  prevImg,
  draggable,
  slug,
  pinnedImages,
}: {
  editFunctions: ProfilePageEditFunctions | undefined | null;
  img: GalleryImageData;
  nextImg: undefined | GalleryImageData;
  prevImg: undefined | GalleryImageData;
  setControlShowing: (_: boolean) => void;
  setLoading: (_: boolean) => void;
  controlShowing: boolean;
  setZoomedImage: (_: ZoomedImageState) => void;
  draggable?: boolean;
  slug: string;
  pinnedImages?: GalleryImageData[];
}): React.JSX.Element {
  // const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
  //   id: `gallery_draggable_img_${img?.id}`,
  //   data: { img, type: "img" },
  // });
  // const { setNodeRef: droppableRef, isOver } = useDroppable({
  //   data: {
  //     accepts: ["img"],
  //     img,
  //   },
  //   id: `gallery_droppable_img_${img?.id}`,
  // });

  // const style =
  //   editFunctions && transform
  //     ? {
  //         transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  //       }
  //     : undefined;
  const my_id = useMyId();

  const [blur, setBlur] = React.useState(img?.is_nsfw);
  // const className = `${isDragging ? "z-100-ui-draggable" : "z-unset"} ${
  //   isOver ? "opacity-75" : ""
  // }`;
  const navigate = useNavigate();
  React.useEffect(() => {
    setBlur(img?.is_nsfw);
  }, [img?.is_nsfw]);
  React.useEffect(() => {
    console.log("🚀 - file: gallery.tsx:85 - img:", stringify(img));
  }, [img]);
  if (!img?.id) return <ErrorSpinnerTimeout />;
  return (
    <div
      // ref={draggable && editFunctions ? setNodeRef : null}
      className={classNames("GalleryImage", draggable && editFunctions && "touch-none")}
    >
      <div
        // ref={droppableRef}
        onClick={() => {
          if (controlShowing) {
            return;
          }
          if (my_id && img?.id) {
            navigate(
              allRoutes.PROFILE.MEDIA_ITEM.buildPath({
                slug,
                media_upload_id: img?.id,
              }),
              {
                preventScrollReset: true,
              }
            );
          }
        }}
        className={classNames(
          `GalleryImageInner aspect-square relative bg-slate-500 bg-cover bg-no-repeat bg-center shadow-md `
          // className
        )}
        // style={{ ...style }}
        // style={{ backgroundImage: `url(${img?.presigned_url})`, ...style }}
        // {...(draggable && editFunctions ? listeners : {})}
        // {...(draggable && editFunctions ? attributes : {})}
      >
        <div className={clsx("absolute inset-0 overflow-hidden")}>
          <LazyMediaItem
            className="object-cover w-full h-full"
            thumbHash={img?.metadata?.thumb_hash ?? undefined}
            size="256"
            mediaUploadId={img?.id}
          />
        </div>
        {blur && (
          <div
            className={classNames(
              "flex max-h-full max-w-full lg:py-4 text-center text-primary-content",
              "text-lg font-semibold flex-col h-full w-full gap-2 justify-center",
              "items-center relative"
            )}
            style={{ backdropFilter: "blur(20px)" }}
          >
            <div
              onClick={(e) => {
                setBlur(false);
                e.stopPropagation();
              }}
              className="bg-primary w-full py-3 max-lg:h-full flex flex-col justify-center items-center max-lg:text-sm bg-opacity-50 px-4"
            >
              <FontAwesomeIcon icon={faFire} fixedWidth size="2x" />
              <p>NSFW</p>
              <p>Click to view</p>
            </div>
          </div>
        )}
        {img?.id && editFunctions && (
          <MediaControls
            editFunctions={editFunctions}
            img={img}
            onToggleModal={setControlShowing}
            prevImage={prevImg}
            nextImage={nextImg}
            setLoading={setLoading}
            pinnedImages={pinnedImages ?? []}
          />
        )}
        {img?.pinned_at && (
          <div
            className={classNames(
              "opacity-80 absolute right-3 top-3 flex justify-center items-center rotate-45"
            )}
          >
            <FontAwesomeIcon
              icon={faThumbtack}
              fixedWidth
              className="text-base-100 stroke-black stroke stroke-2"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export function UserGallery(): React.JSX.Element {
  return <UserGalleryV2 />;
}

function EmptyGallery(): React.JSX.Element {
  return <div className={clsx("EmptyGallery")}></div>;
}

export function UserGalleryV2(): React.JSX.Element {
  // Sort so that the highest seq numbers go first
  const my_id = useMyId();
  const bottomRef = React.createRef<HTMLDivElement>();

  const slug = useTypedParams(allRoutes.PROFILE).slug!;
  const [controlShowing, setControlShowing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const {
    data,
    loading: mediaQueryLoading,
    fetchMore,
    refetch,
  } = useBioV2FromSlugMedia451Query({
    skip: !slug,
    fetchPolicy: "cache-and-network",
    variables: { slug, page: 0 },
  });
  const [isAtBottom, setIsAtBottom] = React.useState(false);
  React.useEffect(
    function setInteractionObserver() {
      // console.log("🚀 - file: gallery.tsx:210 -  interaction observer for profiles");
      try {
        if (!bottomRef?.current) return;
        const observer = new IntersectionObserver(([entry]) => {
          // console.log(
          //   "🚀 - file: gallery.tsx:210 - observer - entry:",
          //   entry.isIntersecting,
          //   entry
          // );
          setIsAtBottom(entry.isIntersecting);
        });
        observer?.observe?.(bottomRef.current);
        return () => observer?.disconnect?.();
      } catch (error) {
        console.error("🚀 - file: gallery.tsx:218 - React.useEffect - error:", error);
      }
    },
    [bottomRef, setIsAtBottom]
  );
  React.useEffect(
    function fetchMoreImagesEffect() {
      try {
        console.log(
          "🚀 - file: gallery.tsx:236 - fetchMoreImagesEffect - isAtBottom:",
          isAtBottom,
          mediaQueryLoading,
          data?.l_bio_v2?.__typename
        );
        if (!isAtBottom || mediaQueryLoading) return;
        const nextPage = match(data?.l_bio_v2)
          .with({ __typename: "L_Bio_V2_Success" }, (data) => data.media.next_page_idx)
          .otherwise(() => null);
        if (nextPage) fetchMore({ variables: { page: nextPage, slug } });
      } catch (error) {
        console.error("🚀 - file: gallery.tsx:244 - fetchMoreImagesEffect - error:", error);
      }
    },
    [isAtBottom, mediaQueryLoading, fetchMore, data]
  );
  const editable = match(data?.l_bio_v2)
    .with({ __typename: "L_Bio_V2_Success" }, (data) => data.editable)
    .otherwise(() => false);
  const editFunctions = useProfilePageEditFunctions(my_id, editable, async () => {
    refetch?.();
  });

  // console.log(
  //   "🚀 - file: gallery.tsx:255 - UserGalleryV2 - data:",
  //   stringify(data?.l_bio_v2)?.slice(0, 100)
  // );
  if (data?.l_bio_v2?.__typename !== "L_Bio_V2_Success")
    console.log("🚀 - file: gallery.tsx:271 - data type: ", stringify(data));
  if (data?.l_bio_v2?.__typename !== "L_Bio_V2_Success") return <></>;
  const images = data?.l_bio_v2?.media.media_items ?? [];
  const pinned_images = data?.l_bio_v2?.media.pinned_items?.filter((img) => img != null) ?? [];
  // if (!slug) console.error("gallery.tsx:275 Missing slug");

  // React.useEffect(() => {
  //   try {
  //     console.log(
  //       "🚀 - file: gallery.tsx:254 - UserGalleryV2 - images:",
  //       stringify(images, { pp: true })
  //     );
  //   } catch (error) {
  //     console.error("🚀 - file: gallery.tsx:254 - UserGalleryV2 - error:", error);
  //   }
  // }, [images]);

  // console.log("🚀 - file: gallery.tsx:263 - UserGalleryV2 - images.length:", images?.length);
  const imgsFiltered = [...images]
    .sort((fst, snd) => (fst.created_at > snd.created_at ? -1 : 1))
    .filter((i) => i.id);

  const anyLoading = loading || mediaQueryLoading;
  if (!images) {
    if (anyLoading) return <SpinnerCentered />;
    return <EmptyGallery />;
  }
  if (!slug) {
    console.error("🚀 - file: gallery.tsx:272 - null slug which should be impossible:", slug);
    return <></>;
  }
  return (
    <div>
      <Container className="UserGalleryV2 my-4 " size={userPageContainerWidth}>
        <div className="grid grid-cols-3 gap-2 md:gap-3 lg:gap-4 ">
          {[...pinned_images, ...imgsFiltered].map((img, idx) => (
            <ErrorBoundary
              key={idx}
              FallbackComponent={ErrorSpinnerTimeout}
              onError={(error) =>
                console.error("🚀 - file: gallery.tsx:305 - UserGallery error:", stringify(error))
              }
            >
              <GalleryImage
                key={img.id ?? idx}
                prevImg={idx > 0 ? imgsFiltered[idx - 1] : undefined}
                nextImg={idx < imgsFiltered.length - 1 ? imgsFiltered[idx + 1] : undefined}
                editFunctions={editFunctions}
                img={img}
                {...{
                  setZoomedImage: () => {
                    console.warn("🚀 - file: gallery.tsx:291 - setZoomedImage unimplemented");
                  },
                  setControlShowing,
                  setLoading,
                  controlShowing,
                }}
                slug={slug}
                pinnedImages={pinned_images}
              />
            </ErrorBoundary>
          ))}
          {anyLoading && (
            <div className="py-2 col-span-3">
              <SpinnerCentered />
            </div>
          )}
          <div className={clsx("h-1 mx-auto col-span-3 w-1 ")} ref={bottomRef}></div>
        </div>
      </Container>
    </div>
  );
}
