import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import React from "react";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { UserPageContainer } from "./common";
import { allRoutes } from "../../util/routes";
import { useMyEventsQuery } from "shared/dist/__generated__/components";

export function UserEvents(): React.JSX.Element {
  const { data, loading, error } = useMyEventsQuery();
  const [showPastEvents, setShowPastEvents] = React.useState(false);
  const futureEvents = React.useMemo(() => {
    const now = DateTime.now();
    return [
      ...(data?.events.filter(
        (evt) => now < DateTime.fromISO(evt.start).plus({ minutes: evt.duration })
      ) ?? []),
    ].sort((e1, e2) => (e1.start < e2.start ? -1 : 1));
  }, [data]);
  const pastEvents = React.useMemo(() => {
    const now = DateTime.now();
    return [
      ...(data?.events.filter(
        (evt) => now > DateTime.fromISO(evt.start).plus({ minutes: evt.duration })
      ) ?? []),
    ].sort((e1, e2) => (e1.start < e2.start ? 1 : -1));
  }, [data]);
  return (
    <UserPageContainer>
      {loading ? (
        <div className="flex justify-center items-center py-10">
          <Spinner />
        </div>
      ) : (
        <div>
          <div>
            <div className="join mb-2">
              <button
                onClick={() => setShowPastEvents(false)}
                className={`join-item btn ${!showPastEvents ? "btn-active" : ""}`}
              >
                Upcoming Events
              </button>
              <button
                onClick={() => setShowPastEvents(true)}
                className={`join-item btn ${showPastEvents ? "btn-active" : ""}`}
              >
                Past Events
              </button>
            </div>
          </div>
          <div className="prose">
            {(showPastEvents ? pastEvents : futureEvents).map((event, idx) => (
              <div key={idx}>
                <h1>
                  <Link
                    to={allRoutes.EVENTS.DETAILS.buildPath({
                      event_id: event.id,
                    })}
                  >
                    {event.name}
                  </Link>
                </h1>
                <p>
                  Date:{"  "}
                  {DateTime.fromISO(event.start).toLocaleString(DateTime.DATETIME_MED)}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </UserPageContainer>
  );
}
