import {
  IconDefinition,
  faCalendar,
  faHeart,
  faHome,
  faMessages,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import {
  NavStackViewHeader,
  usePageTitle,
  useSetPageTitleFxn,
  useShouldSuppressNavStackHeaderState,
} from "shared-web-react/dist/widgets/nav-stack-view";
import React, { useContext } from "react";
import {
  faCalendar as faCalendarHeavy,
  faHeart as faHeartHeavy,
  faHome as faHomeHeavy,
  faMessages as faMessagesHeavy,
  faUser as faUserHeavy,
} from "@fortawesome/pro-solid-svg-icons";

import { AddContentMenu } from "../../widgets/bio/nav-dropdowns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IndicatorNavLink } from "../../widgets/indicator-nav-link";
import { SignUpTrigger } from "./signup-triggers";
import { UserNavbar } from "./top-navbar";
import { UserPageContainer } from "./common";
import { allRoutes } from "../../util/routes";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useMyId } from "shared/dist/auth-data";
import { useMyInfo } from "../bio/bio-settings";
import { useSwipeable } from "react-swipeable";

const LeftMarginContext = React.createContext(0);
export function useLeftMargin() {
  return useContext(LeftMarginContext) + "px";
}

export type UserPageNavWrapperProps = React.PropsWithChildren<{
  hideMenuLabelsInDesktop?: boolean;
  newContentBoxUi?: boolean;
  contentClassName?: React.HTMLProps<HTMLDivElement>["className"];
}>;

export function UserPageNavWrapper({
  children,
  hideMenuLabelsInDesktop,
  newContentBoxUi,
  contentClassName,
}: UserPageNavWrapperProps): React.JSX.Element {
  const my_id = useMyId();
  const { slug } = useMyInfo();
  const [bottom, setBottom] = React.useState(0);
  const suppressNavStack = useShouldSuppressNavStackHeaderState({ slug });
  const pageTitle = usePageTitle();
  const [top, setTop] = React.useState(0);
  const [left, setLeft] = React.useState(0);
  const topNavRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    // console.log(
    //   "🚀 ~ file: main-nav:126 ~ React.useEffect ~     onHeight?.(ref?.current?.clientHeight ?? 0);:",
    //   topNavRef?.current?.clientHeight
    // );
    setTop(topNavRef?.current?.clientHeight ?? 0);
  }, [topNavRef, setTop, suppressNavStack]);

  React.useEffect(() => {
    const onResize = () => {
      console.log("🚀 - file: main-nav.tsx:132 - onResize - onResize:");
      topNavRef.current && setTop(topNavRef?.current?.clientHeight ?? 0);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [topNavRef, setTop]);
  const setTitle = useSetPageTitleFxn();
  /* cSpell:disable */
  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] <= 50 && eventData.dir === "Right" && eventData.deltaX > 100) {
        console.log(
          "🚀 - file: main-nav.tsx:81 - eventData:",
          eventData.initial,
          eventData.deltaX,
          eventData.event?.type,
          (eventData.event as any).touches
        );
        setTitle(null);
        window.history.back();
      }
    },
  });
  /* cSpell:enable */

  return (
    <LeftMarginContext.Provider value={left}>
      <div>
        <div className="top-dangerous bg-white z-50" />
        <div
          className={classNames(
            "fixed overflow-auto top-0 bottom-0 right-0 left-0",
            newContentBoxUi && "bg-base-200"
          )}
        >
          <div
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              paddingLeft: left + "px",
              paddingRight: 0, // hideMenuLabelsInDesktop ? 0 : left + "px",
              paddingTop: `${top}px`,
              paddingBottom: `calc(${bottom}px + env(safe-area-inset-bottom))`,
            }}
            className={classNames(
              "UserPageNavWrapper-content-area-full-screen w-full overflow-y-auto",
              "align-middle justify-center min-h-screen items-stretch flex ",
              "flex-row flex-nowrap overflow-x-hidden relative",
              newContentBoxUi && "bg-base-200"
            )}
          >
            <div
              className={classNames(
                "UserPageNavWrapper-content-area-inner-box flex-1 relative left-0 right-0",
                "min-h-full",
                newContentBoxUi ? "bg-base-200" : "bg-white",
                contentClassName
              )}
              {...(suppressNavStack ? handlers : {})}
            >
              {children}
            </div>
          </div>
          {my_id ? (
            <BottomOrSideNav {...{ setBottom, setLeft, hideMenuLabelsInDesktop }} />
          ) : (
            <SignUpTrigger />
          )}
        </div>
      </div>
      <div ref={topNavRef} className={clsx("fixed  pt-safe top-0 right-0 left-0 z-40-nav-main")}>
        {suppressNavStack ? (
          <NavStackViewHeader topSize="12">{pageTitle ?? ""}</NavStackViewHeader>
        ) : (
          <UserNavbar />
        )}
      </div>
    </LeftMarginContext.Provider>
  );
}

type BottomOrSideNavProps = {
  setBottom: (n: number) => void;
  setLeft: (n: number) => void;
  hideMenuLabelsInDesktop?: boolean;
};

function NavIcon({ icon }: { icon: IconDefinition }) {
  return <FontAwesomeIcon className="fa-solid" icon={icon as any} fixedWidth size="2x" />;
}

function BottomOrSideNav({
  setLeft,
  setBottom,
  hideMenuLabelsInDesktop,
}: BottomOrSideNavProps): React.JSX.Element {
  // const bottomRef = React.useRef<HTMLDivElement>(null);
  const leftRef = React.useRef<HTMLDivElement>(null);
  const location = useLocation();
  const my_id = useMyId();
  React.useEffect(() => {
    setLeft(leftRef?.current?.clientWidth ?? 0);
  }, [location, leftRef]);

  React.useEffect(() => {
    const onResize = () => {
      console.log("🚀 - file: main-nav.tsx:216 - onResize - onResize:");
      leftRef.current && setLeft(leftRef?.current?.clientHeight ?? 0);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [leftRef, setLeft]);

  const NavItems: Array<
    [React.ReactNode, React.ReactNode, string, string, ((s: string) => boolean)?]
  > = React.useMemo(
    () => [
      [
        <NavIcon icon={faHome} />,
        <NavIcon icon={faHomeHeavy} />,
        "Home",
        allRoutes.HOME.buildPath({}),
      ],
      [
        <NavIcon icon={faHeart} />,
        <NavIcon icon={faHeartHeavy} />,
        "Discovery",
        allRoutes.DISCOVERY.buildPath({}),
      ],
      [
        <NavIcon icon={faMessages} />,
        <NavIcon icon={faMessagesHeavy} />,
        "Messages",
        allRoutes.MESSAGING.buildPath({}),
        (s) => s.startsWith("/m/") || s === "/m",
      ],
      // [
      //   <NavIcon icon={faCalendar} />,
      //   <NavIcon icon={faCalendarHeavy} />,
      //   "Events",
      //   allRoutes.EVENTS.LIST.buildPath({}),
      //   (s) => s.startsWith(allRoutes.EVENTS.LIST.buildPath({})),
      // ],
      [
        <NavIcon icon={faUser} />,
        <NavIcon icon={faUserHeavy} />,
        "My Page",
        allRoutes.me.buildPath({}),
        (s) => s.startsWith("/@"),
      ],
    ],
    [] // unreadThreadData]
  );
  if (!my_id) {
    return <></>;
  }

  return (
    <>
      <div
        ref={leftRef}
        className={classNames(
          "UserPageMainNav",
          " bg-white z-35-nav-sidebar border-r border-r-primary max-lg:hidden lg:pt-16 text-primary fixed bottom-0 left-0 top-0"
        )}
      >
        <div className="flex flex-col space-y-3 justify-start items-start overflow-visible ">
          <ul className="menu font-semibold p-0">
            {NavItems.map(([icon, iconHeavy, label, route, locTest], idx) => (
              <React.Fragment key={idx}>
                <li>
                  <IndicatorNavLink
                    key={idx}
                    baseClassName="w-full"
                    to={route}
                    className={({}) => "indicator w-full gap-3 items-center "}
                    indicatorItemClassName="indicator-middle indicator-start badge badge-sm max-lg:badge-xs"
                  >
                    {locTest?.(location.pathname) ?? location.pathname.includes(route)
                      ? iconHeavy
                      : icon}
                    {!hideMenuLabelsInDesktop && label}
                  </IndicatorNavLink>
                </li>
                {idx === 1 && (
                  <li>
                    <AddContentMenu {...{ hideMenuLabelsInDesktop }} />
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={classNames(
          " bg-white lg:hidden text-primary fixed bottom-0",
          "left-0 right-0 z-45-nav-sidebar pb-safe"
        )}
      >
        <UserPageContainer
          className="flex flex-row lg:flex-col justify-around items-center overflow-visible fixed touch-none "
          onHeight={setBottom}
        >
          {NavItems.map(([icon, iconHeavy, _, route, locTest], idx) => (
            <React.Fragment key={idx}>
              <IndicatorNavLink to={route} className={({}) => "indicator"}>
                {locTest?.(location.pathname) ?? location.pathname.includes(route)
                  ? iconHeavy
                  : icon}
              </IndicatorNavLink>
              {idx === 1 && <AddContentMenu />}
            </React.Fragment>
          ))}
        </UserPageContainer>
      </div>
    </>
  );
}
