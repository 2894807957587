import { ContentBox } from "../../widgets/content-box";
import { L_Vouch_Level } from "shared/dist/__generated__/components";
import { Link } from "react-router-dom";
import { NeonSpinner } from "../../widgets/neon-spinner";
import React from "react";
import { VouchInfoBox } from "../user-page/vouching/vouch-info-box";
import { allRoutes } from "../../util/routes";
import clsx from "clsx";
import { createPortal } from "react-dom";

export function UiTestPages(): React.JSX.Element {
  const pages: Array<[string, string]> = [
    [allRoutes.DEV_UTILS.UI_TEST_PAGES.TEST_BIO_ACTIONS.buildPath({}), "bio actions"],
    [allRoutes.DISCOVERY.TEST_DUMMY_DATA.buildPath({}), "dummy data"],
    [allRoutes.DISCOVERY.TEST_EMPTY.buildPath({}), "empty data"],
    [allRoutes.DISCOVERY.TEST_ERROR.buildPath({}), "error page"],
    [allRoutes.DISCOVERY.TEST_NOT_READY.buildPath({}), "profile not ready yet"],
    [allRoutes.DISCOVERY.TEST_WAITLIST.buildPath({}), "waitlist"],
  ];
  const [showNeonSpinner, setShowNeonSpinner] = React.useState(false);
  return (
    <div className={clsx("p-4")}>
      Test pages:
      <ul className={clsx("list-inside list-disc ")}>
        {pages.map(([to, label]) => (
          <li key={to}>
            <Link className={clsx("link")} to={to}>
              {label}
            </Link>
          </li>
        ))}
      </ul>
      <div className="divider" />
      <button
        onClick={() => setShowNeonSpinner(!showNeonSpinner)}
        className={clsx("btn btn-secondary btn-sm")}
      >
        {showNeonSpinner ? "hide" : "show"} neon spinner
      </button>
      <div className="divider" />
      Sample Vouch Info Boxes:
      <VouchInfoBox screenName="test" level={L_Vouch_Level.TopUser} />
      <VouchInfoBox screenName="test" level={L_Vouch_Level.HighlyRecommended} />
      <VouchInfoBox screenName="test" level={L_Vouch_Level.Recommended} />
      {showNeonSpinner &&
        createPortal(
          <div className="fixed inset-0 z-50" onClick={() => setShowNeonSpinner(false)}>
            <NeonSpinner />
          </div>,
          document.body
        )}
    </div>
  );
}
