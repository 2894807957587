import {
  Face_Privacy_Levels_Enum,
  Feature_Flags_Enum,
  GqlOps,
  ProfilePrivacySettingsQuery,
  Profile_Privacy_Levels_Enum,
  useProfilePrivacySettings340Query,
  useUpdateFacePrivacySettingsMutation,
  useUpdateMyPrivacySettingsMutation,
} from "shared/dist/__generated__/components";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";

import { Container } from "../../../widgets/container";
import { ContentBox } from "../../../widgets/content-box";
import { EnumSelectWithDesc } from "shared-web-react/dist/widgets/enum-select";
import { H3 } from "shared-web-react/dist/widgets/text";
import { SelectWithDetailsOption } from "shared-web-react/dist/widgets/select-details";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useIdentityVerifiedStatus } from "./identity/common";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useMustBeLoggedIn } from "../../../util/routes";

function privacyLevelToSelectOption(
  val: ProfilePrivacySettingsQuery["profile_privacy_levels"][0]
): SelectWithDetailsOption {
  const splitDesc = val.localized_string?.en_US?.split("|");
  return [val.value, splitDesc?.[0] ?? "", splitDesc?.[1] ?? ""];
}

export function ProfilePrivacySettings(): React.JSX.Element {
  const my_id = useMustBeLoggedIn() ?? "";
  const addToast = useAddToast();
  const { verified, loading: verifiedLoading } = useIdentityVerifiedStatus();
  const { data, loading } = useProfilePrivacySettings340Query({
    variables: { my_id },
  });

  const showFacialPrivacy = useIsFeatureEnabled(Feature_Flags_Enum.FaceBlurringOnMediaV2);
  console.log("🚀 - file: profile-privacy-settngs.tsx:49 - ProfilePrivacySettings - data:", data);
  const [updateFaceVisibility, { loading: updateFaceLoading }] =
    useUpdateFacePrivacySettingsMutation();
  const [updateProfileVisibility, { loading: updateLoading }] =
    useUpdateMyPrivacySettingsMutation();
  if (loading) {
    return (
      <Container size="xs" className={classNames("flex flex-col h-full gap-2 pb-4 items-stretch")}>
        <ContentBox className="px-3 pt-2 pb-3">
          <SpinnerCentered />
        </ContentBox>
      </Container>
    );
  }
  return (
    <div className={clsx("space-y-2")}>
      <div className="flex w-full flex-row align-middle space-between">
        <H3 className="">Profile Privacy:</H3>
        <div>{(verifiedLoading || updateLoading || loading) && <Spinner />}</div>
      </div>
      {!verifiedLoading && !verified && (
        <>
          <div className="divider my-0" />
          <span className="italic">Visibility settings only available to verified profiles.</span>
        </>
      )}
      <div className="divider my-0" />
      <div>
        <p>Profile Visibility</p>
        <EnumSelectWithDesc
          possibleValues={Profile_Privacy_Levels_Enum}
          inputClassName="w-full"
          value={data?.user_profile_privacy_settings_by_pk?.profile_privacy_level}
          toDesc={(level) => {
            return splitSep(
              data?.profile_privacy_levels?.filter((v) => v.value === level)?.[0]?.localized_string
                ?.en_US
            );
          }}
          onChange={async (value) => {
            if (!value) return;
            updateProfileVisibility({
              variables: { my_id, level: value },
              refetchQueries: [GqlOps.Query.ProfilePrivacySettings340],
            }).then(() => addToast({ content: "Saved!" }));
          }}
        />
      </div>
      {showFacialPrivacy && (
        <div>
          <p>Facial Privacy and Blurring</p>
          <EnumSelectWithDesc
            possibleValues={Face_Privacy_Levels_Enum}
            value={data?.user_profile_privacy_settings_by_pk?.face_privacy_level}
            toDesc={(level) =>
              splitSep(
                data?.face_privacy_levels?.filter((v) => v.value === level)?.[0]?.localized_string
                  ?.en_US
              )
            }
            onChange={async (value) => {
              if (!value) return;
              updateFaceVisibility({
                variables: { my_id, level: value },
                refetchQueries: [GqlOps.Query.ProfilePrivacySettings340],
              }).then(() => addToast({ content: "Saved!" }));
            }}
          />
        </div>
      )}
    </div>
  );
}

const splitSep = (str: null | undefined | string) => {
  if (!str) return null;
  const arr = str.split("|");
  if (arr.length === 1) return str;
  if (arr.length === 2) return [arr[0], arr[1]] as [string, string];
  return null;
};
