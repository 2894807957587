import { GqlOps, useUpdateBioMutation } from "shared/dist/__generated__/components";
import { useMyId, useMySummary } from "shared/dist/auth-data";

import React from "react";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useDebounce } from "use-debounce";
import { useMustBeLoggedIn } from "../../util/routes";

export function BioEditor({
  onSave,
  defaultValue,
  labelOverride,
}: {
  labelOverride?: string;
  defaultValue?: string;
  onSave: () => void;
}): React.JSX.Element {
  const { data: myData, loading: myDataLoading } = useMySummary();
  const [bio, setBio] = React.useState("");
  const [updateBioMutation] = useUpdateBioMutation();
  const [debouncedBio] = useDebounce(bio, 1000);
  const [initialized, setInitialized] = React.useState(false);
  const id = useMyId()!;
  React.useEffect(() => {
    if (initialized) {
      return;
    }
    if (myData?.bio) {
      console.log("setting bio to stored bio");
      setInitialized(true);
      setBio(myData.bio);
    }
  }, [bio, myData, setBio, initialized, setInitialized]);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const addToast = useAddToast();
  useMustBeLoggedIn();
  const anyLoading = myDataLoading || updateLoading;
  React.useEffect(() => {
    console.log("🚀 ~ file: bio-editor.tsx:44 ~ bio:", bio);
    setUpdateLoading(true);
    // this just doesn't quite work..
    // return () => {
    //   console.log("about to save bio on unload: ", bio);
    //   bio?.length ??
    //     (0 > 3 &&
    //       updateBioMutation({
    //         refetchQueries: ["UserSummary"],
    //         variables: { id, bio },
    //       })
    //         .then(onSave)
    //         .then(() => console.log("saved bio: ", bio)));
    // };
  }, [bio]);
  React.useEffect(() => {
    console.log("🚀 ~ file: bio-editor.tsx:63 ~ debouncedBio:", debouncedBio);
    if (debouncedBio.length < 3) {
      setUpdateLoading(false);
      return;
    }
    try {
      updateBioMutation({
        // This doesn't always reload if since the BioFromSlug components aren't unloaded,
        // better bh
        refetchQueries: [GqlOps.Query.UserSummary, GqlOps.Query.BioFromSlug],
        variables: { id, bio: debouncedBio },
        update: (cache, { data }) => {
          cache.modify({
            id: cache.identify({
              __typename: "L_Bio_Public_Response",
              id: myData?.slug ?? "",
            }),
            fields: {
              details(cachedDetails) {
                return {
                  ...cachedDetails,
                  bio: data?.update_users_by_pk?.bio ?? debouncedBio,
                };
              },
            },
          });
        },
      })
        .then(onSave)
        .then(() => {
          setUpdateLoading(false);
        })
        .then(() => console.log("saved db bio: ", bio));
    } catch (e) {
      addToast({ content: "An error occurred saving your bio" });
    } finally {
      setUpdateLoading(false);
    }
  }, [debouncedBio, updateBioMutation]);
  return (
    <div className="form-control w-full ">
      <label className="label px-0">
        <span className="label-text">{labelOverride ?? "Bio"}</span>
        {anyLoading && <Spinner />}
      </label>
      <textarea
        placeholder="Bio Goes Here"
        rows={2 + (defaultValue?.split("\n").length ?? 6)}
        className="textarea textarea-bordered w-full block"
        value={bio}
        onChange={(e) => {
          if (e.target.value.length > 4000) {
            console.log("too long.. returnng");
            return;
          }
          setBio(e.target.value);
        }}
      />
    </div>
  );
}
