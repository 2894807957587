import { useIdentityStatusQuery } from "shared/dist/__generated__/components";
import { useMyId } from "shared/dist/auth-data";

export function useIdentityVerifiedStatus(): {
  verified: boolean;
  refetch: () => void;
  loading: boolean;
} {
  const my_id = useMyId()!;
  const { data, loading, refetch } = useIdentityStatusQuery({
    variables: { my_id },
    skip: !my_id,
    fetchPolicy: "cache-and-network",
  });
  return {
    verified: data?.user_summaries?.[0]?.identity_verification_success ?? false,
    loading,
    refetch,
  };
}
