import { FacebookPixelComponent, GoogleComponent } from "../../widgets/tracking-pixels";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { SpinnerCentered, SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";

import { Capacitor } from "@capacitor/core";
import { Container } from "../../widgets/container";
import { DateTime } from "luxon";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { H4 } from "shared-web-react/dist/widgets/text";
import { UserPageNavWrapper } from "../user-page/main-nav";
import { allRoutes } from "../../util/routes";
import avatars from "../../assets/avatars.png";
import blackAppStoreLogo from "../../assets/app-store-button-black.svg";
import eventImages from "../../assets/events-splash-footer.png";
import { formatDate } from "shared-web-react/dist/widgets/dates";
import redCandidLogo from "../../assets/neon-logos/candid-neon-red.png";
import { useEnv } from "shared/dist/util/env";
import { useEventPublicDetailsBySlug440Query } from "shared/dist/__generated__/components";
import { useMkUrl } from "shared/dist/util/env";
import { useMyId } from "shared/dist/auth-data";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import whiteAppStoreLogo from "../../assets/app-store-button-white.svg";
import whiteCandidLogo from "../../assets/neon-logos/candid-neon-beige.png";

export function EventSplash(): React.JSX.Element {
  return Capacitor.isNativePlatform() ? (
    <UserPageNavWrapper>
      <EventSplashInner />
      <FacebookPixelComponent />
      <GoogleComponent />
    </UserPageNavWrapper>
  ) : (
    <>
      <EventSplashInner />
      <FacebookPixelComponent />
      <GoogleComponent />
    </>
  );
}

function EventSplashInner(): React.JSX.Element {
  const slug = useTypedParams(allRoutes.EVENT_FROM_SLUG.SPLASH).slug;
  const [isDarkMode, setIsDarkMode] = React.useState<boolean>(false);
  const { data, loading, error } = useEventPublicDetailsBySlug440Query({
    variables: { url_slug: slug! },
    skip: !slug,
  });
  const my_id = useMyId();
  const event = data?.event_public_summaries[0];
  const formattedDate = React.useMemo(() => {
    return formatDate(event?.start, "cccc, LLLL dd, yyyy @ h:mm a ZZZZ");
  }, [event?.start]);

  useEffect(() => {
    setIsDarkMode(event?.display_dark_mode ?? false);
  }, [event]);

  const path = allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({ slug: slug! });
  const mkUrl = useMkUrl();
  const eventImgUrl = mkUrl(path) + (event?.media_upload_id ? `?mid=${event.media_upload_id}` : "");

  if (loading) {
    return <SpinnerFullScreen />;
  }
  if (!event || !slug || error) {
    return <ErrorComp caption={"Could not load event data"} />;
  }

  const totalAttendees = event.attendee_count?.attendee_count ?? 0;

  if (loading) {
    return <SpinnerCentered />;
  }

  return (
    <Container
      className={`flex flex-col justify-between items-center ${
        isDarkMode ? "bg-candid-plum-700" : "bg-candid-gray-300"
      } min-h-screen min-w-full md:min-w-screen lg:min-w-screen text-primary-content !px-0 sm:px-0 md:px-0 lg:px-0 xl:px-0`}
    >
      <div className="text-center flex flex-col items-center justify-center py-6">
        <img src={isDarkMode ? whiteCandidLogo : redCandidLogo} className="w-1/3 sm:w-1/4 mb-4" />
        <h3
          className={`text-xl w-4/5 md:max-w-2xl ${
            isDarkMode ? "text-primary-content" : "text-primary"
          } md:text-2xl lg:text-4xl mb-6`}
        >
          Want to connect with open minded ENM people in the wild? 😈
        </h3>
      </div>

      {eventImgUrl && (
        <div
          className="w-full bg-no-repeat bg-cover bg-center aspect-video mb-6"
          style={{ backgroundImage: `url(${eventImgUrl})` }}
        />
      )}
      <div
        className={`max-w-5xl ${
          isDarkMode ? "text-primary-content" : "text-success-content"
        } px-4 py-6`}
      >
        <div className="mb-6">
          <div className={isDarkMode ? "text-primary-content" : "text-primary"}>
            <H4 className="font-semibold text-xl md:text-2xl lg:text-4xl mb-4">
              Link up with the Candid community at {event?.name}!
            </H4>
          </div>
          <div className="mb-4">
            {formattedDate && (
              <p className="text-md md:text-xl lg:text-2xl mb-2">📅 Date: {formattedDate}</p>
            )}
            <p className="text-md md:text-xl lg:text-2xl mb-2">
              📍 Location:{" "}
              {my_id ? event?.location_description : "Shared with Candid Verified Members"}
            </p>
            <p
              className={`text-md md:text-xl lg:text-2xl ${
                isDarkMode ? "text-[#F5498E]" : "text-candid-red-700"
              } mb-2`}
            >
              <img src={avatars} alt="User Avatars" className="inline max-w-[4em]" />{" "}
              {totalAttendees < 25 ? "25" : totalAttendees}+ members attending
            </p>
          </div>
          <p className="text-sm md:text-xl lg:text-2xl mb-6" style={{ whiteSpace: "pre-wrap" }}>
            {event.description}
          </p>
          <JoinButtons slug={slug} isDarkMode={isDarkMode} />
        </div>
      </div>
      <img className="w-full object-cover h-auto mb-6" src={eventImages} />
      <div
        className={`max-w-5xl ${
          isDarkMode ? "text-primary-content" : "text-success-content"
        } px-4 py-6`}
      >
        <div>
          <div className="flex flex-col mb-6">
            <H4
              className={`font-semibold text-lg md:text-xl lg:text-2xl ${
                isDarkMode ? "text-primary-content" : "text-primary"
              } mb-4`}
            >
              New to Candid?🔥
            </H4>
            <p className="text-sm md:text-xl lg:text-2xl mb-4">
              Welcome to Candid, the ultimate social network for the ENM, Poly, and swinger
              communities. Discover like-minded partners, connect with open-minded individuals, and
              join exciting events tailored to our vibrant community. 🌶️
              <br />
              <br />
              Experience a safe, inclusive space where you can freely express yourself, build
              meaningful connections, and explore new adventures. Join Candid today and become part
              of a supportive and engaging network!
            </p>
          </div>

          <H4
            className={`text-lg font-semibold md:text-xl lg:text-2xl ${
              isDarkMode ? "text-primary-content" : "text-primary"
            } mb-4`}
          >
            How to Join ✨
          </H4>
          <p className="text-sm md:text-xl lg:text-2xl mb-2">
            1. Download the Candid App or join through our{" "}
            <a href={allRoutes.login.buildPath({})} className="font-bold underline">
              Web App
            </a>
          </p>
          <p className="text-sm md:text-xl lg:text-2xl mb-2">
            2. Go to the Calendar and RSVP for{" "}
            <a
              className="font-bold underline"
              href={allRoutes.EVENTS.DETAILS.buildPath({ event_id: event.id! })}
            >
              {event?.name}
            </a>
          </p>
        </div>
        <JoinButtons slug={slug} isDarkMode={isDarkMode} />
      </div>
    </Container>
  );
}

function JoinButtons({
  slug,
  isDarkMode,
}: {
  slug: string;
  isDarkMode: boolean;
}): React.JSX.Element {
  return (
    <div className="flex flex-col items-center py-4">
      <a href="https://apps.apple.com/us/app/candid-enm/id6466639507">
        <img
          src={isDarkMode ? whiteAppStoreLogo : blackAppStoreLogo}
          alt="App Store Logo"
          className="min-w-[11em] max-w-[10em] mb-4"
        />
      </a>
      <Link to={allRoutes.login.buildPath({})}>
        <p className="bg-dark-sunset text-white font-bold py-2 px-4 rounded-full transition duration-300 drop-shadow-xl">
          Join through WebApp
        </p>
      </Link>
    </div>
  );
}
