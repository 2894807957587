import React from "react";

const GOOGLE_ID = "G-XEJ73MX2ZQ";

/* cSpell:disable */
export function GoogleComponent(): React.JSX.Element {
  React.useEffect(() => {
    // Create the script element
    const scriptSrc = document.createElement("script");
    scriptSrc.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ID}`;
    scriptSrc.async = true;
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${GOOGLE_ID}');
    `;

    // Append the script to the document head
    document.head.appendChild(scriptSrc);
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(scriptSrc);
      document.head.removeChild(script);
    };
  }, []);

  return <div></div>;
}

const FB_ID = "803178251631575";
export function FacebookPixelComponent(): React.JSX.Element {
  React.useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${FB_ID}');
      fbq('track', 'PageView');
    `;

    // Append the script to the document head
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Your component's content */}
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${FB_ID}&ev=PageView&noscript=1`}
        />
      </noscript>
    </div>
  );
}
