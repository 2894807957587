import { faCheck, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import {
  useUpdateUserPronounsMutation,
  useUserPronounsQuery,
} from "shared/dist/__generated__/components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import clsx from "clsx";
import { debounce } from "shared/dist/util";
import { useAddThread } from "../add-thread-button";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useMyId } from "shared/dist/auth-data";
import { useRefetchMyBioSummary } from "../../screens/user-page/common";

type Inputs = { pronouns: string | null };
export function PronounsPicker(): React.JSX.Element {
  const refetchBio = useRefetchMyBioSummary();
  const my_id = useMyId()!;
  const [saveMutation, saveMutationResult] = useUpdateUserPronounsMutation();
  const { data, loading } = useUserPronounsQuery({
    variables: { user_id: my_id },
  });
  const [editValue, setEditedValue] = React.useState<null | string>(null);
  const [debounceLoading, setDebounceLoading] = React.useState(false);
  React.useEffect(() => {
    setEditedValue(data?.users_by_pk?.pronouns ?? null);
  }, [data, setEditedValue]);
  const saveDebounced = React.useRef(
    debounce(1000, async (data: Inputs, save: (data: Inputs) => Promise<any>) => {
      save(data);
    })
  );
  const anyLoading = loading || saveMutationResult.loading || debounceLoading;
  const addToast = useAddToast();
  const onChange = React.useCallback((pronouns: string) => {
    setDebounceLoading(true);
    setEditedValue(pronouns);
    saveDebounced.current({ pronouns }, ({ pronouns }) =>
      saveMutation({ variables: { id: my_id, pronouns } })
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => setDebounceLoading(false))
        .then(() => {
          addToast({ content: "Saved!" });
          refetchBio();
        })
    );
  }, []);
  return (
    <div className="w-full">
      <div className="form-control flex-1 w-full">
        <div>
          <label className="label">
            <span className="label-text">Pronouns</span>
          </label>
          <label className="join w-full">
            <input
              type="text"
              className="join-item input w-full input-bordered focus:outline-none"
              placeholder="she/her"
              value={editValue ?? ""}
              onChange={(e) => onChange(e.target.value)}
            />
            <span className={clsx("join-item btn")}>
              <FontAwesomeIcon
                icon={anyLoading ? faSpinner : faCheck}
                spin={anyLoading}
                fixedWidth
              />
            </span>
          </label>
        </div>
      </div>
      <div className="max-lg:hidden flex-1" />
    </div>
  );
}
