import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { faBadgeCheck, faCheck, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

import React from "react";
import { VerificationBox } from "../../../widgets/verification-box";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { faAddressCard } from "@fortawesome/pro-solid-svg-icons";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useIdentityVerifiedStatus } from "./identity/common";

// Leaving this in fow now as an example of how to compose an icon stack
export function VerifiedIdentityIconOldDl({
  className,
  dark,
  ...props
}: { dark?: boolean } & Omit<FontAwesomeIconProps, "icon">): React.JSX.Element {
  return (
    <span
      className={classNames(className, " relative -mt-[0.2em]")}
      // data-tip="This user has had a government ID verified by a Candid partner."
    >
      <FontAwesomeIcon className=" m-0" icon={faAddressCard} {...props} fixedWidth />
      <FontAwesomeIcon
        className={classNames(
          dark ? "stroke-primary" : "stroke-white",
          " m-0 absolute left-[40%] top-[30%] bottom-0"
        )}
        icon={faCheck}
        fixedWidth
        style={{
          fontSize: "0.85em",
          strokeWidth: "6em",
          paintOrder: "stroke fill",
        }}
      />
    </span>
  );
}

export function VerifiedIdentityIcon({
  className,
  dark,
  ...props
}: { dark?: boolean } & Omit<FontAwesomeIconProps, "icon">): React.JSX.Element {
  return (
    <span
      className={classNames(
        dark ? "text-primary" : "text-primary-content",
        "tooltip tooltip-bottom",
        className
      )}
      data-tip="This user has had a government ID verified by a Candid partner."
    >
      <FontAwesomeIcon
        icon={faBadgeCheck}
        {...props}
        className={clsx("stroke-[16px]", dark ? "stroke-white" : "stroke-primary")}
        fixedWidth
      />
    </span>
  );
}

function IdentityNotes(): React.JSX.Element {
  return (
    <>
      <ul className="list-inside list-disc">
        <li>Access to dating and discovery features.</li>
        <li>
          Free text chat on public chat channels.{" "}
          <em>Unverified users may be restricted to emoji reactions.</em>
        </li>
        <li>Create private photo albums.</li>
        <li>View private photo albums.</li>
        <li>Invitations to exclusive Candid events.</li>
      </ul>
      <p>
        <em>
          Note that some of these features are currently publicly available but will be restricted
          to verified users in the near future.
        </em>
      </p>
      <div className="divider my-0" />
      <div className="flex flex-col space-y-2">
        <>
          <p className="font-bold">Identity verification notes:</p>
          <p>
            Candid identity verification is to protect our users and keep our platform free from:
            abusive users, scammers, and more.
          </p>
          <p>
            Candid <b>does not sell your data</b> to any third parties and will not use any data
            solely obtained from your ID documents for any marketing purposes.
          </p>
          <p>
            <b>Note</b> that Candid's corporate entity: Principled Software Inc. will be displayed
            on the verification forms.
          </p>
          <p>Candid uses Stripe to process your identity documents.</p>
        </>
      </div>
    </>
  );
}

export const IdentitySettings = PersonaIdentitySettings;

export function PersonaIdentitySettings(): React.JSX.Element {
  console.log("🚀 - file: identity.tsx:107 - PersonaIdentitySettings");
  const { verified, refetch } = useIdentityVerifiedStatus();
  const [apiError, setApiError] = React.useState<string | null>(null);
  const [showInfo, setShowInfo] = React.useState(verified);
  const addToast = useAddToast();
  React.useEffect(() => {
    setShowInfo(!verified);
  }, [setShowInfo, verified]);
  return (
    <VerificationBox
      heading="🎉 Identity Verification 🎉"
      text="Candid identity verification is to protect our users and keep our platform free from:
      abusive users, scammers, and more."
    />
  );
}
