import * as Ably from "ably";

import { AblyProvider, useChannel } from "ably/react";
import { GqlOps, useAblyTokenQuery, useMyEventsQuery } from "shared/dist/__generated__/components";
import { P, match } from "ts-pattern";

import { DateTime } from "luxon";
import React from "react";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useApolloClient } from "shared/dist/apollo";
import { useMyId } from "shared/dist/auth-data";

function AblyListenerInner({ id }: { id: string }): React.JSX.Element {
  const channel = `USER:${id}`;
  const apollo = useApolloClient();
  const ablyData = useChannel(
    { channelName: channel, options: { modes: ["SUBSCRIBE"] } },
    (message) => {
      console.log("🚀 - file: ably-listener.tsx:17 - useChannel - message:", message);
      match(message.name)
        .with("thread_message", "thread_message_reaction", () => {
          console.log(
            "🚀 - file: ably-listener.tsx:32 - AblyListenerInner - message.name:",
            message.name
          );
          apollo.refetchQueries({
            include: [GqlOps.Query.ThreadMessages340, GqlOps.Query.ThreadsIndicator220],
          });
        })
        .otherwise(() => {
          apollo.refetchQueries({
            include: [GqlOps.Query.RelationshipRequestIndicator, GqlOps.Query.NotificationsFeed],
          });
        });
    }
  );
  try {
    console.log(
      "🚀 - file: ably-listener.tsx:33 - ablyData - ablyData:",
      ablyData?.channelError,
      ablyData?.connectionError
    );
    console.log("🚀 - file: ably-listener.tsx:38 - AblyListenerInner - channel:", channel);
  } catch (err) {
    console.error("🚀 - file: ably-listener.tsx:41 - AblyListenerInner error logging:", err);
  }
  return <></>;
}

export function AblyListener(): React.JSX.Element {
  const userId = useMyId();
  const { data, refetch } = useAblyTokenQuery({ skip: !userId });
  console.log("🚀 - file: ably-listener.tsx:27 - AblyListener - data:", data);
  const tokenData = match(data?.l_ably_token)
    .with({ __typename: "L_Simple_Response_Error" }, () => null)
    .with({ __typename: "L_Ably_Token_Success" }, ({ expires, token, clientId }) => ({
      token,
      clientId,
      expires: DateTime.fromISO(expires),
    }))
    .otherwise(() => null);
  const client = React.useMemo(() => {
    if (!tokenData || !tokenData.token) return null;
    console.log("🚀 - file: ably-listener.tsx:39 - client - tokenData:", tokenData);
    return new Ably.Realtime.Promise({
      token: tokenData?.token,
      clientId: tokenData?.clientId ?? undefined,
    });
  }, [tokenData]);
  console.log("🚀 - file: ably-listener.tsx:45 - AblyListener - tokenData:", tokenData);
  React.useEffect(() => {
    if (!tokenData) return;
    const delta = Math.abs(tokenData.expires.diffNow().toMillis());
    const timer = setTimeout(refetch, delta - 1000 * 60);
    return () => timer && clearTimeout(timer);
  }, [tokenData]);
  return userId && client ? (
    <AblyProvider client={client}>
      <AblyListenerInner id={userId} />
    </AblyProvider>
  ) : (
    <></>
  );
}
