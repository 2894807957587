import { useAuthIsLoading, useMyId } from "shared/dist/auth-data";

import { Link } from "react-router-dom";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../util/routes";
import logoPurple from "../../assets/neon-logos/candid-neon-purple.png";

export function TermsNavButtons(): React.JSX.Element {
  const id = useMyId();
  const loading = useAuthIsLoading();
  return (
    <>
      <div className="absolute flex items-center justify-between flex-row top-0 left-0 right-0 p-4 space-x-2">
        <div className="flex-1 ">
          <img src={logoPurple} className="w-64" />
        </div>

        <div className="flex-1">
          {loading ? (
            <div className="btn btn-accent capitalize">
              <div className={"opacity-0"}>Logout</div>
              <div className={`absolute mx-auto`}>
                <Spinner />
              </div>
            </div>
          ) : (
            <div className="space-x-2 text-right flex lg:space-x-4 text-sm lg:text-base">
              <Link
                className={`btn max-lg:btn-sm btn-primary rounded-full capitalize`}
                to={allRoutes.ROOT.buildPath({})}
              >
                <div className={id ? "" : "opacity-0"}>Home</div>
                <div className={`absolute mx-auto ${id ? "opacity-0" : ""}`}>Home</div>
              </Link>

              {id && (
                <Link
                  className={`btn max-lg:btn-sm btn-primary rounded-full capitalize`}
                  to={allRoutes.me.buildPath({})}
                >
                  My Page
                </Link>
              )}
              <Link
                className={`btn max-lg:btn-sm btn-primary rounded-full capitalize`}
                to={id ? allRoutes.logout.buildPath({}) : allRoutes.login.buildPath({})}
              >
                <div className={id ? "" : "opacity-0"}>Logout</div>
                <div className={`absolute mx-auto ${id ? "opacity-0" : ""}`}>Login</div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
