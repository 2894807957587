import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { Maybe, NonEmptyArray, classNames, isNonEmptyArray } from "shared/dist/util";
import {
  Relationship_Statuses_Enum,
  Relationship_Types_Enum,
  useRelationshipStatusBySlugQuery,
} from "shared/dist/__generated__/components";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";
import {
  faCircleExclamation,
  faCircleX,
  faCircleXmark,
  faClock,
  faEnvelope,
  faExclamationTriangle,
  faHeart,
  faMinusCircle,
  faPeopleArrows,
  faPlusCircle,
  faQuestionCircle,
  faSpinner,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faHeart as faHeartOutline,
  faUser as faUserOutline,
} from "@fortawesome/pro-regular-svg-icons";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import { SpinnerButton } from "./spinner-button";
import { allRoutes } from "../util/routes";
import clsx from "clsx";
import { faPeopleArrows as faPeopleArrowsLight } from "@fortawesome/pro-light-svg-icons";
import { faUserCheck } from "@fortawesome/pro-solid-svg-icons";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { navHeaderState } from "shared-web-react/dist/widgets/nav-stack-view";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useIdentityVerifiedStatus } from "../screens/user-page/settings/identity/common";
import { useMyId } from "shared/dist/auth-data";
import { useRelationshipFunctions } from "shared/dist/connections/relationships";

/** save a lot of typing here */
const TypesEnum = Relationship_Types_Enum;
type TypesEnum = Relationship_Types_Enum;

/** save a lot of typing here */
const StatusesEnum = Relationship_Statuses_Enum;
type StatusesEnum = Relationship_Statuses_Enum;

function BaseIcon({ on }: { on?: boolean }): React.JSX.Element {
  return <FontAwesomeIcon icon={on ? faUserCheck : faUserPlus} fixedWidth />;
}

function AddRelationshipNotLoggedIn(): React.JSX.Element {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <span className="opacity-50">
        <BaseIcon />
      </span>
      {showModal && (
        <div className="absolute">
          <div className="modal cursor-pointer modal-open text-primary !my-0">
            <div className="modal-box">
              <p>You'll need to login or create an account first</p>
              <Link to={allRoutes.login.buildPath({})}>Login</Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function toolTipForRelationship({
  status,
  type,
  i_initiated,
}: {
  i_initiated?: boolean;
  type: TypesEnum;
  status: StatusesEnum | null | undefined;
}) {
  //  switch ([status, type, i_initiated]) {
  if (type === TypesEnum.Friend) {
    if (status === StatusesEnum.Accepted) {
      return "You are currently friends.  Click to unfriend.";
    } else if (status === StatusesEnum.Requested) {
      return i_initiated
        ? "Friend request pending, click to cancel"
        : "Click to respond to this request";
    }
    return "Click to add this user as a friend";
  } else if (type === TypesEnum.Partner) {
    if (status === StatusesEnum.Accepted) {
      return "You are currently in a relationship.  Click to disconnect.";
    } else if (status === StatusesEnum.Requested) {
      return i_initiated ? "Relationship request pending" : "Click to respond to this request";
    }
    return "Click to create a relationship with this user";
  } else if (status === StatusesEnum.Rejected) {
    return i_initiated ? "Request that you declined" : "The other party declined this request.";
  } else if (status === StatusesEnum.EndedByFromUser || status === StatusesEnum.EndedByToUser) {
    return "Ended by one party";
  }
  return null;
}

export function iconForRelationship({
  status,
  type,
}: {
  i_initiated?: boolean;
  type: TypesEnum;
  status?: StatusesEnum | null | undefined;
}): FontAwesomeIconProps["icon"] {
  //  switch ([status, type, i_initiated]) {
  const icon =
    type === TypesEnum.Like
      ? status === StatusesEnum.Accepted
        ? faHeart
        : faHeartOutline
      : type === TypesEnum.Friend
      ? status === StatusesEnum.Accepted
        ? faUser
        : faUserOutline
      : type === TypesEnum.Partner
      ? status === StatusesEnum.Accepted
        ? faPeopleArrows
        : faPeopleArrowsLight
      : faQuestionCircle;
  return icon as IconProp;
}

function AddRelationshipIconOverlay({ icon }: { icon: IconProp }) {
  return (
    <FontAwesomeIcon
      className="fa-stack-1x stroke-white absolute -bottom-3 -right-3 lg:-bottom-5 lg:-right-5 opacity-90"
      style={{
        strokeWidth: "36px",
        fontSize: "0.75em",
      }}
      icon={icon}
      fixedWidth
    />
  );
}

type RelationshipIconProps = {
  loading?: boolean;
  i_initiated?: boolean;
  disabled?: boolean;
  type: TypesEnum;
  actionOverride?: "disconnect" | "exclamation";
  status?: StatusesEnum | undefined | null;
};

function RelationshipIcon({
  status,
  disabled,
  type,
  i_initiated,
  loading,
  actionOverride,
}: RelationshipIconProps): React.JSX.Element {
  const tip = toolTipForRelationship({ status, type, i_initiated });
  const icon = iconForRelationship({ status, type, i_initiated });
  return (
    <Tooltip>
      <TooltipTrigger>
        <span>
          <span className="relative indicator inline-block">
            <FontAwesomeIcon
              spin={loading}
              className={clsx(disabled && "opacity-75")}
              icon={loading ? faSpinner : icon}
              fixedWidth
            />
            {actionOverride === "exclamation" ? (
              <AddRelationshipIconOverlay icon={faCircleExclamation} />
            ) : actionOverride === "disconnect" ? (
              <AddRelationshipIconOverlay icon={faMinusCircle} />
            ) : !status ? (
              <AddRelationshipIconOverlay icon={faPlusCircle} />
            ) : (
              status === Relationship_Statuses_Enum.Requested &&
              (i_initiated ? (
                <AddRelationshipIconOverlay icon={faClock} />
              ) : (
                <>
                  <span className="indicator-item badge bg-red-500 badge-sm" />
                  <AddRelationshipIconOverlay icon={faEnvelope} />
                </>
              ))
            )}
          </span>
        </span>
      </TooltipTrigger>
      {tip && <TooltipContent>{tip}</TooltipContent>}
    </Tooltip>
  );
}

type RelationshipControlButtonProps = {
  rel_types: NonEmptyArray<TypesEnum>;
  disabled?: boolean;
  slug: string;
};

/**
 * @param children the first element is the icon and the next is the modal content
 */
type RelationshipControlWidgetProps = {
  children: [React.ReactNode, React.ReactNode];
  modalId: string;
  modalTitle: string;
  showCancel?: boolean;
};
function RelationshipControlModalToggle({
  modalId,
  children,
  modalTitle,
  showCancel,
}: RelationshipControlWidgetProps): React.JSX.Element {
  const checkboxRef = React.useRef<HTMLInputElement>(null);

  const closeModal = () => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = false;
    }
  };
  return (
    <>
      <label htmlFor={modalId}>{children[0]}</label>
      <input type="checkbox" id={modalId} className="modal-toggle" ref={checkboxRef} />
      <label
        htmlFor={modalId}
        className="modal modal-bottom md:modal-middle cursor-pointer"
        style={{ borderRadius: "0 !important" }}
      >
        <label className="modal-box relative !rounded-none max-md:pb-16" htmlFor="">
          <h3 className="text-lg font-bold">{modalTitle}</h3>
          <div className="py-4 flex space-x-2 flex-row flex-nowrap justify-stretch w-full">
            {children[1]}
          </div>
          {showCancel && (
            <button
              onClick={closeModal}
              className="btn btn-outline px-4 py-2 rounded-lg flex-1 w-full"
            >
              Cancel
            </button>
          )}
        </label>
      </label>
    </>
  );
}

function CancelRequestsButton({
  slug,
  rel_types,
  refetch,
  disabled: disabledProp,
}: RelationshipControlButtonProps & {
  refetch: () => Promise<any>;
}): React.JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const addToast = useAddToast();
  const { disconnect } = useRelationshipFunctions({
    setLoading,
    onError: (content) => addToast({ content, color: "error" }),
  });
  return (
    <RelationshipControlModalToggle
      modalId={`cancel_req_to_${slug}`}
      modalTitle={`Do you want to cancel this request to ${slug}?`}
      showCancel
    >
      <div className="btn btn-sm btn-outline">Cancel</div>
      <div className="flex flex-col w-full">
        <SpinnerButton
          loading={loading}
          disabled={loading}
          onClickWrapped={async () =>
            Promise.all(rel_types.map((rt) => disconnect(slug, rt))).then(refetch)
          }
          className={classNames("btn btn-primary w-full my-5", disabledProp && "opacity-50")}
        >
          <FontAwesomeIcon icon={faCircleXmark} className="mr-2" />
          Cancel Request
        </SpinnerButton>
      </div>
    </RelationshipControlModalToggle>
  );
}

function RespondToRequestsButton({
  slug,
  rel_types,
  refetch,
  disabled: disabledProp,
}: RelationshipControlButtonProps & {
  refetch: () => Promise<any>;
}): React.JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const addToast = useAddToast();
  const { acceptRequest, disconnect } = useRelationshipFunctions({
    setLoading,
    onError: (content) => addToast({ content, color: "error" }),
  });

  const { loading: verifiedLoading, verified } = useIdentityVerifiedStatus();
  const anyLoading = loading || verifiedLoading;
  const confirm = useConfirm();
  const navigate = useNavigate();
  const verifiedConfirm = React.useCallback(async () => {
    if (verifiedLoading || verified) return;
    confirm({
      title: "Verify your profile",
      content:
        "To maintain trust and safety, all users of Candid need to verify their profile with government ID before connecting to other members",
      okButtonContent: "Verify ID",
      onOk() {
        navigate(allRoutes.SETTINGS.PRIVACY.buildPath({}), {
          state: navHeaderState("Back to @" + slug),
        });
      },
    });
  }, [verifiedLoading, verified, confirm]);
  if (!verifiedLoading && !verified) {
    return (
      <button
        disabled={anyLoading}
        onClick={verifiedConfirm}
        className={classNames(disabledProp && "opacity-50")}
      >
        <RelationshipIcon
          type={rel_types[0] ?? Relationship_Types_Enum.Like}
          actionOverride="exclamation"
        />
      </button>
    );
  }
  return (
    <RelationshipControlModalToggle
      modalId={`respond_to_${slug}`}
      modalTitle={`Respond to @${slug}'s request`}
    >
      <RelationshipIcon
        {...{
          type: rel_types[0] ?? Relationship_Types_Enum.Like,
          status: StatusesEnum.Requested,
        }}
      />
      <>
        {rel_types.map((rel_type) => (
          <SpinnerButton
            key={rel_type}
            disabled={loading}
            onClickWrapped={async () => acceptRequest(slug, rel_type).then(() => refetch())}
            className={classNames("btn btn-primary flex-1", disabledProp && "opacity-50")}
          >
            <FontAwesomeIcon
              icon={iconForRelationship({
                status: StatusesEnum.Requested,
                type: rel_type,
              })}
              className="mr-2"
            />
            Accept {rel_type}
          </SpinnerButton>
        ))}
        <SpinnerButton
          disabled={loading}
          onClickWrapped={() =>
            Promise.all(rel_types.map((rel_type) => disconnect(slug, rel_type))).then(() =>
              refetch()
            )
          }
          className={classNames("relative btn btn-accent flex-1", disabledProp && "opacity-50")}
        >
          <FontAwesomeIcon icon={faCircleX} fixedWidth className="mr-2" />
          Deny Request
        </SpinnerButton>
      </>
    </RelationshipControlModalToggle>
  );
}

function DisconnectButton({
  slug,
  rel_types,
  refetch,
  disabled: disabledProp,
}: RelationshipControlButtonProps & {
  refetch: () => Promise<any>;
}): React.JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const addToast = useAddToast();
  const { disconnect } = useRelationshipFunctions({
    setLoading,
    onError: (content) => addToast({ content, color: "error" }),
  });
  return (
    <RelationshipControlModalToggle
      modalId={`disconnect_from_${slug}`}
      modalTitle={`Disconnect from @${slug}`}
    >
      <RelationshipIcon
        status={StatusesEnum.Accepted}
        type={rel_types[0] ?? TypesEnum.Like}
        actionOverride="disconnect"
      />
      <>
        <SpinnerButton
          disabled={loading}
          onClickWrapped={async () =>
            Promise.all(rel_types.map((rt) => disconnect(slug, rt))).then(refetch)
          }
          className={classNames("btn btn-primary mx-auto", disabledProp && "opacity-50")}
        >
          <FontAwesomeIcon icon={faCircleXmark} className="mr-2" />
          Disconnect
        </SpinnerButton>
      </>
    </RelationshipControlModalToggle>
  );
}

/**
 * This is called when the user has no existing relationships with the target slug
 *
 * @param props
 */
function AddSingleTypeRelationshipButton({
  slug,
  rel_type,
  bypassModalConfirmation: bypassModal,
  refetch,
  disabled: disabledProp,
}: Omit<RelationshipControlButtonProps, "rel_types"> & {
  bypassModalConfirmation?: boolean;
  rel_type: Relationship_Types_Enum;
  refetch?: () => Promise<any>;
}): React.JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const addToast = useAddToast();
  const { initiateRequest } = useRelationshipFunctions({
    setLoading,
    onError: (content) => addToast({ content, color: "error" }),
  });
  const { loading: verifiedLoading, verified } = useIdentityVerifiedStatus();
  const anyLoading = loading || verifiedLoading;
  const confirm = useConfirm();
  const navigate = useNavigate();
  const verifiedConfirm = React.useCallback(async () => {
    if (verifiedLoading || verified) return;
    confirm({
      title: "Verify your profile",
      content:
        "To maintain trust and safety, all users of Candid need to verify their profile with government ID before connecting to other members",
      okButtonContent: "Verify ID",
      onOk() {
        navigate(allRoutes.SETTINGS.PRIVACY.buildPath({}), {
          state: navHeaderState("Back to @" + slug),
        });
      },
    });
  }, [verifiedLoading, verified, confirm]);
  if (!verifiedLoading && !verified) {
    return (
      <button
        disabled={anyLoading}
        onClick={verifiedConfirm}
        className={classNames(disabledProp && "opacity-50")}
      >
        <RelationshipIcon type={rel_type} actionOverride="exclamation" />
      </button>
    );
  }
  if (bypassModal) {
    return (
      <SpinnerButton
        disabled={anyLoading}
        onClickWrapped={async () => initiateRequest(slug, rel_type).then(() => refetch?.())}
        className={classNames(disabledProp && "opacity-50")}
      >
        <RelationshipIcon type={rel_type} />
      </SpinnerButton>
    );
  }
  return (
    <RelationshipControlModalToggle
      modalId={`connect_to_${slug}`}
      modalTitle={`Connect with @${slug}`}
    >
      <RelationshipIcon type={rel_type} />
      <>
        {rel_type === TypesEnum.Like && (
          <SpinnerButton
            disabled={anyLoading}
            onClickWrapped={async () => initiateRequest(slug, TypesEnum.Like).then(refetch)}
            className={classNames("btn btn-primary flex-1", disabledProp && "opacity-50")}
          >
            <FontAwesomeIcon
              icon={iconForRelationship({
                type: Relationship_Types_Enum.Like,
              })}
              className="mr-2"
            />
            2 Send Like
          </SpinnerButton>
        )}
        {rel_type === TypesEnum.Friend && (
          <AddFriendModalContent {...{ disabledProp, slug, setLoading, loading, refetch }} />
        )}
      </>
    </RelationshipControlModalToggle>
  );
}

function AddFriendModalContent({
  loading,
  setLoading,
  disabledProp,
  refetch,
  slug,
}: {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  disabledProp?: boolean;
  slug: string;
  refetch?: Maybe<() => Promise<any>>;
}): React.JSX.Element {
  const addToast = useAddToast();
  const { initiateRequest } = useRelationshipFunctions({
    setLoading,
    onError: (content) => addToast({ content, color: "error" }),
  });
  return (
    <div className={classNames("flex flex-col gap-2 items-center justify-start")}>
      <p className={classNames("text-sm italic text-left")}>
        Remember to only use this "friends" feature for people you already know and want to connect
        with on Candid.
      </p>
      <p className={classNames("text-sm italic text-left")}>
        For people you'd like to connect with, send them a like{" "}
        <FontAwesomeIcon
          icon={iconForRelationship({
            status: Relationship_Statuses_Enum.Accepted,
            type: Relationship_Types_Enum.Like,
          })}
        />{" "}
        instead.
      </p>
      <SpinnerButton
        disabled={loading}
        onClickWrapped={() => initiateRequest(slug, TypesEnum.Friend).then(refetch)}
        className={classNames("relative btn btn-accent flex-1", disabledProp && "opacity-50")}
      >
        <FontAwesomeIcon
          icon={iconForRelationship({
            type: Relationship_Types_Enum.Friend,
          })}
          className="mr-2"
        />
        IRL Friend Request
      </SpinnerButton>
    </div>
  );
}

function AddRelationshipButton({
  slug,
  rel_types,
  refetch,
  disabled: disabledProp,
}: RelationshipControlButtonProps & {
  refetch: () => Promise<any>;
}): React.JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const addToast = useAddToast();
  const { initiateRequest } = useRelationshipFunctions({
    setLoading,
    onError: (content) => addToast({ content, color: "error" }),
  });
  return (
    <RelationshipControlModalToggle
      modalId={`connect_to_${slug}`}
      modalTitle={`Connect with @${slug}`}
    >
      <div className="btn btn-sm btn-outline">Add Friend</div>
      <div className="flex flex-col">
        {rel_types.includes(TypesEnum.Like) && (
          <SpinnerButton
            disabled={loading}
            onClickWrapped={async () => initiateRequest(slug, TypesEnum.Like).then(refetch)}
            className={classNames("btn btn-primary flex-1", disabledProp && "opacity-50")}
          >
            <FontAwesomeIcon
              icon={iconForRelationship({
                type: Relationship_Types_Enum.Like,
              })}
              className="mr-2"
            />
            Send Like
          </SpinnerButton>
        )}
        {rel_types.includes(TypesEnum.Friend) && (
          <AddFriendModalContent {...{ disabledProp, slug, setLoading, loading, refetch }} />
        )}
      </div>
    </RelationshipControlModalToggle>
  );
}
function CannotConnect({
  caption,
  children,
}: React.PropsWithChildren<{ caption: string }>): React.JSX.Element {
  return (
    <Tooltip>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent>{caption}</TooltipContent>
    </Tooltip>
  );
}

export function NonPartnerRelationshipTypeControl({
  slug,
  disabled,
  rel_type,
  bypassModalConfirmation,
}: {
  rel_type: Relationship_Types_Enum.Like | Relationship_Types_Enum.Friend;
  bypassModalConfirmation?: boolean;
  disabled?: boolean | undefined;
  slug: string;
}): React.JSX.Element {
  const my_id = useMyId()!;
  const {
    data,
    loading: queryLoading,
    refetch,
  } = useRelationshipStatusBySlugQuery({
    fetchPolicy: "cache-and-network",
    skip: !my_id,
    variables: {
      my_id,
      slug,
      type_exp: {
        _in: [Relationship_Types_Enum.Friend, Relationship_Types_Enum.Like],
      },
    },
  });
  const existingRel = data?.relationships?.[0];
  const extraRelationships = data?.relationships?.[1];
  if (extraRelationships) {
    console.log("🚀 ~ file: add-relationship-button.tsx:586 ~ data:", extraRelationships);
    return (
      <Tooltip>
        <TooltipTrigger>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </TooltipTrigger>
        <TooltipContent>An Error Occurred</TooltipContent>
      </Tooltip>
    );
  }
  if (!existingRel) {
    return (
      <AddSingleTypeRelationshipButton
        {...{
          slug,
          rel_type,
          disabled,
          refetch,
          bypassModalConfirmation,
        }}
      />
    );
  }
  const fromMe = existingRel.from_user_id === my_id;
  if (existingRel.type === rel_type) {
    if (existingRel.status === StatusesEnum.Rejected) {
      return fromMe ? (
        <Tooltip>
          <TooltipTrigger>
            <FontAwesomeIcon
              icon={iconForRelationship({ type: rel_type })}
              className={classNames("opacity-50")}
            />
          </TooltipTrigger>
          <TooltipContent>You cannot reconnect to this user</TooltipContent>
        </Tooltip>
      ) : (
        // If **I** rejected, then I can resend
        <AddSingleTypeRelationshipButton
          {...{
            slug,
            rel_type,
            disabled,
            refetch,
            bypassModalConfirmation,
          }}
        />
      );
    }
    if (existingRel.status === StatusesEnum.Accepted) {
      return <DisconnectButton {...{ slug, rel_types: [rel_type], disabled, refetch }} />;
    }
    if (existingRel.status === StatusesEnum.Requested) {
      return fromMe ? (
        <CancelRequestsButton {...{ slug, rel_types: [rel_type], disabled, refetch }} />
      ) : (
        <RespondToRequestsButton {...{ slug, rel_types: [rel_type], disabled, refetch }} />
      );
    }
    if (existingRel.status === StatusesEnum.EndedByToUser) {
      return fromMe ? (
        <CannotConnect caption="You cannot connect with this person">
          <FontAwesomeIcon
            icon={iconForRelationship({ type: rel_type })}
            className={"opacity-50"}
          />
        </CannotConnect>
      ) : (
        <AddSingleTypeRelationshipButton
          {...{ slug, rel_type, disabled, refetch, bypassModalConfirmation }}
        />
      );
    }
    if (existingRel.status === StatusesEnum.EndedByFromUser) {
      return fromMe ? (
        <AddSingleTypeRelationshipButton
          {...{ slug, rel_type, disabled, refetch, bypassModalConfirmation }}
        />
      ) : (
        <CannotConnect caption="You cannot connect with this person">
          <FontAwesomeIcon
            icon={iconForRelationship({ type: rel_type })}
            className={"opacity-50"}
          />
        </CannotConnect>
      );
    }
  } else {
    // There is an existing, other relationship type
    if (existingRel.status === StatusesEnum.Rejected) {
      return fromMe ? (
        <CannotConnect caption="You cannot connect with this person">
          <FontAwesomeIcon
            icon={iconForRelationship({ type: rel_type })}
            className={"opacity-50"}
          />
        </CannotConnect>
      ) : (
        <AddSingleTypeRelationshipButton
          {...{
            slug,
            rel_type,
            disabled,
            refetch,
            bypassModalConfirmation,
          }}
        />
      );
    }
    return (
      <CannotConnect caption="Cannot send multiple relationship requests">
        <FontAwesomeIcon
          icon={iconForRelationship({ type: rel_type })}
          className={classNames("opacity-50")}
        />
      </CannotConnect>
    );
  }
  return (
    <>
      {" "}
      <CannotConnect caption={JSON.stringify({ fromMe, existingRel })}>
        <FontAwesomeIcon
          icon={iconForRelationship({ type: rel_type })}
          className={classNames("opacity-50")}
        />
      </CannotConnect>
    </>
  );
}
/**
 * A flexible component which can add multiple relationship types.
 *
 * This was used early on where we wanted one button on the bio page.  Now we're
 * moving to one button for likes  and one for friends
 * @param { RelationshipControlButtonProps } prop
 * @returns
 */
export function MultipleTypeRelationshipControlButton({
  slug,
  rel_types,
  disabled: disabledProp,
}: RelationshipControlButtonProps): React.JSX.Element {
  const my_id = useMyId()!;
  const {
    data,
    loading: queryLoading,
    refetch,
  } = useRelationshipStatusBySlugQuery({
    variables: { my_id, slug, type_exp: { _in: rel_types } },
    fetchPolicy: "cache-and-network",
    skip: !my_id,
  });
  const [inboundRequests, outboundRequests, otherPartyRejectsRequests] = React.useMemo(() => {
    const inboundRequests =
      data?.relationships.filter(
        (t) => t.status === StatusesEnum.Requested && t.from_user_id !== my_id
      ) ?? [];
    const outboundRequests =
      data?.relationships.filter(
        (t) => t.status === StatusesEnum.Requested && t.from_user_id === my_id
      ) ?? [];
    const otherPartyRejectsRequests =
      data?.relationships.filter(
        (t) =>
          (t.status === StatusesEnum.Rejected && t.from_user_id === my_id) ||
          (t.status === StatusesEnum.EndedByFromUser && t.from_user_id !== my_id) ||
          (t.status === StatusesEnum.EndedByToUser && t.from_user_id === my_id)
      ) ?? [];
    return [inboundRequests, outboundRequests, otherPartyRejectsRequests];
  }, [data]);
  console.log("🚀 ~ file: add-relationship-button.tsx:521 ~ ]=React.useMemo ~ ", {
    slug,
    inboundRequests,
    outboundRequests,
    otherPartyRejectsRequests,
    rels: data?.relationships,
  });
  const myId = useMyId();
  const loading = queryLoading;
  if (!myId) { return <AddRelationshipNotLoggedIn />; } // prettier-ignore
  if (loading) {
    return (
      <SpinnerButton
        loading={true}
        className={classNames("relative", disabledProp && "opacity-50")}
        disabled={true}
        spinnerClassName="bottom-0"
      >
        {"\u00A0"}
      </SpinnerButton>
    );
  }

  // If we don't have any existing relationships, show the widget to add some
  if (!data?.relationships?.[0]) {
    return <AddRelationshipButton {...{ slug, rel_types, disabled: disabledProp, refetch }} />;
  }

  //If the other party has declined or rejected, block out all options
  if (otherPartyRejectsRequests.length) {
    return (
      <SpinnerButton
        className={classNames("relative tooltip", disabledProp && "opacity-50")}
        disabled={true}
        spinnerClassName="bottom-0"
        // data-tip="You cannot connect with this person"
      >
        <RelationshipIcon
          {...{
            status: StatusesEnum.Rejected,
            type: rel_types[0],
            disabled: true,
          }}
        />
      </SpinnerButton>
    );
  }

  // If we have **accepted** relationships, show the disconnect widget
  const acceptedRelationships =
    data?.relationships?.filter((t) => t.status === StatusesEnum.Accepted) ?? [];
  if (acceptedRelationships.length) {
    return (
      <DisconnectButton
        {...{
          slug,
          rel_types: [acceptedRelationships[0]?.type],
          disabled: disabledProp,
          refetch,
        }}
      />
    );
  }
  // Needed to make these changes due to a change in TS 5.4. see utils.ts
  const inboundRequestTypes = inboundRequests.map((t) => t.type);
  if (isNonEmptyArray(inboundRequestTypes)) {
    return (
      <RespondToRequestsButton
        {...{
          slug,
          rel_types: inboundRequestTypes,
          disabled: disabledProp,
          refetch,
        }}
      />
    );
  }
  // if we only have outbound requests, show the cancel widget
  const outboundRequestTypes = outboundRequests.map((t) => t.type);
  if (isNonEmptyArray(outboundRequestTypes)) {
    return (
      <CancelRequestsButton
        {...{
          slug,
          rel_types: outboundRequestTypes,
          disabled: disabledProp,
          refetch,
        }}
      />
    );
  }

  return (
    <SpinnerButton
      loading={true}
      className={classNames("relative", disabledProp && "opacity-50")}
      disabled={true}
      spinnerClassName="bottom-0"
    ></SpinnerButton>
  );
}

export function TestAddRelationshipButtonUi(props: RelationshipIconProps): React.JSX.Element {
  return (
    <SpinnerButton
      onClick={() => {}}
      loading={props.loading}
      className={classNames("relative", props.disabled && "opacity-50")}
      disabled={props.disabled}
      spinnerClassName="bottom-0"
    >
      <RelationshipIcon {...props} />
    </SpinnerButton>
  );
}
