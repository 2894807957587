import { BlockedUsers, DiscoveryHiddenUsers } from "./safety";

import { Container } from "../../../widgets/container";
import { DeleteAccountButton } from "./delete-account";
import { H2 } from "shared-web-react/dist/widgets/text";
import clsx from "clsx";

export function AccountCenter(): React.JSX.Element {
  return (
    <Container size="sm" className={clsx("p-2")}>
      <H2>Account Center:</H2>
      <div className="divider" />
      <DeleteAccountButton />
      <div className="divider" />
      <DiscoveryHiddenUsers />
      <div className="divider" />
      <BlockedUsers />
    </Container>
  );
}
