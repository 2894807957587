import { ImageUploader } from "../../../widgets/images";
import React from "react";
import { useUpdateEventMutation } from "shared/dist/__generated__/components";

type EventMediaUploaderProps = {
  mediaUploadId?: string;
  event_id?: string;
  setMediaUploadId: (mediaUploadId: string) => void;
};
export function EventMediaUploader({
  event_id,
  setMediaUploadId,
  mediaUploadId,
}: EventMediaUploaderProps): React.JSX.Element {
  const [updateMutation, updateResults] = useUpdateEventMutation();
  const [loading, setLoading] = React.useState(false);
  const [new_media_upload_id, setNewMediaUploadId] = React.useState<string | null>(null);
  return (
    <div className="my-2">
      <ImageUploader
        btnContent={
          loading
            ? "Uploading..."
            : mediaUploadId
            ? "Image Upload Complete"
            : "Upload Event Cover Image"
        }
        onError={() => {
          console.error("Erorr in uploadinng");
        }}
        btnClassName={
          loading ? "btn btn-primary" : mediaUploadId ? "btn btn-success" : "btn btn-primary"
        }
        onComplete={() => {
          if (new_media_upload_id) {
            if (event_id) {
              updateMutation({
                variables: {
                  event_id,
                  changes: { media_upload_id: new_media_upload_id },
                },
              });
            }
            setMediaUploadId(new_media_upload_id);
          }
          setLoading(false);
        }}
        onStart={() => setLoading(true)}
        // onDismiss={() => setLoading(false)}
        onUploadIdReceived={setNewMediaUploadId}
        mediaOptions={{
          exclude_from_bio: true,
        }}
      />
    </div>
  );
}
