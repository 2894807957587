import {
  Feature_Flags_Enum,
  L_News_Feed_Type,
  useNewsFeed440Query,
} from "shared/dist/__generated__/components";
import { H2, H3 } from "shared-web-react/dist/widgets/text";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { classNames, sortBy } from "shared/dist/util";
import { useIsDev, useIsProd } from "shared/dist/util/env";

import { Container } from "../../widgets/container";
import { DateTime } from "luxon";
import { ErrorBoundary } from "react-error-boundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NewsFeedItem } from "./news-feed/items";
import React from "react";
import { VisibilityObserver } from "shared-web-react/dist/widgets/visibility-observer";
import clsx from "clsx";
import { faHome } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useOnScreenLogger } from "shared-web-react/dist/util/on-screen-logger";
import { useVirtualizer } from "@tanstack/react-virtual";

export function Home(): React.JSX.Element {
  // const isProd = useIsProd();
  const [useDummyData, setUseDummyData] = React.useState(false);
  const isProd = useIsProd();
  const [feedType, setFeedType] = React.useState<L_News_Feed_Type>(L_News_Feed_Type.All);
  const { loading, data, fetchMore } = useNewsFeed440Query({
    variables: {
      filter: { feed_type: feedType },
      dummy_data: useDummyData,
    },
    fetchPolicy: "cache-first",
  });
  const discoveryFeedEnabled = useIsFeatureEnabled(Feature_Flags_Enum.NewsFeedDiscovery_01);
  const onAtBottom = React.useCallback(
    async (atBottom: boolean) => {
      // console.log("🚀 - file: home.tsx:44 - atBottom:", atBottom);
      if (!atBottom || loading) return;
      const nextPage = match(data?.l_news_feed)
        .with({ __typename: "L_News_Feed_Response_Success" }, (data) => data.next_page_cursor)
        .otherwise(() => null);
      // console.log("🚀 - file: home.tsx:36 - React.useEffect - nextPage:", nextPage);
      if (nextPage) fetchMore({ variables: { page: nextPage, cursor: nextPage } });
    },
    [loading, fetchMore, data]
  );

  const isDev = useIsDev();
  const newsFeedResult = data?.l_news_feed;
  const onScreenLog = useOnScreenLogger().log;
  const sorted = React.useMemo(() => {
    const sorted =
      newsFeedResult?.__typename !== "L_News_Feed_Response_Success"
        ? []
        : sortBy(newsFeedResult?.items ?? [], (item) =>
            DateTime.fromISO(item.created_at).toMillis()
          ).reverse();
    return sorted;
  }, [newsFeedResult]);
  React.useEffect(
    () =>
      onScreenLog({
        id: "news_count",
        content: `item count / media: ${sorted.length} / ${
          sorted.filter((s) => s.__typename === "L_News_Feed_Item_Media").length
        }`,
      }),
    [sorted, onScreenLog]
  );
  React.useEffect(
    () =>
      onScreenLog({ content: "itemid: " + window.history.state?.newsFeedItemId, id: "newsItem" }),
    [onScreenLog, window.history.state?.newsFeedItemId]
  );
  const scrollParentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: sorted.length + 1,
    getScrollElement: () => scrollParentRef.current,
    estimateSize: () => 750,
    getItemKey: (index) => sorted[index]?.id ?? "<empty>",
    measureElement(element, _entry, _instance) {
      const height = element.children[0]?.getBoundingClientRect?.().height ?? 0;
      return height;
    },
    gap: 8,
    overscan: 8 /* cSpell:disable-line */,
  });
  React.useEffect(() => {
    if (!sorted?.length) return;
    const idToScrollTo = window.history.state?.newsFeedItemId;
    if (!idToScrollTo) return;
    const index = sorted.findIndex((i) => i.id === idToScrollTo);
    // console.log("🚀 - file: home.tsx:99 - React.useLayoutEffect - index:", index);
    if (index) {
      setTimeout(() => {
        rowVirtualizer.scrollToIndex(index);
        window.history.replaceState({}, "");
      }, 1);
    }
  }, [sorted]);
  if (loading && !data?.l_news_feed) return <SpinnerCentered />;

  if (newsFeedResult?.__typename === "L_Simple_Response_Error")
    return <div>err: {newsFeedResult?.human_readable_error}</div>;

  if (newsFeedResult?.__typename === "L_News_Feed_Bad_Cursor")
    return <div>err: invalid cursor</div>;

  if (newsFeedResult?.__typename === "L_News_Feed_Empty" || !newsFeedResult?.items?.length) {
    return isProd ? (
      <Container
        size="xs"
        className="h-full p-4 flex flex-col items-center text-candid-purple-700 justify-center space-y-8 text-center"
      >
        <FontAwesomeIcon icon={faHome} fixedWidth size="5x" />
        <H3 className="font-semibold ">Empty News Feed</H3>
        <div className="rounded-md bg-candid-purple-700 text-white text-left space-y-3 p-4 font-medium text-lg">
          <p>Uh oh, looks like we don't have any updates for you.</p>
          <p>Add some friends and content for this to change.</p>
          {/* <ul className="list-disc list-outside ml-6">
            <li>Add your friends to build a solid core network.</li>
            <li>Upload some more of those beautiful profile pictures.</li>
            <li>Connect on the candid discord.</li>
          </ul> */}
        </div>
      </Container>
    ) : (
      <Container className="pt-4 flex flex-col gap-2 px-2">
        <H2>Empty news feed</H2>
        <button className="btn btn-primary" onClick={() => setUseDummyData(true)}>
          Use dummy data
        </button>
      </Container>
    );
  }
  return (
    <div className={clsx("absolute inset-0 max-h-full overflow-y-hidden min-h-0 ")}>
      {discoveryFeedEnabled && (
        <Container size="xs" className="mb-4 mt-2 px-2">
          {/* <p className="text-muted text-sm">Show updates from:</p> */}
          <div className={clsx("join flex flex-row items-center justify-center")}>
            {[
              { e: L_News_Feed_Type.ConnectionsOnly, t: "My Connections" },
              { e: L_News_Feed_Type.DiscoveryOnly, t: "Possible Matches" },
              { e: L_News_Feed_Type.All, t: "Everyone" },
            ].map((item) => (
              <button
                onClick={() => setFeedType(item.e)}
                key={item.e}
                className={clsx(
                  "join-item flex-1 btn btn-xs whitespace-nowrap btn-primary",
                  feedType === item.e ? "" : "btn-outline "
                )}
              >
                {item.t}
              </button>
            ))}
          </div>
        </Container>
      )}
      <div
        ref={scrollParentRef}
        className={clsx("relative h-full max-h-full min-h-0 overflow-y-auto  ")}
      >
        <Container
          size="xs"
          className={classNames(
            "h-full flex flex-col items-center pb-16",
            "text-candid-purple-700 justify-start space-y-8 text-center"
          )}
          style={{ height: "100%", position: "relative" }}
        >
          <div
            className="w-full max-w-full space-y-3 pb-16 "
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative",
            }}
          >
            <ErrorBoundary FallbackComponent={FeedFallback}>
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const item = sorted[virtualRow.index];
                return (
                  <div
                    key={`${virtualRow.key}`}
                    data-index={virtualRow.index}
                    ref={rowVirtualizer.measureElement}
                    style={{
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${Math.max(virtualRow.start)}px)`,
                    }}
                  >
                    {item && (
                      <>
                        <NewsFeedItem disableDeepLinks={useDummyData} item={item} />
                        <VisibilityObserver
                          onVisibility={(b) =>
                            b && window.history.replaceState({ newsFeedItemId: item.id }, "")
                          }
                        />
                        {isDev && (
                          <div className="absolute bottom-2 p-1 bg-gray-500 right-2 text-xs">
                            {virtualRow.index} / {Number(virtualRow.key)}
                          </div>
                        )}
                      </>
                    )}
                    {virtualRow.index === sorted.length - 1 && (
                      <VisibilityObserver
                        onVisibility={onAtBottom}
                        className={clsx("h-1 pb-16 mx-auto col-span-3 w-1 ")}
                      />
                    )}
                  </div>
                );
              })}
            </ErrorBoundary>
          </div>
        </Container>
      </div>
    </div>
  );
}

function FeedFallback(): React.JSX.Element {
  return (
    <div className="w-full h-full flex-col-center-center bg-primary">
      <Spinner />
    </div>
  );
}
