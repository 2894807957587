import React from "react";
import { match } from "ts-pattern";

export type TshirtSizes = "xl" | "lg" | "md" | "sm" | "xs" | "xxs";

export type ContainerProps = React.PropsWithChildren<{
  size?: TshirtSizes;
  className?: string;
  style?: React.CSSProperties;
  onHeight?: (height: number) => void;
}>;
export function Container({
  children,
  className,
  onHeight,
  size,
  style,
}: ContainerProps): React.JSX.Element {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    onHeight?.(ref?.current?.clientHeight ?? 0);
  }, [ref, onHeight]);
  const sizeClass = match(size)
    .with("xxs", () => "max-w-sm")
    .with("xs", () => "max-w-xl")
    .with("sm", () => "max-w-4xl")
    .with("md", () => "max-w-6xl")
    .with("lg", () => "max-w-7xl")
    .with("xl", () => "max-w-8xl")
    .with(undefined, () => "max-w-8xl")
    .exhaustive();

  return (
    <div
      ref={ref}
      className={`container mx-auto relative ${sizeClass} md:px-6 lg:px-8 ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}

export function ModalContainer({ children, className, size }: ContainerProps): React.JSX.Element {
  // for xs sizes, show the border even on mobile
  if (size === "xs") {
    return (
      <div
        className={`ModalContainer-xs w-screen bg-primary flex h-full flex-col justify-around overflow-hidden max-h-full p-2 lg:p-4`}
      >
        <div
          className={`lg:rounded-lg rounded-md p-4 md:p-6 lg:p-8 bg-base-100 mx-auto max-h-full overflow-auto w-full max-w-xs drop-shadow-md lg:drop-shadow-lg  ${className}`}
        >
          {children}
        </div>
      </div>
    );
  }
  return (
    <div
      className={`ModalContainer-not-xs w-screen bg-primary flex h-full flex-col max-lg:justify-start lg:justify-around overflow-hidden max-h-full lg:p-4`}
    >
      <div
        className={`lg:rounded-lg md:rounded-md p-4 md:p-6 lg:p-8 bg-base-100 mx-auto max-h-full overflow-auto w-full max-w-${
          size ?? "md"
        } lg:drop-shadow-lg  ${className}`}
      >
        {children}
      </div>
    </div>
  );
}

export function ModalContainerWithBackground({
  children,
  ...props
}: Omit<ContainerProps, "className">): React.JSX.Element {
  return (
    <div className="h-screen bg-primary flex flex-col items-center justify-center">
      <ModalContainer {...props} className="flex flex-col gap-3 ">
        {children}
      </ModalContainer>
    </div>
  );
}
