import { Capacitor, PermissionState } from "@capacitor/core";
import { L_Geo_Point_Type, useSetApnsTokenMutation } from "shared/dist/__generated__/components";
import { PushNotificationSchema, PushNotifications } from "@capacitor/push-notifications";
import React, { useContext } from "react";

import { Geolocation } from "@capacitor/geolocation";
import { match } from "ts-pattern";
import { useMyId } from "shared/dist/auth-data";
import { useSaveLocation } from "../widgets/bio/location-picker";

export type PermissionStateWithExtras = "loading" | "unavailable" | PermissionState;
type PermissionsObject = {
  geolocation: PermissionStateWithExtras;
  pushNotifications: PermissionStateWithExtras;
};
const defaultPermissionsObject: PermissionsObject = {
  pushNotifications: "loading",
  geolocation: "loading",
};
export type PermissionType = keyof PermissionsObject;

const PermissionsContext = React.createContext<{
  states: PermissionsObject;
  setState: (key: keyof PermissionsObject, newState: PermissionStateWithExtras) => void;
}>({
  states: defaultPermissionsObject,
  setState: () => {
    console.error("!!!! Set state default value called");
  },
});

export function useGetLocalPermissionState(permType: PermissionType) {
  return useContext(PermissionsContext).states[permType];
}

export function useSetLocalPermissionState(permType: PermissionType) {
  const ctx = useContext(PermissionsContext);
  return (newState: PermissionStateWithExtras) => ctx.setState(permType, newState);
}

/**
 * Calls the capacitor plugin to check the current permission state and updates the local state
 * @param permType
 * @returns a function to update the local state
 */
function useUpdatePermissionState(permType: PermissionType) {
  const { setState } = useContext(PermissionsContext);
  const check = useCheckPermission(permType);
  return React.useCallback(async () => {
    return check().then((currentState) => {
      setState(permType, currentState);
      return currentState;
    });
  }, [setState, check]);
}

function usePermissionIsAvailable(permType: PermissionType) {
  return match(permType)
    .with(
      "geolocation",
      () => Capacitor.isNativePlatform() && Capacitor.isPluginAvailable("Geolocation")
    )
    .with(
      "pushNotifications",
      () => Capacitor.isNativePlatform() && Capacitor.isPluginAvailable("PushNotifications")
    )
    .exhaustive();
}

// function usePermissionIsAvailableAndSetLocal(permType: PermissionType) {
//   const avail = usePermissionIsAvailable(permType);
//   const set = useSetLocalPermissionState(permType);
//   if (!avail) set("unavailable");
//   return avail;
// }

export function useCheckPermission(permType: PermissionType) {
  return async () => {
    const avail = usePermissionIsAvailable(permType);
    if (!avail) return "unavailable";
    const currentState = await match(permType)
      .returnType<Promise<PermissionStateWithExtras>>()
      .with("geolocation", async () => (await Geolocation.checkPermissions()).coarseLocation)
      .with("pushNotifications", async () => (await PushNotifications.checkPermissions()).receive)
      .exhaustive();
    return currentState;
  };
}

export function useRequestPermissions(permType: PermissionType) {
  const avail = usePermissionIsAvailable(permType);
  const set = useSetLocalPermissionState(permType);
  const fxn = React.useCallback(async () => {
    if (!avail) return;
    const newStatus = await match(permType)
      .with("geolocation", () =>
        Geolocation.requestPermissions({ permissions: ["location"] }).then((e) => e.location)
      )
      .with("pushNotifications", () =>
        PushNotifications.requestPermissions().then((e) => {
          const result = e.receive;
          match(result)
            .with("granted", () => PushNotifications.register())
            .otherwise(() => {});
          return result;
        })
      )
      .exhaustive();
    set(newStatus);
  }, [set, permType, avail]);
  return fxn;
}

const addPushNotificationListeners = async (
  setToken: (s: string) => Promise<any>,
  onNotificationReceived: (n: PushNotificationSchema) => Promise<any>,
  onNotificationActionPerformed: (n: PushNotificationSchema) => Promise<any>
) => {
  const unavailable =
    !Capacitor.isNativePlatform() || !Capacitor.isPluginAvailable("PushNotifications");
  if (unavailable) return;
  await PushNotifications.addListener("registration", async (token) => {
    await setToken(token.value);
  });

  await PushNotifications.addListener("registrationError", (err) => {
    console.error("Registration error: ", err.error);
  });

  await PushNotifications.addListener("pushNotificationReceived", (notification) => {
    console.log("Push notification received: ", notification);
    return onNotificationReceived(notification);
  });

  await PushNotifications.addListener("pushNotificationActionPerformed", (action) => {
    console.log(
      "🚀 - file: permissions-context.tsx:139 - awaitPushNotifications.addListener - notification:",
      action.notification.link
    );
    return onNotificationActionPerformed(action.notification);
  });
};

/**
 *
 * @returns a function to save the APNS token to the database
 */
function useRegisterApnsTokenFunction() {
  const myId = useMyId();
  const [mutate] = useSetApnsTokenMutation();
  const setLocalState = useSetLocalPermissionState("pushNotifications");
  return React.useCallback(
    async (token: string) => {
      if (!myId) return;
      setLocalState("granted");
      return mutate({ variables: { apns: token, user_id: myId } });
    },
    [mutate, myId]
  );
}

export function useListenAndRegisterForNotifications(
  setState: (s: PermissionState) => Promise<any>
) {}

function GeolocationInit({ children }: React.PropsWithChildren<{}>): React.JSX.Element {
  const myId = useMyId();
  const status = useGetLocalPermissionState("geolocation");
  const saveMutation = useSaveLocation({ source: L_Geo_Point_Type.Gps, suppressToast: true });
  const available = usePermissionIsAvailable("pushNotifications");
  const update = useUpdatePermissionState("geolocation");
  React.useEffect(() => {
    if (!available || !myId) return;
    update();
    if (status !== "granted") return;
    const updateLocation = () => {
      Geolocation.getCurrentPosition({ maximumAge: 1000 * 60 * 10 }).then((position) => {
        saveMutation({
          long: position.coords.longitude,
          lat: position.coords.latitude,
        });
      });
    };
    updateLocation();
    const interval = setInterval(updateLocation, 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, [status, saveMutation, myId, available]);
  return <>{children}</>;
}

function PushNotificationsInit({ children }: React.PropsWithChildren<{}>): React.JSX.Element {
  const registerTokenInDb = useRegisterApnsTokenFunction();
  const available = usePermissionIsAvailable("pushNotifications");
  const update = useUpdatePermissionState("pushNotifications");
  const navigateTo = (u: string) => window.location.pathname !== u && window.location.assign(u);
  React.useEffect(() => {
    try {
      if (!available) return;
      addPushNotificationListeners(
        async (t) => {
          update();
          return registerTokenInDb(t);
        },
        async (not) => {
          console.log("🚀 ~ file: permissions-context.tsx:216 ~ notification", not);
        },
        async (not) => {
          const link =
            not.link ??
            not.data?.link ??
            not.data?.data?.pinpoint?.deeplink; /* cSpell:disable-line */
          console.log("🚀 ~ file: permissions-context.tsx:251 ~ not.link", not, link);
          link && navigateTo(link.startsWith("http") ? new URL(link).pathname : link);
        }
      );
      // console.log("🚀 ~ file: permissions-context.tsx:223 PushNotificationsInit about to update");
      update().then((result) =>
        match(result)
          .with("granted", () => PushNotifications.register())
          .otherwise(() => {})
      );
    } catch (err) {
      console.error(
        "permissions-context.tsx:225 ~ PushNotificationsInit React.useEffect ~ err:",
        err
      );
    }
    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [available]);
  return <>{children}</>;
}

export function PermissionsStateProvider({
  children,
}: React.PropsWithChildren<{}>): React.JSX.Element {
  const geoLocAvail = usePermissionIsAvailable("geolocation");
  const pushAvail = usePermissionIsAvailable("pushNotifications");
  const [states, setStates] = React.useState<PermissionsObject>({
    pushNotifications: pushAvail ? "loading" : "unavailable",
    geolocation: geoLocAvail ? "loading" : "unavailable",
  });
  const setState = (key: PermissionType, newState: PermissionStateWithExtras) => {
    setStates((prev) => ({ ...prev, [key]: newState }));
  };
  useListenAndRegisterForNotifications(async (newState) => setState("pushNotifications", newState));
  return (
    <PermissionsContext.Provider value={{ states, setState }}>
      <GeolocationInit>
        <PushNotificationsInit>{children}</PushNotificationsInit>
      </GeolocationInit>
    </PermissionsContext.Provider>
  );
}
