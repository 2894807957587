import { faMagnifyingGlass, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "./input";
import React from "react";

interface SearchBarProps {
  onSearch: (term: string) => void;
  placeholder?: string;
  value: string;
  loading?: boolean;
}

export function SearchBar({
  onSearch,
  placeholder = "Search connections...",
  value,
  loading,
}: SearchBarProps): React.JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const STORAGE_KEY = "search_input_value";
  const [shouldFocus, setShouldFocus] = React.useState(false);

  // Effect to restore value when component is mounted
  React.useEffect(() => {
    const cachedValue = sessionStorage.getItem(STORAGE_KEY);
    sessionStorage.setItem(STORAGE_KEY, ""); // clear cache
    if (cachedValue) {
      onSearch(cachedValue); // Restore the cached value using the onSearch handler
      setShouldFocus(true); // Set focus flag when there's a cached value
    }
  }, []);

  // Effect to focus input only when shouldFocus is true
  React.useEffect(() => {
    if (shouldFocus && inputRef.current) {
      inputRef.current.focus();
      setShouldFocus(false); // Reset the focus flag
    }
  }, [shouldFocus]);

  return (
    <div className="w-full mx-auto">
      <div className="flex text-md items-stretch bg-white rounded-md border border-candid-gray-300 overflow-hidden">
        <Input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const term = e.target.value;
            onSearch(term);
            sessionStorage.setItem(STORAGE_KEY, term); // cache value
          }}
          className="w-full px-4 py-[5px] focus:outline-none border-none"
          ref={inputRef}
        />
        <div className="bg-gray-100 flex items-center justify-center px-3 border-l border-candid-gray-300">
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} className="h-4 w-4 text-gray-400 animate-spin" />
          ) : (
            <FontAwesomeIcon icon={faMagnifyingGlass} className="h-4 w-4 text-gray-400" />
          )}
        </div>
      </div>
    </div>
  );
}
