import { Container } from "../../../widgets/container";
import { ContentBox } from "../../../widgets/content-box";
import { IdentitySettings } from "./identity";
import { Link } from "react-router-dom";
import { NotificationSettings } from "./notifications";
import { ProfilePrivacySettings } from "./profile-privacy-settings";
import { ShowCandidTeamInFeedSettings } from "./show-candid-team-in-feed";
import { allRoutes } from "../../../util/routes";
import { classNames } from "shared/dist/util";

export function PrivacySettings(): React.JSX.Element {
  return (
    <Container
      size="xs"
      // Need a lot of padding for the visibility dropdown
      // removed for now (pb-64)
      className={classNames("flex flex-col h-full gap-2 items-stretch max-md:text-xs md:text-sm")}
    >
      <IdentitySettings />
      <ContentBox className="px-3 pt-2 pb-3">
        <ProfilePrivacySettings />
      </ContentBox>
      <ContentBox className="px-3 pt-2 pb-3">
        <NotificationSettings />
      </ContentBox>
      <ContentBox className="px-3 pt-2 pb-3">
        <ShowCandidTeamInFeedSettings />
      </ContentBox>

      <div className="sm:w-2/5 px-3 pb-3 flex-col justify-between w-3/5 ">
        <div className="flex-0 underline">
          <Link
            // className={`btn max-lg:btn-sm btn-primary rounded-full capitalize`}
            to={allRoutes.terms.buildPath({})}
          >
            Terms & Conditions
          </Link>
        </div>
        <div className="flex-0 underline">
          <Link
            // className={`btn max-lg:btn-sm btn-primary rounded-full capitalize`}
            to={allRoutes.privacy.buildPath({})}
          >
            Privacy Policy
          </Link>
        </div>

        <div className="flex-0 underline">
          <Link
            // className={`btn max-lg:btn-sm btn-primary rounded-full capitalize`}
            to={allRoutes.privacy.buildPath({})}
          >
            Community Guidelines
          </Link>
        </div>

        <div className="flex-0 underline">
          <a href="https://trustcandid.typeform.com/feedbackform">Contact Us</a>
        </div>
      </div>
    </Container>
  );
}
