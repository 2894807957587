import { useState, useCallback, useEffect } from "react";
import { secureStorageGet, secureStorageSet } from "../capacitor-plugins/secure-storage";
import { Capacitor } from "@capacitor/core";

const isNative = Capacitor.isNativePlatform();

export function useSecureStorage(storageKey: string) {
  const [lastChecked, setLastChecked] = useState<string | null>(null);

  const getSecureValue = useCallback(async () => {
    try {
      let value: string | null = null;
      if (isNative) {
        value = await secureStorageGet(storageKey);
      } else {
        value = localStorage.getItem(storageKey);
      }
      setLastChecked(value);
      return value;
    } catch (error) {
      console.error(`Error getting cookie ${storageKey}:`, error);
      return null;
    }
  }, [storageKey]);

  const setSecureValue = useCallback(
    async (value: string) => {
      try {
        if (isNative) {
          await secureStorageSet(storageKey, value);
        } else {
          localStorage.setItem(storageKey, value);
        }
        setLastChecked(value);
      } catch (error) {
        console.error(`Error setting cookie ${storageKey}:`, error);
      }
    },
    [storageKey]
  );

  useEffect(() => {
    getSecureValue();
  }, [getSecureValue]);

  return {
    lastChecked,
    getSecureValue,
    setSecureValue,
  };
}
