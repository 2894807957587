import { ProfileList, ProfileListItem, ProfileListProps } from "../../../widgets/profile-list";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { classNames, filterNulls } from "shared/dist/util";

import { DateTime } from "luxon";
import { MatchNotificationPopup } from "../../../widgets/bio/match-notifications";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { ZeroScreen } from "../../../widgets/zero-screens";
import { allRoutes } from "../../../util/routes";
import { navHeaderState } from "shared-web-react/dist/widgets/nav-stack-view";
import { useInboundRelationshipRequestsForUserV410Query } from "shared/dist/__generated__/components";
import { useMyId } from "shared/dist/auth-data";
import { useSecureStorage } from "../../../widgets/use-secure-storage";

export function DiscoveryMyIncomingLikes(): React.JSX.Element {
  const my_id = useMyId();
  const { getSecureValue, setSecureValue } = useSecureStorage("my_likes");
  const [initialLastChecked, setInitialLastChecked] = useState<string | null>(null);
  const [, setLastChecked] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { data, loading, refetch } = useInboundRelationshipRequestsForUserV410Query({
    skip: !my_id,
    variables: { types: ["like"], my_id: my_id! },
  });

  const [selectedSlug, setSelectedLikeSlug] = useState<string | null>(null);
  const [displayPassModal, setDisplayPassModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchInitialDate = async () => {
      const value = await getSecureValue();
      setInitialLastChecked(value);
      setLastChecked(value);
      setIsLoading(false);
    };

    fetchInitialDate();
  }, [getSecureValue]);

  useEffect(() => {
    if (!isLoading && !loading) {
      const newLastChecked = new Date().toISOString();

      setSecureValue(newLastChecked);
      setLastChecked(newLastChecked);
    }
  }, [isLoading, loading, setSecureValue]);

  const closeModelAndRefetch = useCallback(() => {
    setSelectedLikeSlug(null);
    setDisplayPassModal(false);
    refetch();
  }, [refetch]);

  const mkRoute: ProfileListProps["mkRoute"] = useCallback(
    ({ slug, ...other }: ProfileListItem) =>
      slug
        ? {
            to: allRoutes.PROFILE.buildPath({ slug }),
            state: navHeaderState("Back to my likes"),
          }
        : null,
    []
  );

  const mkActions: ProfileListProps["mkActions"] = useCallback(
    ({ slug, ...other }: ProfileListItem) => [
      slug
        ? {
            component: (
              <div className="grid grid-cols-2 gap-3">
                <div
                  onClick={() => {
                    setSelectedLikeSlug(slug);
                  }}
                  className="btn btn-sm btn-primary"
                >
                  Match
                </div>
                <div
                  className="btn btn-sm btn-outline"
                  onClick={() => {
                    setDisplayPassModal(true);
                    setSelectedLikeSlug(slug);
                  }}
                >
                  Pass
                </div>
              </div>
            ),
          }
        : null,
    ],
    []
  );

  const items: Array<ProfileListItem> = useMemo(() => {
    return filterNulls(
      data?.relationships_union?.map((e) => {
        const createdAt = e.created_at ? DateTime.fromISO(e.created_at) : null;
        const initialLastCheckedDate = initialLastChecked
          ? DateTime.fromISO(initialLastChecked)
          : null;
        const showNewBadge =
          createdAt && initialLastCheckedDate ? createdAt > initialLastCheckedDate : false;
        return e.snd_user_summary?.slug && e.snd_user_summary?.screen_name
          ? {
              slug: e.snd_user_summary.slug,
              screenName: e.snd_user_summary.screen_name,
              showNewBadge,
            }
          : null;
      })
    );
  }, [data, initialLastChecked]);

  const hasBadge = useCallback((profile: ProfileListItem) => {
    return profile.showNewBadge || false;
  }, []);

  if (loading || isLoading) return <SpinnerCentered />;

  return items.length === 0 ? (
    <div className="flex flex-col h-full w-full overflow-hidden items-stretch">
      <ZeroScreen screenType="discovery-incoming" className="px-0 " />
    </div>
  ) : (
    <div
      className={classNames("flex justify-start flex-col join join-vertical gap-4 h-full relative")}
    >
      <div className={classNames("p-4 pb-16 max-h-full overflow-y-auto")}>
        {selectedSlug && (
          <MatchNotificationPopup
            slug={selectedSlug}
            showPopup={selectedSlug}
            refetch={closeModelAndRefetch}
            displayPassModal={displayPassModal}
          />
        )}
        <ProfileList
          profiles={items}
          mkActions={mkActions}
          mkRoute={mkRoute}
          hasBadge={hasBadge}
          columnCountOverride={1}
        />
      </div>
    </div>
  );
}
