import { CURRENT_API_VERSION } from "shared/dist/version";
import { Capacitor } from "@capacitor/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Maybe } from "shared/dist/util";
import { NeonSpinner } from "../widgets/neon-spinner";
import React from "react";
import clsx from "clsx";
import { faAppStore } from "@fortawesome/free-brands-svg-icons";
import { faRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { useAsset } from "shared-web-react/dist/util/assets-provider";
import { useIsDev } from "shared/dist/util/env";
import { useServerApiVersionsQuery } from "shared/dist/__generated__/components";

type Version = { major: number; minor: number; patch: number };
function parseVersion(version: Maybe<string>): null | Version {
  if (!version) return null;
  const tokens = version.split(".");
  if (tokens.length !== 3) return null;
  const [major, minor, patch] = tokens.map((v) => parseInt(v));
  return { major, minor, patch };
}

function checkVersion({ me, min, def }: { me: Version | null; min: Version | null; def: boolean }) {
  if (!me || !min) return def;
  if (me.major > min.major) return true;
  if (me.major === min.major && me.minor > min.minor) return true;
  if (me.major === min.major && me.minor === min.minor && me.patch >= min.patch) return true;
  return false;
}

const isNativePlatform = Capacitor.isNativePlatform();
export function VersionCheck({ children }: React.PropsWithChildren<{}>): React.JSX.Element {
  const { data, loading } = useServerApiVersionsQuery({
    fetchPolicy: "cache-and-network",
    pollInterval: 1000 * 60 * 10,
  });
  const [forceFancyPantsSpinner, setForceFancyPantsSpinner] = React.useState(!useIsDev());
  console.log("🚀 - file: version-check.tsx:39 - VersionCheck - data:", data);
  React.useEffect(() => {
    const timer = setTimeout(() => setForceFancyPantsSpinner(false), 500);
    return () => timer && clearTimeout(timer);
  }, []);
  const logoUrl = useAsset("logoWhiteFullWord");
  const serverMinVersion = data?.l_min_api_version?.min_version;
  const serverMin = parseVersion(serverMinVersion);
  const myVersion = parseVersion(CURRENT_API_VERSION);
  if (loading || !serverMinVersion || forceFancyPantsSpinner) return <NeonSpinner />;
  const versionCheck = checkVersion({ me: myVersion, min: serverMin, def: true });
  if (!versionCheck) {
    return (
      <div className="pt-safe  pb-safe relative w-screen h-screen p-4 flex-col-center-center bg-primary text-primary-content">
        <div className=" !fixed bottom-0 right-0 text-right bg-primary z-50 p-4 pb-safe">
          <p>Required version: {serverMinVersion}</p>
          <p>Current version: {CURRENT_API_VERSION}</p>
        </div>
        <div className=" !fixed top-4 w-screen bg-primary z-50 p-4 pt-safe">
          {logoUrl && <img className={`max-w-[75%] w-48`} src={logoUrl} />}
        </div>
        <div className=" text-4xl font-semibold flex-col-center-center gap-4">
          <FontAwesomeIcon icon={isNativePlatform ? faAppStore : faRotateRight} size="2x" />
          <p className="text-4xl font-semibold text-center">
            We're sorry, but your version of Candid is slightly out of date.
          </p>
          {isNativePlatform ? (
            <a
              href="https://apps.apple.com/us/app/candid-enm/id6466639507"
              target="_blank"
              className={clsx("btn btn-lg btn-base-200")}
            >
              Please update to the latest version.
            </a>
          ) : (
            <span>
              <button className={clsx("text-2xl link")} onClick={() => window.location.reload()}>
                reload
              </button>
            </span>
          )}
        </div>
      </div>
    );
  }
  return <>{children}</>;
}
