import {
  FullScreenSubMenu,
  FullScreenSubMenuSideBar,
} from "shared-web-react/dist/widgets/full-screen-sub-menu";
import { NavLink, Outlet, useParams } from "react-router-dom";

import { IndicatorNavLink } from "../../widgets/indicator-nav-link";
import { ThreadList } from "./thread-list";
import { allRoutes } from "../../util/routes";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { topNavbarSpacingClassName } from "../user-page/top-navbar";

export function MessagingLanding(): React.JSX.Element {
  return <div className="max-lg:hidden">{/* <div>Messaging Home Page</div> */}</div>;
}

type MessagingViewProps = {
  children: React.ReactNode;
};
export function MessagingHome({ children }: MessagingViewProps): React.JSX.Element {
  const hasThreadId = !!useParams()?.thread_id;
  return (
    <FullScreenSubMenu showSidebar={!hasThreadId}>
      {/* {false && ( */}
      <FullScreenSubMenuSideBar>
        {/* <div className="tabs flex w-full flex-nowrap mb-4 justify-center">
          <IndicatorNavLink
            to={allRoutes.MESSAGING.LIST.buildPath({})}
            className={({ isActive }) =>
              classNames(
                "tab tab-bordered text-white ",
                isActive
                  ? "border-white" //  text-white"
                  : "border-candid-gray-700" // text-candid-gray-700"
              )
            }
          >
            Messages
          </IndicatorNavLink>
          <IndicatorNavLink
            to={allRoutes.REQUESTS.buildPath({})}
            className={({ isActive }) =>
              classNames(
                "tab tab-bordered text-white ",
                isActive
                  ? "border-white" //  text-white"
                  : "border-candid-gray-700" // text-candid-gray-700"
              )
            }
          >
            Requests
          </IndicatorNavLink>
        </div> */}
        {children}
        {/* <ThreadList /> */}
      </FullScreenSubMenuSideBar>
      <Outlet />
    </FullScreenSubMenu>
  );
}

// export function MessagingSidebarTabs({
//   view,
// }: MessagingViewProps): React.JSX.Element {
//   return (
//   );
// }
