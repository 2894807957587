import { H3, H4 } from "shared-web-react/dist/widgets/text";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ModalContainer, ModalContainerWithBackground } from "../../../widgets/container";
import { SpinnerCentered, SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";

import React from "react";
import { allRoutes } from "../../../util/routes";
import { useAcceptPartnershipInviteMutation } from "shared/dist/__generated__/components";

function AcceptInviteError({ error }: { error: string }): React.JSX.Element {
  return (
    <ModalContainerWithBackground size="xs">
      <H4>An Error occurred: </H4>
      <p>{error}</p>
    </ModalContainerWithBackground>
  );
}

function AcceptInviteSuccess(): React.JSX.Element {
  return (
    <ModalContainerWithBackground size="xs">
      <H4>Success!</H4>
      <Link className="btn btn-primary btn-large" to={allRoutes.me.buildPath({})}>
        Go to my page
      </Link>
    </ModalContainerWithBackground>
  );
}

export function AcceptPartnerInvite(): React.JSX.Element {
  const { invitation_code } = useParams<{ invitation_code: string }>();
  const [mutate, { data, loading, error }] = useAcceptPartnershipInviteMutation();
  const navigate = useNavigate();
  const devOverridePageMatch = window.location.search?.match(/.*page=(\S+)$/)?.[1];
  console.log(
    "🚀 ~ file: accept-partner-invite.tsx:42 ~ AcceptPartnerInvite ~ devOverridePageMatch:",
    devOverridePageMatch,
    window.location.search
  );
  React.useEffect(() => {
    if (devOverridePageMatch) {
      return;
    }
    if (!invitation_code || invitation_code?.length === 0) {
      navigate(allRoutes.HOME.buildPath({}));
      return;
    }
    mutate({ variables: { code: invitation_code } });
  }, [useNavigate]);
  if (devOverridePageMatch) {
    return devOverridePageMatch === "error" ? (
      <AcceptInviteError error="Dev Override Error" />
    ) : devOverridePageMatch === "loading" ? (
      <ModalContainerWithBackground size="xs">
        <div className="py-8">
          <SpinnerCentered />
        </div>
      </ModalContainerWithBackground>
    ) : (
      <AcceptInviteSuccess />
    );
  }
  if (loading) {
    return (
      <ModalContainerWithBackground size="xs">
        <div className="py-8">
          <SpinnerCentered />
        </div>
      </ModalContainerWithBackground>
    );
  }
  if (data?.lm_accept_partner_invite?.__typename === "L_Simple_Response_Error") {
    return (
      <AcceptInviteError
        error={data?.lm_accept_partner_invite?.human_readable_error ?? "Unknown Error"}
      />
    );
  }
  return <AcceptInviteSuccess />;
}
