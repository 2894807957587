import { Link, useNavigate, useParams } from "react-router-dom";
import { allRoutes, useLogoutAndRedirect } from "../../util/routes";
import {
  faBars,
  faBell,
  faCalendar,
  faComputer,
  faEyeSlash,
  faFire,
  faGear,
  faHeadset,
  faLock,
  faPaintBrush,
  faPerson,
  faShield,
  faUserMagnifyingGlass,
  faUserPlus,
} from "@fortawesome/pro-solid-svg-icons";

import { BigModal } from "../../widgets/bio/big-modal";
import { Feature_Flags_Enum } from "shared/dist/__generated__/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavbarDropdownItem } from "../../widgets/bio/nav-dropdowns";
import { PersonaButton } from "./settings/identity/persona";
import { QrCodeToShare } from "../../widgets/bio/share-qr-code";
import React from "react";
import { ShowIndicator } from "shared/dist/indicators-context";
import { faFaceParty } from "@fortawesome/pro-duotone-svg-icons";
import { useAsset } from "shared-web-react/dist/util/assets-provider";
import { useBlockUser } from "./settings/safety";
import { useIdentityVerifiedStatus } from "./settings/identity/common";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useIsProd } from "shared/dist/util/env";
import { useMyId } from "shared/dist/auth-data";
import { useMySlug } from "../bio/bio-settings";

export type NavbarProps = {
  hideActions?: boolean;
};

function NavbarRightContentAnon(): React.JSX.Element {
  const id = useMyId();
  if (id) {
    return <></>;
  }
  return (
    <Link className="text-candid-gray-200 font-semibold" to={allRoutes.login.buildPath({})}>
      Login
    </Link>
  );
}
function NavbarRightContentLoggedIn({}: {}): React.JSX.Element {
  const slug = useMySlug()?.slug;

  const showNotifications = useIsFeatureEnabled(Feature_Flags_Enum.NotificationsInAppV00);
  return (
    <div
      className={`flex-0 max-md:text-lg max-md:space-x-4 text-2xl flex space-x-6 items-center flex-row`}
    >
      {showNotifications && slug && (
        <Link
          className={"text-primary flex justify-center items-center"}
          to={allRoutes.PROFILE.NOTIFICATIONS.buildPath({ slug })}
        >
          <ShowIndicator prefix={allRoutes.PROFILE.NOTIFICATIONS.buildPath({ slug })}>
            <FontAwesomeIcon icon={faBell} fixedWidth className="text-primary" />
          </ShowIndicator>
        </Link>
      )}

      <Link className={"text-primary"} to={allRoutes.EVENTS.LIST.buildPath({})}>
        <ShowIndicator prefix={allRoutes.EVENTS.LIST.buildPath({})}>
          <FontAwesomeIcon icon={faCalendar} fixedWidth />
        </ShowIndicator>
      </Link>
      <NavbarHamburger />
    </div>
  );
}

function NavbarHamburger(): React.JSX.Element {
  const navigate = useNavigate();
  const logout = useLogoutAndRedirect();
  const isProd = useIsProd();
  const [showWidget, setShowWidget] = React.useState<null | "qr">(null);
  const [showCopyToast, setShowCopyToast] = React.useState(false);
  const labelRef = React.useRef<any>(null);
  const { slug } = useParams();
  const mySlug = useMySlug()?.slug;
  const blockUser = useBlockUser(slug === mySlug ? undefined : slug);
  const isCreatingEventsEnabled = useIsFeatureEnabled(Feature_Flags_Enum.EventsCreateV00);
  const isInvitingUsersEnabled = useIsFeatureEnabled(Feature_Flags_Enum.UserToUserInviteV00);
  const { verified } = useIdentityVerifiedStatus();
  React.useEffect(() => {
    if (showWidget === "qr") {
      const elem = document.activeElement as any;
      elem?.blur?.();
    }
  }, [showWidget, labelRef]);
  return (
    <>
      <div className={`dropdown dropdown-end relative `}>
        <label tabIndex={0} ref={labelRef} className="flex justify-center items-center">
          <ShowIndicator prefix={allRoutes.SETTINGS.buildPath({})}>
            <FontAwesomeIcon icon={faBars} className="text-primary" />
          </ShowIndicator>
        </label>

        <ul
          tabIndex={0}
          className="mt-[23px] mr-[-16px] dropdown-content whitespace-nowrap text-base menu p-2 shadow bg-primary "
        >
          {/* <NavbarDropdownItem icon={faQrcode} onClick={() => setShowWidget("qr")}>
            Share My Page (QR)
          </NavbarDropdownItem> */}
          {/* <NavbarDropdownItem
            icon={faClipboard}
            status={loadingCopyUrl ? "coming soon" : undefined}
            onClick={() => {
              console.log(
                "🚀 ~ file: navbar.tsx:207 ~ NavbarHamburger ~ loadingCopyUrl",
                loadingCopyUrl
              );
              console.log(
                "🚀 ~ file: navbar.tsx:209 ~ NavbarHamburger ~ copyUrlToClipboard",
                copyUrlToClipboard
              );
              if (!copyUrlToClipboard) {
                return;
              }
              copyUrlToClipboard();
              setShowCopyToast(true);
              const elem = document.activeElement as any;
              elem?.blur?.();
              setTimeout(() => setShowCopyToast(false), 2500);
            }}
          >
            Copy My Page URL
          </NavbarDropdownItem> */}
          <NavbarDropdownItem icon={faPerson} onClick={() => navigate(allRoutes.me.buildPath({}))}>
            My Page
          </NavbarDropdownItem>

          {!verified && (
            <NavbarDropdownItem icon={faFire}>
              <PersonaButton
                beginBtnClassName="text-warning"
                beginBtnContent="Verify my Identity"
              />
            </NavbarDropdownItem>
          )}
          <NavbarDropdownItem icon={faGear} to={allRoutes.SETTINGS.buildPath({})}>
            Settings
          </NavbarDropdownItem>
          {isCreatingEventsEnabled && (
            <NavbarDropdownItem
              icon={faFaceParty}
              onClick={() => navigate(allRoutes.EVENTS.CREATE.buildPath({}))}
            >
              Create Event
            </NavbarDropdownItem>
          )}
          <NavbarDropdownItem
            icon={faShield}
            onClick={() => navigate(allRoutes.SETTINGS.ACCOUNT.buildPath({}))}
          >
            Account Center
          </NavbarDropdownItem>
          {isInvitingUsersEnabled && mySlug && (
            <NavbarDropdownItem
              icon={faUserPlus}
              onClick={() => navigate(allRoutes.PROFILE.INVITES.buildPath({ slug: mySlug }))}
            >
              <span className="text-warning">Invite a Friend</span>
            </NavbarDropdownItem>
          )}
          {slug && slug !== mySlug && (
            <>
              <div className="divider my-1" />
              <NavbarDropdownItem icon={faEyeSlash} onClick={() => blockUser()}>
                Block @{slug}
              </NavbarDropdownItem>
            </>
          )}
          <li>
            <Link to={allRoutes.SUPPORT.buildPath({})} className="text-base-100">
              <FontAwesomeIcon icon={faHeadset} fixedWidth />
              Contact Support
            </Link>
          </li>
          <div className="divider my-1" />
          <NavbarDropdownItem icon={faLock} onClick={() => logout()}>
            Logout
          </NavbarDropdownItem>
          {!isProd && (
            <>
              <div className="divider my-1" />
              <NavbarDropdownItem
                icon={faPaintBrush}
                onClick={() => navigate(allRoutes.DEV_UTILS.COLORS.buildPath({}))}
              >
                Color Test
              </NavbarDropdownItem>
              <NavbarDropdownItem
                icon={faComputer}
                onClick={() => navigate(allRoutes.DEV_UTILS.INFO.buildPath({}))}
              >
                Dev Info
              </NavbarDropdownItem>
              <NavbarDropdownItem
                icon={faUserMagnifyingGlass}
                onClick={() => navigate(allRoutes.DEV_UTILS.UI_TEST_PAGES.buildPath({}))}
              >
                Dev UI test pages
              </NavbarDropdownItem>
            </>
          )}
        </ul>
      </div>
      {showWidget === "qr" && (
        <BigModal onDismiss={() => setShowWidget(null)}>
          <QrCodeToShare />
        </BigModal>
      )}
      {showCopyToast && (
        <div
          className="toast toast-top opacity-90 toast-center"
          onClick={() => setShowCopyToast(false)}
        >
          <div className="alert alert-success">
            <div>
              <span>Copied!</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export const topNavbarTwHeight = "16";
export const topNavbarHeightClassName: React.HTMLAttributes<HTMLButtonElement>["className"] = `h-${topNavbarTwHeight}`;
export const topNavbarSpacingClassName: React.HTMLAttributes<HTMLButtonElement>["className"] = `top-${topNavbarTwHeight}`;

// Currently we need to hardcode the height of the navbar so that it the bottom
// one is hidden on mobile but the modal is still centered on desktop.  There's probably
// a better solution.  will fiugre it out later
export function TopNavWithSpacer({
  hideActions,
  children,
}: React.PropsWithChildren<Omit<NavbarProps, "onHeight">>): React.JSX.Element {
  // const [height, setHeight] = React.useState(0);
  return (
    <div>
      <div className={`fixed left-0 right-0 top-[64px] bottom-0 lg:bottom-[64px] overflow-auto`}>
        {children}
      </div>
      <div
        className={`top-nav-spacer w-full bg-primary z-45-nav-sidebar fixed right-0 left-0 h-0 lg:h-[64px]`}
      />
      <UserNavbar hideActions={hideActions} />
    </div>
  );
}
export function UserNavbar({ hideActions }: NavbarProps): React.JSX.Element {
  const id = useMyId();

  const logo = useAsset("logoCircleNoPadding");
  return (
    <div className="UserNavbar w-screen">
      <div
        className="px-2 w-screen bg-primary-content lg:border-b-primary lg:border-b z-40-nav-main h-16"
        // className="px-4 !fixed top-safe w-screen bg-primary-content lg:border-b-primary lg:border-b z-40-nav-main h-16"
        // ref={ref}
      >
        {/* <Container size={userPageContainerWidth} className=" px-2"> */}
        <div className={`flex-row flex justify-between items-center h-full text-candid-gray-200`}>
          {hideActions ? (
            <img className={`h-[1.5rem]`} src={logo} />
          ) : (
            <>
              <Link className={`flex-1 `} to={allRoutes.HOME.buildPath({})}>
                <img className={`h-[1.5rem]`} src={logo} />
              </Link>
              {id ? <NavbarRightContentLoggedIn /> : <NavbarRightContentAnon />}
            </>
          )}
        </div>
        {/* </Container> */}
      </div>
    </div>
  );
}
