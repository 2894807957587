import {
  ProfileList,
  ProfileListItem,
  ProfileListProps,
  profileListItemFromSummary,
} from "../../../widgets/profile-list";
import {
  Relationship_Types_Enum,
  useOutboundRelationshipRequestsForUserV410Query,
} from "shared/dist/__generated__/components";

import { AddThreadButton } from "../../../widgets/add-thread-button";
import { IconButtonWrapper } from "../../../widgets/icon-button-wrapper";
import { MultipleTypeRelationshipControlButton } from "../../../widgets/add-relationship-button";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { ZeroScreen } from "../../../widgets/zero-screens";
import { filterNulls } from "shared/dist/util";
import { useMyId } from "shared/dist/auth-data";

type FriendsRequestListProps = {};

export function OutboundFriendsRequestList({}: FriendsRequestListProps): React.JSX.Element {
  const my_id = useMyId() ?? "";
  const { data, loading } = useOutboundRelationshipRequestsForUserV410Query({
    variables: { type: "friend", my_id },
  });
  const profiles = React.useMemo(() => {
    return (
      data?.relationships_union.map((r) => profileListItemFromSummary(r.snd_user_summary)) ?? []
    );
  }, [data, my_id]);
  const mkActions: ProfileListProps["mkActions"] = React.useCallback(
    ({ slug }: ProfileListItem) => {
      return filterNulls(
        slug
          ? [
              {
                component: (
                  // <IconButtonWrapper className="!p-[0.15rem] text-xl">
                  <MultipleTypeRelationshipControlButton
                    slug={slug}
                    rel_types={[Relationship_Types_Enum.Like, Relationship_Types_Enum.Friend]}
                  />
                  // </IconButtonWrapper>
                ),
              },
            ]
          : []
      );
    },
    []
  );
  if (loading) {
    return <SpinnerCentered />;
  }
  if (profiles.length === 0) {
    return (
      <div className="flex flex-col h-full w-full overflow-hidden">
        <ZeroScreen screenType="friends-outbound-request" />
      </div>
    );
  }
  return (
    <div>
      <div className="p-5">
        <ProfileList {...{ profiles, mkActions }} />
      </div>
    </div>
  );
}
