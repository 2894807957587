import { Share, ShareOptions } from "@capacitor/share";

import { Capacitor } from "@capacitor/core";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";

export type ShareOpts = ShareOptions & { fallbackText: string };
const isNativePlatform = Capacitor.isNativePlatform();
export function useShare() {
  const addToast = useAddToast();
  return async ({ fallbackText, dialogTitle, files, ...opts }: ShareOpts) => {
    const canShare = await Share.canShare();
    if (isNativePlatform && canShare) {
      return Share.share({ dialogTitle, files, ...opts });
    } else {
      if (navigator.share) {
        navigator.share?.(opts);
      } else {
        navigator.clipboard?.writeText(opts?.text || window.location.href);
        addToast({ content: "Copied to Clipboard!" });
      }
    }
  };
}
