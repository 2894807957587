import { faCirclePlus, faCircleX } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "shared/dist/util";

type PillProps = {
  active?: boolean;
  value: string;
  label: string;
  hideIcon?: boolean;
  disabled?: boolean;
  className?: React.HTMLAttributes<HTMLButtonElement>["className"];
  onClick?: (value: string) => void;
};
export function Pill({
  disabled,
  className,
  hideIcon,
  active,
  value,
  label,
  onClick,
}: PillProps): React.JSX.Element {
  return (
    <button
      disabled={disabled}
      onClick={() => !disabled && onClick?.(value)}
      className={classNames(
        "btn btn-sm rounded-[0.4rem] my-1 mx-1 px-2 btn-primary active:hover:scale-100 ",
        active ? "" : "btn-outline",
        disabled && active && "!bg-primary !text-primary-content !opacity-50 !border-primary",
        disabled && !active && "opacity-50",
        //   ? ""
        //   : "!bg-primary !text-primary-content opacity-50",
        className
      )}
    >
      {!hideIcon && (
        <FontAwesomeIcon
          icon={active ? faCircleX : faCirclePlus}
          fixedWidth
          className="active:hover:scale-100"
        />
      )}
      {label}
    </button>
  );
}
