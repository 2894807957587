import React, { useCallback, useEffect, useMemo } from "react";
import {
  useClearAllIndicatorsFunction,
  useSetIndicatorFunction,
} from "shared/dist/indicators-context";
import {
  useNotificationsFeed510LazyQuery,
  usePublicUpcomingEventList451LazyQuery,
  useRelationshipRequestIndicatorLazyQuery,
  useThreadsIndicator220LazyQuery,
  useUserUrlSlug530Query,
} from "shared/dist/__generated__/components";

import { DateTime } from "luxon";
import { allRoutes } from "../util/routes";
import { sortBy } from "shared/dist/util";
import { useMyId } from "shared/dist/auth-data";
import { useNeedsEmailOrVerification } from "./pickers/email-editor";
import { useSecureStorage } from "./use-secure-storage";

const LOCAL_INDICATOR_KEYS = {} as const;

export function useSetLocalIndicatorFunction() {
  const setIndicator = useSetIndicatorFunction();

  return useCallback(
    (key: keyof typeof LOCAL_INDICATOR_KEYS, visible: boolean) => {
      const prefix = LOCAL_INDICATOR_KEYS[key];
      visible ? localStorage.removeItem(key) : localStorage.setItem(key, "hide");
      setIndicator(prefix, visible);
    },
    [setIndicator]
  );
}

export function useIndicators() {
  const myId = useMyId();

  const { data: slugData } = useUserUrlSlug530Query({
    variables: { user_id: myId! },
    skip: !myId,
  });
  const slug = slugData?.users_by_pk?.url_slug?.slug;
  const showEmailBadge = useNeedsEmailOrVerification();

  const { lastChecked: notificationsLastChecked, setSecureValue: setNotificationsCookie } =
    useSecureStorage("notifications");
  const { lastChecked: eventsLastChecked, setSecureValue: setEventsCookie } =
    useSecureStorage("events");

  const [fetchFriends, { data: friendsData }] = useRelationshipRequestIndicatorLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [fetchThreads, { data: threadsData }] = useThreadsIndicator220LazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [fetchNotifications, { data: notificationsData }] = useNotificationsFeed510LazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [fetchEvents, { data: eventsData }] = usePublicUpcomingEventList451LazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const setIndicator = useSetIndicatorFunction();
  const clearAllIndicators = useClearAllIndicatorsFunction();

  useEffect(() => {
    if (!myId) return;

    fetchThreads({ variables: { my_id: myId }, pollInterval: 60000 });
    fetchFriends({ variables: { my_id: myId }, pollInterval: 60000 });
    fetchEvents({ variables: { offset: 0 } });
    fetchNotifications({ variables: { dummy_data: false }, pollInterval: 60000 });
  }, [myId, fetchThreads, fetchFriends, fetchEvents, fetchNotifications]);

  const sortedNotifications = useMemo(() => {
    if (notificationsData?.l_notifications_feed?.__typename !== "L_News_Feed_Response_Success") {
      return sortBy(
        [...(friendsData?.friend_requests ?? []), ...(friendsData?.like_requests ?? [])],
        (item) => DateTime.fromISO(item?.created_at).toMillis()
      ).reverse();
    }
    return sortBy(
      [
        ...(friendsData?.friend_requests ?? []),
        ...(friendsData?.like_requests ?? []),
        ...(notificationsData?.l_notifications_feed.items ?? []),
      ],
      (item) => DateTime.fromISO(item?.created_at).toMillis()
    ).reverse();
  }, [notificationsData, friendsData]);

  const indicatorStates = useMemo(
    () => ({
      requests:
        (friendsData?.friend_requests?.length ?? 0) > 0 ||
        (friendsData?.like_requests?.length ?? 0) > 0,
      email: showEmailBadge,
      inboundRequests: (friendsData?.friend_requests?.length ?? 0) > 0,
      threads: new Set(threadsData?.threads.map(({ thread_id }) => thread_id).filter(Boolean)),
    }),
    [friendsData, showEmailBadge, threadsData]
  );

  useEffect(() => {
    if (!myId || !friendsData || !threadsData || !notificationsData || !eventsData) {
      return;
    }

    // Set indicators individually instead of clearing all
    setIndicator(allRoutes.REQUESTS.buildPath({}), indicatorStates.requests);
    setIndicator(allRoutes.SETTINGS.PERSONAL.buildPath({}), indicatorStates.email);
    if (slug) {
      setIndicator(
        allRoutes.PROFILE.FRIENDS.INBOUND_REQUESTS.buildPath({ slug }),
        indicatorStates.inboundRequests
      );
    }
    indicatorStates.threads.forEach((thread_id) => {
      if (thread_id) {
        setIndicator(allRoutes.MESSAGING.LIST.THREAD.buildPath({ thread_id }), true);
      }
    });
  }, [
    myId,
    slug,
    friendsData,
    threadsData,
    notificationsData,
    eventsData,
    setIndicator,
    indicatorStates,
  ]);

  useEffect(() => {
    if (!slug) return;
    if (!notificationsLastChecked) return;
    const mostRecentNotificationDate = DateTime.fromISO(sortedNotifications[0]?.created_at || "");
    const lastCheckedNotificationDate = DateTime.fromISO(notificationsLastChecked);
    const shouldShow = mostRecentNotificationDate > lastCheckedNotificationDate;
    setIndicator(allRoutes.PROFILE.NOTIFICATIONS.buildPath({ slug }), shouldShow);
  }, [slug, sortedNotifications, notificationsLastChecked, setIndicator]);

  useEffect(() => {
    if (!slug || !eventsData || !eventsLastChecked) return;
    const shouldShowEvents = (() => {
      if (!eventsData.event_public_summaries || eventsData.event_public_summaries.length === 0) {
        return false;
      }
      const eventsWithCreatedAt = eventsData.event_public_summaries.filter(
        (event) => event.created_at
      );
      if (eventsWithCreatedAt.length === 0) {
        return false;
      }
      const mostRecentEventDate = DateTime.fromISO(
        eventsWithCreatedAt.sort(
          (a, b) =>
            DateTime.fromISO(b.created_at!).toMillis() - DateTime.fromISO(a.created_at!).toMillis()
        )[0].created_at!
      );
      const lastCheckedEventDate = DateTime.fromISO(eventsLastChecked);
      return mostRecentEventDate > lastCheckedEventDate;
    })();

    setIndicator(allRoutes.EVENTS.LIST.buildPath({}), shouldShowEvents);
  }, [slug, eventsData, eventsLastChecked, setIndicator]);

  const clearNotificationIndicator = useCallback(() => {
    if (slug) {
      setIndicator(allRoutes.PROFILE.NOTIFICATIONS.buildPath({ slug }), false);
      setNotificationsCookie(new Date().toISOString());
    }
  }, [slug, setIndicator, setNotificationsCookie]);

  const clearEventsIndicator = useCallback(() => {
    setIndicator(allRoutes.EVENTS.LIST.buildPath({}), false);
    setEventsCookie(new Date().toISOString());
  }, [setIndicator, setEventsCookie]);

  const clearInboundRequestsIndicator = useCallback(() => {
    if (slug) {
      setIndicator(allRoutes.PROFILE.FRIENDS.INBOUND_REQUESTS.buildPath({ slug }), false);
    }
  }, [slug, setIndicator]);

  return {
    useSetLocalIndicatorFunction: useSetLocalIndicatorFunction(),
    clearNotificationIndicator,
    clearEventsIndicator,
    clearInboundRequestsIndicator,
  };
}
