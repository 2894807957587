import { ContentBoxInfoLine } from "../content-box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { allRoutes } from "../../util/routes";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";

export function EditBioContentBoxLine({
  editable,
  subPage,
}: {
  editable: boolean;
  subPage?: "bio";
}): null | React.JSX.Element {
  if (!editable) {
    return null;
  }
  return (
    <ContentBoxInfoLine>
      {""}
      <Link
        className=" link italic"
        to={
          subPage === "bio"
            ? allRoutes.SETTINGS.PERSONAL.buildPath({})
            : allRoutes.SETTINGS.PROFILE.buildPath({})
        }
      >
        <FontAwesomeIcon icon={faPencil} fixedWidth className="mr-1" />
        edit
      </Link>
    </ContentBoxInfoLine>
  );
}
