import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ObScreen } from "./widgets";
import { classNames } from "shared/dist/util";
import { faPartyHorn } from "@fortawesome/pro-duotone-svg-icons";

export function ObExplanation({ onNext }: { onNext: () => void }): React.JSX.Element {
  return (
    <ObScreen
      title="Welcome to Candid!"
      subtitle="We're excited for you to join such an amazing community."
    >
      <div className={classNames("flex flex-col h-full prose items-stretch")}>
        <div className="flex-0">
          <p>
            We're going to make this setup as quick and easy as possible so you can get to
            connecting with others.
          </p>
          <p>
            We're also in beta, so this will get better over time. If you see something we should or
            shouldn't ask, let us know!
          </p>
        </div>
        <div className="flex-1 basis-full"></div>
        <div className="flex-0">
          <button className="btn btn-primary w-full" onClick={onNext}>
            <FontAwesomeIcon icon={faPartyHorn} fixedWidth className="mr-2" />
            Lets Go!
          </button>
          <p className="italic mt-2">
            Your privacy is paramount and we will not sell any of your information to any third
            parties.
          </p>
        </div>
      </div>
    </ObScreen>
  );
}
