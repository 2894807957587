import { Link, useNavigate } from "react-router-dom";
import { P, match } from "ts-pattern";
import { SpinnerCentered, SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import { useAuthIsLoading, useMyId } from "shared/dist/auth-data";

import { AUTH_REDIRECT_COOKIE_KEY } from "shared-web-react/dist/constants";
import Cookies from "js-cookie";
import { OB_LAST_PAGE_IDX } from "./onboarding/main";
import { Onboarding_Statuses_Enum } from "shared/dist/__generated__/components";
import React from "react";
import { UserPageNavWrapper } from "./user-page/main-nav";
import { allRoutes } from "../util/routes";
import clsx from "clsx";
import { useMyInfo } from "./bio/bio-settings";

export function Me(): React.JSX.Element {
  const { slug, loading, status, refetch } = useMyInfo();
  const authLoading = useAuthIsLoading();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setShowSpinner(true), 1000);
  }, []);
  const id = useMyId();

  React.useEffect(() => {
    console.log("🚀 - file: me.tsx:59 - React.useEffect - status:", { status, loading });
    if (loading) return;

    if (!id && !authLoading) {
      navigate(allRoutes.HOME.buildPath({}));
      return;
    }

    match(status)
      .with(
        Onboarding_Statuses_Enum.V2_00New,
        Onboarding_Statuses_Enum.V2_05InvitedConfirmedObPending,
        () => {
          navigate(allRoutes.ONBOARDING.buildPath({}));
          return;
        }
      )
      .with(Onboarding_Statuses_Enum.V2_10ObCompletePendingApproval, () => {
        navigate(allRoutes.ONBOARDING.buildPath({}), { state: { startingIdx: OB_LAST_PAGE_IDX } });
        return;
      })
      .with(Onboarding_Statuses_Enum.V2_20LiveUser, () => {
        if (slug) {
          const cachedRedirect = Cookies.get(AUTH_REDIRECT_COOKIE_KEY);
          const profileRoute = allRoutes.PROFILE.buildPath({ slug });
          const route = cachedRedirect ?? profileRoute;
          Cookies.remove(AUTH_REDIRECT_COOKIE_KEY);
          navigate(route);
        }
      })
      .with(P.nullish, () => loading || refetch())
      .exhaustive();
  }, [slug, status, loading, authLoading, id]);

  return (
    <UserPageNavWrapper>
      {showSpinner ? (
        <div className={clsx("flex-col-center-center")}>
          <SpinnerCentered caption="loading your info" />
          <Link to={allRoutes.logout.buildPath({})} className={clsx("btn")}>
            logout
          </Link>
        </div>
      ) : (
        <></>
      )}
    </UserPageNavWrapper>
  );
}
// export function Me(): React.JSX.Element {
//   const id = useMyId();
//   const summaryQuery = useUserSummaryQuery({ variables: { id } })m
//   const imgsQuery = useUserMediaQuery({ variables: { user_id: id } });
//   const user = summaryQuery.data?.users_by_pk;
//   const logout = useLogout();
//   const gqlClient = useGqlClient();
//   if ((imgsQuery.loading || summaryQuery.loading) && !user) {
//     return (
//       <SpinnerFullScreen
//         caption={`Loading ${filterNulls([
//           summaryQuery.loading ? "summary" : null,
//           imgsQuery.loading ? "images" : null,
//         ]).join(" and ")}`}
//       />
//     );
//   }
//   if (!user) {
//     return <SpinnerFullScreen caption={"missing user"} />;
//   }
//   return (
//     <div className="lg:h-full">
//       <Bio
//         editFunctions={{
//           refetchBio: summaryQuery.refetch,
//           refetchImages: imgsQuery.refetch,
//           screenName: async (screen_name: string) => {
//             console.log(
//               "🚀 ~ file: me.tsx ~ line 34 ~ screenName: ~ screen_name",
//               screen_name
//             );
//             await gqlClient.UpdateScreenName({ id, screen_name });
//           },
//           bio: async (bio: string) => {
//             await gqlClient.UpdateBio({ id, bio });
//           },
//           archiveImage: async (id: string) => {
//             gqlClient.ArchiveMedia({ id, archived_at: DateTime.now().toISO() });
//             imgsQuery.refetch();
//           },
//           setImgPrivate: async (id: string, val: boolean) => {},
//           setImgNsfw: async (id: string, val: boolean) => {},
//           setProfileImage: async (id: string) => {
//             await gqlClient.SetUserProfileImage({ profile_media_id: id });
//             imgsQuery.refetch();
//           },
//         }}
//         screenName={user.screen_name}
//         bio={user.bio}
//         images={
//           imgsQuery.data?.media_uploads.map((u) => ({
//             url: u.media_upload_signed_url?.presigned_url,
//             id: u.id,
//             caption: u.caption,
//           })) ?? []
//         }
//         socials={{}}
//       />
//     </div>
//   );
// }
