import React, { Fragment } from "react";
import {
  SelectWithDetails,
  SelectWithDetailsOption,
} from "shared-web-react/dist/widgets/select-details";
import { Type, numberType } from "react-router-typesafe-routes";
import {
  useOrientationQuery,
  useUpdateOrientationMutation,
} from "shared/dist/__generated__/components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpinnerButton } from "../spinner-button";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useMyId } from "shared/dist/auth-data";
import { useRefetchMyBioSummary } from "../../screens/user-page/common";

const orientations = {
  heterosexual: [
    "Heterosexual",
    "Individuals who are sexually and/or romantically attracted to the opposite sex.",
  ] as const,
  homosexual: [
    "Homosexual",
    "Individuals who are sexually and/or romantically attracted to the same sex.",
  ] as const,
  bisexual: ["Bisexual", "Individuals who are sexually attracted to both men & women"] as const,
  pansexual: [
    "Pansexual",
    "Individuals who are attracted to people from the full spectrum of sex, gender, and gender identity.",
  ] as const,
  queer: [
    "Queer",
    "A term sometimes used to refer to the entire LGBTQ+ community & the spectrum of sexual orientations & gender identities.",
  ] as const,
  asexual: ["Asexual", "Individuals who don’t experience sexual attraction."] as const,
  heteroflexible: [
    "Heteroflexible",
    "Individuals who identify as predominantly heterosexual but are open to a homosexual encounter.",
  ] as const,
  homoflexible: [
    "Homoflexible",
    "Individuals who identify as predominantly homosexual but are open to a heterosexual encounter.",
  ] as const,
  demisexual: [
    "Demisexual",
    "Individuals who don’t experience sexual attraction unless they form a strong emotional connection with someone.",
  ] as const,
  demihomo: [
    "Demi-Homosexual",
    "Individuals who only experience homosexual attraction and only when they form a strong emotional connection with someone.",
  ] as const,
  demihetero: [
    "Demi-Heterosexual",
    "Individuals who only experience heterosexual attraction and only when they form a strong emotional connection with someone.",
  ] as const,
};

const orientationsArray: Array<SelectWithDetailsOption> = Object.entries(orientations).map(
  ([k, [n, d]]) => [k, n, d] as const
);

export function OrientationPicker(): React.JSX.Element {
  const my_id = useMyId()!;
  const refetchBio = useRefetchMyBioSummary();
  const [saveMutation, saveMutationResult] = useUpdateOrientationMutation();
  const { data, loading, error } = useOrientationQuery({
    variables: { user_id: my_id },
  });
  const addToast = useAddToast();
  const [selected, setSelected] = React.useState<null | SelectWithDetailsOption>(null);
  const save = React.useCallback(
    async (newOrientation: number | string | null) => {
      await saveMutation({
        variables: {
          orientation: String(newOrientation),
          user_id: my_id,
        },
      });
      refetchBio();
    },
    [selected, saveMutation]
  );
  return (
    <div className="space-y-2">
      <div>
        <label className="label">Orientation</label>
        <SelectWithDetails
          options={orientationsArray}
          loading={saveMutationResult.loading}
          defaultKey={data?.users_by_pk?.orientation}
          disableOnLoading
          selected={selected}
          setSelected={(data) => {
            save(data?.[0] ?? null).then(() => addToast({ content: "Saved!" }));
            setSelected(data);
          }}
        />
      </div>
    </div>
  );
}

export function ObOrientationPicker({
  setDisableButton,
}: {
  setDisableButton: (b: boolean) => void;
}): React.JSX.Element {
  const my_id = useMyId()!;
  const refetchBio = useRefetchMyBioSummary();
  const [saveMutation] = useUpdateOrientationMutation();
  const { data, loading, error } = useOrientationQuery({
    variables: { user_id: my_id },
  });
  const [selected, setSelected] = React.useState<null | SelectWithDetailsOption>(null);

  const save = React.useCallback(
    async (newOrientation: number | string | null) => {
      await saveMutation({
        variables: {
          orientation: String(newOrientation),
          user_id: my_id,
        },
      });
      refetchBio();
    },
    [selected, saveMutation]
  );
  React.useEffect(() => {
    data && !data.users_by_pk?.orientation ? setDisableButton(true) : setDisableButton(false);
  }, [data]);
  return (
    <div className="flex flex-col items-center max-h-80">
      {orientationsArray.map((orientation) => (
        <SpinnerButton
          key={orientation[0]}
          className={clsx(
            "mb-5 w-full",
            data?.users_by_pk?.orientation === orientation[0]
              ? "btn bg-candid-purple-000 text-primary-content"
              : "btn btn-outline ",
            "font-bold py-2 px-4 rounded-full"
          )}
          onClick={async (e) => {
            console.log(selected, orientation[0]);
            let orientationId = String(orientation[0]);
            setDisableButton(true);
            await save(orientationId);
            setSelected(orientation);
            setDisableButton(false);
          }}
        >
          {orientation[1]}
        </SpinnerButton>
      ))}
    </div>
  );
}
