export const EventTags = {
  v01_closed: "Closed Event",
  v01_partner: "Partner Event",
  v01_festival: "Festival",
  v01_concert: "Concert",
  v01_happy_hour: "Happy Hour",
  v01_takeover: "Takeover",
  v01_ambassador_event: "Ambassador Event",
};

export const EventTagsForSelectPills = Object.entries(EventTags).map(([k, v]) => ({
  value: k as keyof typeof EventTags,
  label: v,
}));
