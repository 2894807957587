import { Container, ContainerProps } from "../../widgets/container";
import {
  useBioToRefreshLazyQuery,
  useBioToRefreshQuery,
  useUserSummaryQuery,
} from "shared/dist/__generated__/components";

import React from "react";
import { useMyId } from "shared/dist/auth-data";

export const userPageContainerWidth = "sm";

export type RefetchProp = { refetch?: () => Promise<any> };

export function UserPageContainer({
  children,
  className,
  ...props
}: ContainerProps): React.JSX.Element {
  return (
    <Container
      {...{ ...props, size: userPageContainerWidth }}
      className={`py-2 max-lg:px-2 w-screen overflow-hidden ${className ?? ""}`}
    >
      {children}
    </Container>
  );
}

export function useRefetchMyBioSummary() {
  const my_id = useMyId();
  // my_id should never be null. ok to get an empty response
  const { data } = useUserSummaryQuery({ variables: { id: my_id ?? "" } });
  const [query, { refetch }] = useBioToRefreshLazyQuery();
  React.useEffect(() => {
    const slug = data?.user_summaries?.[0].slug;
    if (slug && my_id) {
      query({ variables: { slug } });
    }
  }, [data, my_id]);
  return async () => {
    refetch();
  };
}
